import WalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, lighten, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetAvatarQuery, useGetUserQuery } from '../../../../../../api/LabtraceApi';
import { getTokenDecoded } from '../../../../../../services/Auth/Token';
import { resetUser, setUser } from '../../../../../../slices/User';
import CreditBalanceDrawer from './components/Drawer/CreditBalanceDrawer';
import UserProfileDrawer from './components/Drawer/UserProfileDrawer';

const UserProfileHeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
    width: '20rem',
    maxWidth: '20rem',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    marginBottom: '.5rem'
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    width: '20rem',
    maxWidth: '20rem',
    padding: '.75rem 1rem',
    '&:last-of-type': {
        borderTop: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
        borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
        marginTop: '.5rem'
    },
    '& svg': {
        color: alpha(theme.palette.common.black, 0.38)
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& .MuiListItemIcon-root:first-of-type svg': {
            color: theme.palette.common.black
        },
        '& .MuiListItemIcon-root:last-of-type svg': {
            color: theme.palette.primary.main
        },
        '& .MuiTypography-root': {
            color: theme.palette.common.black
        }
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: alpha(theme.palette.common.black, 0.6)
}));

const UserProfileHeader = ({ name, email, avatar }) => (
    <UserProfileHeaderWrapper>
        <Avatar alt={`${name}`} src={avatar} />
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: '1rem' }}>
            <Typography variant="subtitle1">
                {name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
                {email}
            </Typography>
        </Box>
    </UserProfileHeaderWrapper>
);

UserProfileHeader.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string
};

UserProfileHeader.defaultProps = {
    avatar: ''
};

const MenuItemContent = ({ item }) => (
    <Box sx={{ display: 'flex', flex: 1, alignItems: 'stretch' }}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <Box sx={{ display: 'flex', flex: 'auto' }}>
            <StyledTypography variant="body2">{item.title}</StyledTypography>
        </Box>
        {
            item.isNav
            && (
                <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ChevronRightIcon />
                </ListItemIcon>
            )
        }
    </Box>
);

MenuItemContent.propTypes = {
    item: PropTypes.shape({
        icon: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
        isNav: PropTypes.bool.isRequired
    }).isRequired
};

const UserProfile = () => {
    const decodedToken = getTokenDecoded();
    const userId = decodedToken ? decodedToken.id : null;
    const [anchorElement, setAnchorElement] = useState(null);
    const [isUserProfileDrawerOpen, setIsUserProfileDrawerOpen] = useState(false);
    const [isCreditBalanceDrawerOpen, setIsCreditBalanceDrawerOpen] = useState(false);
    const { data: userData } = useGetUserQuery(userId, { skip: !userId });
    const { data: avatarData } = useGetAvatarQuery(userId, { skip: !userId });
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const history = useHistory();

    useEffect(() => {
        if (userData) {
            const { id, name, lastName, email, organisation, location, isActive } = userData;

            dispatch(setUser({
                id,
                name,
                lastName,
                email,
                organization: organisation,
                location,
                isActive,
            }));
        }
    }, [userData]);

    const openUserProfileMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const closeUserProfileMenu = () => {
        setAnchorElement(null);
    };

    const toggleUserProfileDrawer = () => setIsUserProfileDrawerOpen(!isUserProfileDrawerOpen);

    const toggleCreditBalanceDrawer = () => setIsCreditBalanceDrawerOpen(!isCreditBalanceDrawerOpen);

    const logout = () => {
        localStorage.removeItem('token');
        dispatch(resetUser());
        history.push('/sign-in');
    };

    return (
        <Box>
            <Tooltip title="User profile">
                <IconButton sx={{ p: 0 }} onClick={openUserProfileMenu}>
                    <Avatar
                        alt={`${user.name} ${user.lastName}`}
                        src={avatarData ? avatarData.avatar : ''}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElement)}
                onClose={closeUserProfileMenu}
            >
                <UserProfileHeader
                    name={`${user.name} ${user.lastName}`}
                    email={user.email}
                    avatar={avatarData ? avatarData.avatar : ''}
                />
                <StyledMenuItem onClick={() => {
                    closeUserProfileMenu();
                    toggleUserProfileDrawer();
                }}
                >
                    <MenuItemContent item={{
                        icon: <PersonIcon />,
                        title: 'Personal Data',
                        isNav: true
                    }}
                    />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => {
                    closeUserProfileMenu();
                    toggleCreditBalanceDrawer();
                }}
                >
                    <MenuItemContent item={{
                        icon: <WalletIcon fontSize="small" />,
                        title: 'Credit Balance',
                        isNav: true
                    }}
                    />
                </StyledMenuItem>
                <StyledMenuItem onClick={logout}>
                    <MenuItemContent item={{
                        icon: <LogoutIcon fontSize="small" />,
                        title: 'Log out',
                        isNav: false
                    }}
                    />
                </StyledMenuItem>
            </Menu>
            <UserProfileDrawer onClose={toggleUserProfileDrawer} isOpen={isUserProfileDrawerOpen} />
            <CreditBalanceDrawer onClose={toggleCreditBalanceDrawer} isOpen={isCreditBalanceDrawerOpen} />
        </Box>
    );
};

export default UserProfile;
