import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { lighten, styled } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useCanCreateProjectQuery, useGetProjectQuery } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';
import { isLeaderVisible, isOrganizationVisible } from '../../services/Utils/Utils';
import { SuccessChip } from '../Chip/Chip';
import { DIALOG_TYPE_PROJECT } from '../Dialog/Dialog';
import Divider from '../Divider/Divider';
import CreateNewProjectForm from './components/CreateNewProjectForm/CreateNewProjectForm';
import InviteForm from './components/InviteForm/InviteForm';

const ActiveBreadcrumb = styled(Typography)(({ theme }) => ({
    color: lighten(theme.palette.common.black, 0.13)
}));

const LinkBreadcrumb = styled(Link)(({ theme }) => ({
    color: lighten(theme.palette.common.black, 0.62),
    textDecoration: 'none'
}));

const StyledButton = styled(Button)(() => ({
    width: '127px',
    height: '48px'
}));

const StyledAvatar = styled(Avatar)(() => ({
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: '-1rem !important'
}));

const DashboardHeader = ({ breadcrumbs, singleProject, subFolders }) => {
    const { openDialog } = useContext(DialogContext);
    const { projectId } = useParams();
    const { data: projectData } = useGetProjectQuery(projectId, { skip: !projectId });
    const [project, setProject] = useState(null);
    const user = useSelector((state) => state.user);
    const { error: canNotCreateProject } = useCanCreateProjectQuery(user.id, { skip: !user.id });

    useEffect(() => {
        projectData && setProject(projectData);
    }, [projectData]);

    const handleCreateButtonClick = () => {
        if (canNotCreateProject) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'Not Allowed',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        {canNotCreateProject.data.error.responseMessage}
                    </Typography>
                )
            });
        } else {
            openDialog({
                isOpen: true,
                status: null,
                title: 'Create new project',
                content: <CreateNewProjectForm />,
                type: DIALOG_TYPE_PROJECT
            });
        }
    };

    return (
        <Paper elevation={0}>
            <Grid container>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
                        {
                            breadcrumbs.map((breadcrumb, index) => {
                                if (index < breadcrumbs.length - 1) {
                                    return (
                                        <LinkBreadcrumb
                                            key={breadcrumb.id}
                                            to={breadcrumb.href}
                                        >
                                            <Typography variant="h5">
                                                {breadcrumb.name}
                                            </Typography>
                                        </LinkBreadcrumb>
                                    );
                                }
                                return (
                                    <ActiveBreadcrumb
                                        key={breadcrumb.id}
                                        variant="h5"
                                    >
                                        {breadcrumb.name}
                                        {
                                            (projectData && user.id === projectData.leaderId && singleProject && subFolders)
                                            && (
                                                <IconButton
                                                    onClick={() => openDialog({
                                                        isOpen: true,
                                                        status: null,
                                                        title: 'Update project',
                                                        content: <CreateNewProjectForm project={projectData} />
                                                    })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )
                                        }
                                    </ActiveBreadcrumb>
                                );
                            })
                        }
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        singleProject
                            ? (
                                <Stack direction="row" spacing={3}>
                                    {
                                        projectData && isLeaderVisible(user.id, projectData)
                                        && (
                                            <>
                                                <Stack direction="row" spacing={1}>
                                                    <Stack spacing={0}>
                                                        <Typography variant="caption" color="text.secondary">Project leader</Typography>
                                                        <Typography variant="subtitle2" sx={{ mt: '-5px' }}>
                                                            { project && `${project.leaderFirstName} ${project.leaderLastName}` }
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Divider orientation="vertical" />
                                            </>
                                        )
                                    }
                                    <Stack direction="row" spacing={1}>
                                        <AvatarGroup max={4} sx={{ ml: '1rem !important' }}>
                                            {
                                                project
                                                && project.members.records.map((member, index) => {
                                                    if (member.status === 'Active') {
                                                        if (index < 4) {
                                                            return (
                                                                <StyledAvatar key={member.id}>
                                                                    {member.name.charAt(0)}
                                                                    {member.lastName.charAt(0)}
                                                                </StyledAvatar>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })
                                            }
                                        </AvatarGroup>
                                        <Stack spacing={0}>
                                            <Typography variant="caption" color="text.secondary">Project members</Typography>
                                            <Typography variant="subtitle2" sx={{ mt: '-5px' }}>
                                                { project
                                                && `${project.members.records.filter((record) => record.status === 'Active').length}/${project.maxNumberOfMembers}` }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <StyledButton
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        startIcon={<AddIcon />}
                                        sx={{ borderRadius: '50px' }}
                                        onClick={() => openDialog({
                                            isOpen: true,
                                            status: null,
                                            title: 'Invite new member',
                                            content: <InviteForm projectId={projectId} userId={user.id} />
                                        })}
                                        disabled={project && user.id !== project.leaderId}
                                    >
                                        <Typography variant="button">Invite</Typography>
                                    </StyledButton>
                                </Stack>
                            )
                            : (
                                <StyledButton
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    startIcon={<AddIcon />}
                                    sx={{ borderRadius: '50px' }}
                                    onClick={handleCreateButtonClick}
                                >
                                    <Typography variant="button">Create</Typography>
                                </StyledButton>
                            )
                    }
                </Grid>
                {
                    singleProject
                    && (
                        <Grid item xs={12} sx={{ mt: '2rem' }}>
                            <Stack spacing={1}>
                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                    <Stack direction="row" spacing={0}>
                                        <Typography variant="subtitle2" color="text.secondary">From:&nbsp;</Typography>
                                        <Typography variant="subtitle2">
                                            {project && moment(project.startDate.date).format('DD/MM/YYYY')}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={0}>
                                        <Typography variant="subtitle2" color="text.secondary">To:&nbsp;</Typography>
                                        <Typography variant="subtitle2">
                                            {
                                                (project && project.endDate && project.endDate.date)
                                                    ? moment(project.endDate.date).format('DD/MM/YYYY')
                                                    : 'unlimited'
                                            }
                                        </Typography>
                                    </Stack>
                                    <SuccessChip
                                        label={
                                            project
                                        && (
                                            (project.endDate && project.endDate.date)
                                                ? `${moment(project.endDate.date).diff(moment(project.startDate.date), 'days')} days`
                                                : 'unlimited'
                                        )
                                        }
                                    />
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    {
                                        projectData && isOrganizationVisible(user.id, projectData)
                                        && (
                                            <Stack direction="row" spacing={0}>
                                                <Typography variant="subtitle2" color="text.secondary">Organization:&nbsp;</Typography>
                                                <Typography variant="subtitle2">
                                                    {project && project.organisation}
                                                </Typography>
                                            </Stack>
                                        )
                                    }
                                    <Stack direction="row" spacing={0}>
                                        <Typography variant="subtitle2" color="text.secondary">Area:&nbsp;</Typography>
                                        <Typography variant="subtitle2">
                                            {project && project.area}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={0}>
                                        <Typography variant="subtitle2" color="text.secondary">Location:&nbsp;</Typography>
                                        <Typography variant="subtitle2">
                                            {project && project.location}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                    )
                }
            </Grid>
            <Divider sx={{ marginTop: '2rem' }} />
        </Paper>
    );
};

DashboardHeader.defaultProps = {
    singleProject: false,
    subFolders: false
};

DashboardHeader.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
    singleProject: PropTypes.bool,
    subFolders: PropTypes.bool
};

export default DashboardHeader;
