import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignInScene from '../scenes/SignIn/SignIn';
import SignUpScene from '../scenes/SignUp/SignUp';
import AlmostDoneSignUpScene from '../scenes/AlmostDone/AlmostDoneSignUp';
import AlmostDoneResetPasswordScene from '../scenes/AlmostDone/AlmostDoneResetPassword';
import ThankYouScene from '../scenes/ThankYou/ThankYou';
import ResetPasswordScene from '../scenes/ResetPassword/ResetPassword';
import NewPasswordScene from '../scenes/NewPassword/NewPassword';
import CongratulationsScene from '../scenes/ThankYou/Congratulations';
import DashboardScene from '../scenes/Dashboard/Dashboard';
import TokenExpiredScene from '../scenes/TokenExpired/TokenExpired';

const isUserLoggedIn = () => {
    let isLoggedIn = false;

    const token = localStorage.getItem('token');
    if (token) {
        isLoggedIn = true;
    }

    return isLoggedIn;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = isUserLoggedIn();

    return (
        <Route
            {...rest}
            render={(props) => (
                isLoggedIn ? <Component {...props} /> : <Redirect to="/sign-in" />
            )}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = isUserLoggedIn();

    const { search } = useLocation();
    const activate = new URLSearchParams(search).get('activate');

    return (
        <Route
            {...rest}
            render={(props) => (
                isLoggedIn && !activate ? <Redirect to="/" /> : <Component {...props} />
            )}
        />
    );
};

LoginRoute.propTypes = {
    component: PropTypes.elementType.isRequired
};

const Routes = () => (
    <Switch>
        <Route exact path="/not-found" render={() => 'Page not found.'} />
        <LoginRoute exact path="/sign-in" component={SignInScene} />
        <Route exact path="/sign-up" component={SignUpScene} />
        <Route exact path="/sign-up-almost-done" component={AlmostDoneSignUpScene} />
        <Route exact path="/reset-password-almost-done" component={AlmostDoneResetPasswordScene} />
        <Route exact path="/thank-you/:token" component={ThankYouScene} />
        <Route exact path="/token-expired/:token" component={TokenExpiredScene} />
        <Route exact path="/reset-password" component={ResetPasswordScene} />
        <Route exact path="/new-password/:token" component={NewPasswordScene} />
        <Route exact path="/congratulations" component={CongratulationsScene} />
        <PrivateRoute path="/dashboard" component={DashboardScene} />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/not-found" />
    </Switch>
);

export default Routes;
