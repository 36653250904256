import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiDialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import { styled, alpha } from '@mui/material/styles';

export const DIALOG_TYPE_PROJECT = 'project';

const DialogWrapper = styled(Box)(() => ({
    width: '32rem',
    minHeight: '10rem',
    padding: '1rem'
}));

const StyledTitle = styled(Typography)(() => ({
    marginLeft: '.5rem'
}));

const StyledSuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const StyledSuccessTitle = styled(StyledTitle)(({ theme }) => ({
    color: theme.palette.success.main
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: alpha(theme.palette.common.black, 0.38)
}));

const StyledInfoTitle = styled(StyledTitle)(({ theme }) => ({
    color: alpha(theme.palette.common.black, 0.38)
}));

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

const StyledErrorTitle = styled(StyledTitle)(({ theme }) => ({
    color: theme.palette.error.main
}));

const Dialog = ({ dialog, closeDialog }) => (
    <MuiDialog open={dialog.isOpen}>
        <DialogWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        dialog.status === 'success'
                        && (
                            <>
                                <StyledSuccessIcon fontSize="large" />
                                <StyledSuccessTitle variant="h6">{dialog.title}</StyledSuccessTitle>
                            </>
                        )
                    }
                    {
                        dialog.status === 'info'
                        && (
                            <>
                                <StyledInfoIcon fontSize="large" />
                                <StyledInfoTitle variant="h6">{dialog.title}</StyledInfoTitle>
                            </>
                        )
                    }
                    {
                        dialog.status === 'error'
                        && (
                            <>
                                <StyledErrorIcon fontSize="large" />
                                <StyledErrorTitle variant="h6">{dialog.title}</StyledErrorTitle>
                            </>
                        )
                    }
                    {
                        !dialog.status
                        && <Typography variant="h6" color="common.black">{dialog.title}</Typography>
                    }
                </Box>
                {
                    dialog.type !== DIALOG_TYPE_PROJECT
                    && <CloseIcon sx={{ cursor: 'pointer' }} onClick={closeDialog} />
                }
            </Box>
            {dialog.content}
        </DialogWrapper>
    </MuiDialog>
);

Dialog.propTypes = {
    dialog: PropTypes.shape({
        status: PropTypes.string,
        isOpen: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.node.isRequired,
        type: PropTypes.string,
    }).isRequired,
    closeDialog: PropTypes.func.isRequired
};

export default Dialog;
