import Chip from '@mui/material/Chip';
import { lighten, styled } from '@mui/material/styles';

const SuccessChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.success.main, 0.92),
    color: theme.palette.success.main
}));

const ErrorChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.error.main, 0.92),
    color: theme.palette.error.main
}));

const WarningChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.warning.main, 0.92),
    color: theme.palette.warning.main
}));

const SuspendedUserChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.grey[500], 0.92),
    color: theme.palette.error.main,
}));

const HoldUserChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.grey[500], 0.92),
    color: theme.palette.tonalOffset,
}));

const DeletedFileChip = styled(Chip)(({ theme }) => ({
    background: lighten(theme.palette.grey[400], 0.82),
    color: theme.palette.tonalOffset,
}));

export { DeletedFileChip, ErrorChip, HoldUserChip, SuccessChip, SuspendedUserChip, WarningChip };
