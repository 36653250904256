import Error from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Joi from 'joi';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAcceptInvitationMutation, useLoginMutation } from '../../api/LabtraceApi';
import ImageWrapper from '../../components/ImageWrapper/ImageWrapper';
import { getToken, setToken } from '../../services/Auth/Token';

import LoginImage from '../../assets/images/login.svg';
import LogoImage from '../../assets/images/logo.svg';

const SignInWrapper = styled(Box)(({ theme }) => ({
    borderRadius: '.25rem',
    background: 'linear-gradient(158.35deg, #EE643E 0.09%, #A31988 100%)',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '5.5rem',
        paddingRight: '5.5rem'
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white
}));

const StyledVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

const StyledError = styled(Error)(({ theme }) => ({
    color: theme.palette.common.white
}));

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
    '& > input': {
        color: theme.palette.common.white
    },
    '& > fieldset': {
        borderColor: '#FFF !important'
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 100,
    background: theme.palette.common.white,
    '& > .MuiTypography-root, &.Mui-disabled': {
        color: theme.palette.primary.main
    },
    '&:hover, &.Mui-disabled': {
        background: theme.palette.common.white
    }
}));

const SignIn = () => {
    const [values, setValues] = useState({
        email: {
            value: '',
            error: '',
            pattern: Joi.string().email({ tlds: { allow: false } }).required().messages({
                'string.empty': 'Email address is required.',
                'string.email': 'Email address is not valid.'
            })
        },
        password: {
            value: '',
            error: ''
        },
        showPassword: false,
    });
    const history = useHistory();
    const [login, { isLoading }] = useLoginMutation();
    const [acceptInvitation] = useAcceptInvitationMutation();
    const { search } = useLocation();

    const activateProject = async () => {
        if (new URLSearchParams(search).get('activate') && getToken()) {
            const activate = (jwtDecode(new URLSearchParams(search).get('activate')));
            const { id } = jwtDecode(getToken());
            await acceptInvitation({
                projectId: activate.projectId,
                userId: id
            }).unwrap();

            history.push(`/dashboard/projects/${activate.projectId}`);
        } else if (getToken()) {
            history.push('/');
        }
    };

    useEffect(() => {
        activateProject();
    }, []);

    const handleChange = (prop) => (event) => {
        const propError = values[prop].pattern ? values[prop].pattern.validate(event.target.value).error : null;
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value,
                error: propError ? propError.details[0].message : ''
            }
        });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const signIn = async () => {
        if (values.email.value && values.password.value && !values.email.error) {
            try {
                const response = await login({
                    email: values.email.value,
                    password: values.password.value
                }).unwrap();
                setToken(response.token);
                activateProject();
            } catch (error) {
                setValues({
                    ...values,
                    email: {
                        ...values.email,
                        error: error.data
                    },
                });
            }
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={4}>
                <SignInWrapper
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <Box sx={{ mb: '3rem' }}>
                        <img src={LogoImage} alt="LabTrace" />
                    </Box>
                    <Stack spacing={3} sx={{ width: '100%', mb: '2rem' }}>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-email" shrink style={{ background: '#D75057', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                                <StyledTypography variant="subtitle1">Email</StyledTypography>
                            </InputLabel>
                            <StyledInput
                                id="outlined-adornment-email"
                                type="email"
                                value={values.email.value}
                                onChange={handleChange('email')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            disabled
                                        >
                                            {values.email.error && <StyledError />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Email"
                            />
                            {
                                values.email.error
                                && (
                                    <FormHelperText id="outlined-email-helper-text">
                                        <StyledTypography variant="caption">{values.email.error}</StyledTypography>
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" shrink style={{ background: '#D1485E', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                                <StyledTypography variant="subtitle1">Password</StyledTypography>
                            </InputLabel>
                            <StyledInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password.value}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {
                                                values.password.value
                                                && (values.showPassword
                                                    ? <StyledVisibilityOffIcon />
                                                    : <StyledVisibilityIcon />)
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Stack>

                    <StyledTypography variant="subtitle2" sx={{ mb: '2rem' }} style={{ cursor: 'pointer' }} onClick={() => history.push('/reset-password')}>Forgotten password?</StyledTypography>
                    <StyledButton
                        variant="contained"
                        size="large"
                        disableElevation
                        fullWidth
                        onClick={signIn}
                        disabled={
                            !values.email.value
                            || !values.password.value
                            || !!values.email.error
                            || isLoading
                        }
                    >
                        <StyledTypography variant="button">Login</StyledTypography>
                    </StyledButton>
                    <StyledTypography variant="subtitle2" sx={{ mt: '1.25rem', mb: '.5rem' }}>or</StyledTypography>
                    <Button variant="text" size="large" fullWidth onClick={() => history.push('/sign-up')}>
                        <StyledTypography variant="button">Create account</StyledTypography>
                    </Button>
                </SignInWrapper>
            </Grid>
            <Grid item xs={8}>
                <ImageWrapper>
                    <img src={LoginImage} alt="Login illustration" />
                </ImageWrapper>
            </Grid>
        </Grid>
    );
};

export default SignIn;
