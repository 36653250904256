import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import GradientLogoImage from '../../../../assets/images/gradientLogo.svg';
import ThankYouImage from '../../../../assets/images/thankYou.svg';

const StyledTitleWrapper = styled(Paper)(() => ({
    position: 'absolute',
    top: '20%',
    left: '18%',
    display: 'flex',
    flexDirection: 'column'
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: '6rem',
    bottom: '3rem',
    [theme.breakpoints.up('lg')]: {
        right: '13.5rem',
        bottom: '6rem',
    }
}));

const Layout = ({ children, align }) => (
    <Paper elevation={0} sx={{ height: '100vh', position: 'relative' }}>
        <Box sx={{ position: 'absolute', left: '2rem', top: '1.5rem' }}>
            <img src={GradientLogoImage} alt="LabTrace" />
        </Box>
        <StyledTitleWrapper elevation={0} sx={{ alignItems: align }}>
            {children}
        </StyledTitleWrapper>
        <ImageWrapper>
            <img src={ThankYouImage} alt="Thank you" />
        </ImageWrapper>
    </Paper>
);

Layout.defaultProps = {
    children: [],
    align: 'center'
};

Layout.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    align: PropTypes.string
};

export default Layout;
