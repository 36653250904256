import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    name: '',
    lastName: '',
    email: '',
    organization: '',
    location: '',
    isActive: false,
    isAdmin: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            });
        },
        resetUser: (state) => {
            state.id = null;
            state.name = '';
            state.lastName = '';
            state.email = '';
            state.organization = '';
            state.location = '';
            state.isActive = false;
            state.isAdmin = false;
        }
    }
});

export const { setUser, resetUser } = userSlice.actions;
