import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import GradientLogoImage from '../../../../assets/images/gradientLogo.svg';
import AlmostDoneImage from '../../../../assets/images/almostDone.svg';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginBottom: '1.5rem'
}));

const Layout = ({ subtitle }) => (
    <Paper elevation={0} sx={{ height: '100vh', position: 'relative' }}>
        <Box sx={{ position: 'absolute', left: '2rem', top: '1.5rem' }}>
            <img src={GradientLogoImage} alt="LabTrace" />
        </Box>
        <Paper elevation={0} sx={{ position: 'absolute', top: '12%', left: '18%' }}>
            <StyledTitle variant="h3">Almost done!</StyledTitle>
            {subtitle}
        </Paper>
        <Box sx={{ position: 'absolute', right: '7.5rem', bottom: '8.5rem' }}>
            <img src={AlmostDoneImage} alt="Almost done" />
        </Box>
    </Paper>
);

Layout.propTypes = {
    subtitle: PropTypes.node.isRequired
};

export default Layout;
