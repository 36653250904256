import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: ['Overpass', 'sans-serif'].join(','),
        headline1: {
            fontWeight: '300',
            fontSize: '100px',
            lineHeight: '112px',
            letterSpacing: '-1.5px'
        },
        headline2: {
            fontWeight: '300',
            fontSize: '62px',
            lineHeight: '72px',
            letterSpacing: '-0.5px'
        },
        headline3: {
            fontWeight: '400',
            fontSize: '50px',
            lineHeight: '56px'
        },
        headline4: {
            fontWeight: '400',
            fontSize: '35px',
            lineHeight: '36px',
            letterSpacing: '0.25px'
        },
        headline5: {
            fontWeight: '400',
            fontSize: '25px',
            lineHeight: '25px',
        },
        headline6: {
            fontWeight: '600',
            fontSize: '21px',
            lineHeight: '24px',
            letterSpacing: '0.15px'
        },
        subtitle1: {
            fontWeight: '400',
            fontSize: '17px',
            lineHeight: '24px',
            letterSpacing: '0.15px'
        },
        subtitle2: {
            fontWeight: '600',
            fontSize: '15px',
            lineHeight: '24px',
            letterSpacing: '-0.1px'
        },
        body1: {
            fontWeight: '400',
            fontSize: '17px',
            lineHeight: '24px',
            letterSpacing: '0.5px'
        },
        body2: {
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '18px',
            letterSpacing: '0.25px'
        },
        button: {
            fontWeight: '600',
            fontSize: '15px',
            lineHeight: '24px',
            letterSpacing: '1.25px',
        },
        caption: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.4px'
        },
        overline: {
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '16px',
            letterSpacing: '-1.5px'
        },
    },
    palette: {
        primary: {
            main: '#A31988',
            light: '#E5BBD9',
            dark: '#860C7C',
            50: '#F4E4EF',
            100: '#E5BBD9'
        },
        secondary: {
            main: '#F4AE9B',
            light: '#F7EAE9',
            dark: '#E05D39'
        },
        error: {
            main: '#F44336'
        },
        warning: {
            main: '#FFC107'
        },
        success: {
            main: '#4CAF50'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 905,
            lg: 1240,
            xl: 1440
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                button: {
                    height: '1.25rem'
                }
            }
        }
    }
});

export default theme;
