import React from 'react';
import Typography from '@mui/material/Typography';
import Layout from './components/Layout/Layout';

const AlmostDoneSignUp = () => (
    <Layout
        subtitle={(
            <>
                <Typography variant="h5">Please check your email to complete</Typography>
                <Typography variant="h5"> the registration.</Typography>
            </>
        )}
    />
);

export default AlmostDoneSignUp;
