import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import { styled, alpha, lighten } from '@mui/material/styles';
import { DialogContext } from '../../../../../../hooks/DialogContext';
import { useAcceptInvitationMutation } from '../../../../../../api/LabtraceApi';

const MENU_ITEM_HEIGHT = 100;

const NotificationBadge = styled(Badge)(({ theme }) => ({
    '& > .MuiBadge-badge': {
        backgroundColor: theme.palette.common.white,
        transform: 'scale(0.4)',
        right: '2px',
        top: '2px'
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    width: '20rem',
    maxWidth: '20rem',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    '& svg': {
        color: alpha(theme.palette.common.black, 0.38)
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& svg': {
            color: theme.palette.primary.main
        }
    }
}));

const Notification = ({ notification }) => (
    <Box sx={{ display: 'flex', flex: 1, py: '.5rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar>{notification.avatar}</Avatar>
        </Box>
        <Box sx={{ display: 'flex', flex: 'auto', flexDirection: 'column', mx: '1rem' }}>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }}>
                {notification.title}
            </Typography>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'none', mt: '.25rem' }}>
                {notification.timePassed}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
                notification.isInvitation
                    ? <CheckCircleIcon />
                    : <ChevronRightIcon />
            }
        </Box>
    </Box>
);

Notification.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.number.isRequired,
        avatar: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        timePassed: PropTypes.string.isRequired,
        isInvitation: PropTypes.bool.isRequired
    }).isRequired
};

const Notifications = ({ notifications }) => {
    const [anchorElement, setAnchorElement] = useState(null);
    const [acceptInvitation] = useAcceptInvitationMutation();
    const user = useSelector((state) => state.user);
    const { openDialog } = useContext(DialogContext);
    const history = useHistory();

    const openNotificationsMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const closeNotificationsMenu = () => {
        setAnchorElement(null);
    };

    const hasNotifications = notifications.length > 0;

    const handleNotificationClick = async (notification) => {
        if (notification.isInvitation) {
            try {
                await acceptInvitation({
                    projectId: notification.id,
                    userId: user.id
                }).unwrap();

                history.push(`/dashboard/projects/${notification.id}`);
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Thank you!',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            You have accepted the invitation with success.
                        </Typography>
                    )
                });
            } catch (error) {
                openDialog({
                    isOpen: true,
                    status: 'error',
                    title: 'An error occured',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            The invitation could not be accepted, please try again.
                        </Typography>
                    )
                });
            }
        }
        closeNotificationsMenu();
    };

    return (
        <Box>
            <IconButton
                aria-label="show 0 new notifications"
                color="inherit"
                sx={{ marginRight: '1.5rem' }}
                onClick={openNotificationsMenu}
            >
                <NotificationBadge badgeContent={hasNotifications ? '' : null}>
                    <NotificationsIcon />
                </NotificationBadge>
            </IconButton>
            {
                hasNotifications
                && (
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElement}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElement)}
                        onClose={closeNotificationsMenu}
                        PaperProps={{
                            style: {
                                maxHeight: MENU_ITEM_HEIGHT * 5
                            }
                        }}
                    >
                        {
                            notifications.map((notification) => (
                                <StyledMenuItem
                                    key={notification.id}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    <Notification notification={notification} />
                                </StyledMenuItem>
                            ))
                        }
                    </Menu>
                )
            }
        </Box>
    );
};

export default Notifications;

Notifications.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired
};
