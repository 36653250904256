import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cancelled: true
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            });
        }
    }
});

export const { setLoader } = loaderSlice.actions;
