import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import UsersList from './UsersList';

import Divider from '../../components/Divider/Divider';

const AdminPanel = () => {
    const user = useSelector((state) => state.user);
    return (
        <Paper elevation={0}>
            <Grid container>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ pt: '10px', pb: '6px' }}>
                        User Management
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: '2rem' }} />
            <UsersList />
        </Paper>
    );
};

export default AdminPanel;