import UnfoldMore from '@mui/icons-material/UnfoldMore';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { alpha, lighten, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { DialogContext } from '../../hooks/DialogContext';
import { HoldUserChip, SuccessChip, SuspendedUserChip } from '../Chip/Chip';
import AdminCreditsManagementForm from '../DashboardAdmin/AdminManageCreditsForm';
import AdminRevokeSuspensionForm from '../DashboardAdmin/AdminRevokeSuspensionForm';
import AdminSuspendForm from '../DashboardAdmin/AdminSuspendForm';

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.text.secondary
}));

const StyledAvatar = styled(Avatar)(() => ({
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: '-1rem !important'
}));

const StyledValue = styled('span')({
    fontWeight: 'bold',
    height: '1.5rem',
    color: 'black',
});

const StyledRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '& > td': {
        whiteSpace: 'noWrap'
    },
    '& > td:last-of-type': {
        minWidth: '50px !important',
        '& button': {
            display: 'none',
            '& > svg': {
                color: alpha(theme.palette.common.black, 0.38)
            }
        }
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& > td:last-of-type': {
            '& button': {
                display: 'inline-flex',
                '& > svg': {
                    color: theme.palette.primary.main
                }
            }
        }
    }
}));

const TableAdminView = ({ headCells, filteredUsers }) => {
    const { openDialog } = useContext(DialogContext);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);
    const [menuIsOpen, setOpenMenu] = useState(null);
    const [selectedUserRow, setSelectedUserRow] = useState(null);

    const descendingComparator = (a, b, orderByProp) => {
        if (!orderByProp) {
            return -1;
        }
        if (
            (typeof a[orderByProp] === 'object' && typeof b[orderByProp] === 'object' && b[orderByProp].value < a[orderByProp].value)
            || (typeof a[orderByProp] === 'string' && typeof b[orderByProp] === 'string' && b[orderByProp] < a[orderByProp])
            || (typeof a[orderByProp] === 'number' && typeof b[orderByProp] === 'number' && b[orderByProp] < a[orderByProp])
        ) {
            return -1;
        }
        if (
            (typeof a[orderByProp] === 'object' && typeof b[orderByProp] === 'object' && b[orderByProp].value > a[orderByProp].value)
            || (typeof a[orderByProp] === 'string' && typeof b[orderByProp] === 'string' && b[orderByProp] > a[orderByProp])
            || (typeof a[orderByProp] === 'number' && typeof b[orderByProp] === 'number' && b[orderByProp] > a[orderByProp])
        ) {
            return 1;
        }
        return 0;
    };

    const openUserMenu = (event, row) => {
        setSelectedUserRow(row);
        setOpenMenu(event.currentTarget);
    };

    const closeUserMenu = () => {
        setOpenMenu(null);
        setSelectedUserRow(null);
    };

    const openManageCredits = () => {
        closeUserMenu();
        openDialog({
            isOpen: true,
            status: '',
            title: 'Manage Credits',
            content: <AdminCreditsManagementForm key="AdminCreditsManagementForm" selectedUser={selectedUserRow} />,
        });
    };

    const openSuspendUserModal = () => {
        closeUserMenu();
        openDialog({
            isOpen: true,
            status: 'error',
            title: 'Warning!',
            content: <AdminSuspendForm key="AdminSuspendForm" userToSuspendEmail={selectedUserRow.email} />,
        });
    };

    const openRevokeSuspensionModal = () => {
        closeUserMenu();
        openDialog({
            isOpen: true,
            status: 'error',
            title: 'Alert!',
            content: <AdminRevokeSuspensionForm key="AdminRevokeSuspensionForm" userEmail={selectedUserRow.email} />,
        });
    };

    const getComparator = (selectedOrder, orderByProp) => (
        selectedOrder === 'desc'
            ? (a, b) => descendingComparator(a, b, orderByProp)
            : (a, b) => -descendingComparator(a, b, orderByProp)
    );

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortData = (array, comparator) => {
        if (array) {
            return array.sort((a, b) => comparator(a, b));
        }
        return [];
    };

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            headCells.map((cell, column) => {
                                if (cell.label) {
                                    return (
                                        <TableCell key={cell.id}>
                                            <TableSortLabel
                                                active
                                                IconComponent={UnfoldMore}
                                                onClick={() => handleSort(cell.id)}
                                            >
                                                {cell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={`${cell.id}-StyledRow-}}`} />
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody key="adminTableBody">
                    {
                        sortData(filteredUsers, getComparator(order, orderBy)).map((row, rowIndex) => {
                            const { id, avatar, location, name, lastName, isActive, email, ...loopData } = row;
                            return (
                                <>
                                    <StyledRow
                                        key={`${id}-${rowIndex}}-StyledRow`}
                                        onClick={(event) => openUserMenu(event, row)}
                                    >
                                        {Object.values(loopData).map((cellContent, columnIndex) => {
                                            const value = (cellContent && typeof cellContent === 'object')
                                                ? cellContent.value
                                                : cellContent;
                                            if (columnIndex === 0) {
                                                return (
                                                    <StyledBodyCell
                                                        key={`${id}-${value}-${rowIndex}-${columnIndex}`}
                                                    >
                                                        <Stack direction="row" spacing={1}>
                                                            {avatar

                                                                ? <Avatar src={avatar} />
                                                                : (
                                                                    <AvatarGroup max={4} sx={{ ml: '1rem !important' }}>
                                                                        <StyledAvatar key={`${id}-${value}-${rowIndex}-${columnIndex}-StyledAvatar`}>
                                                                            {name.charAt(0)}
                                                                            {lastName.charAt(0)}
                                                                        </StyledAvatar>
                                                                    </AvatarGroup>
                                                                )}
                                                            <Stack>
                                                                <StyledValue>{value}</StyledValue>
                                                                {email}
                                                            </Stack>
                                                        </Stack>
                                                    </StyledBodyCell>
                                                );
                                            } if (columnIndex === 1) {
                                                return (
                                                    <StyledBodyCell
                                                        key={`${value}-${rowIndex}-${columnIndex}`}
                                                    >
                                                        <Stack>
                                                            <StyledValue>{value}</StyledValue>
                                                            {location}
                                                        </Stack>
                                                    </StyledBodyCell>
                                                );
                                            }
                                            if (columnIndex === 2) {
                                                return (
                                                    <StyledBodyCell key={`${id}-${value}-${rowIndex}-${columnIndex}`}>
                                                        {value}
                                                    </StyledBodyCell>
                                                );
                                            }
                                            if (columnIndex === 3) {
                                                return (
                                                    <StyledBodyCell key={`${id}-${value}-${rowIndex}-${columnIndex}`}>
                                                        {value}
                                                    </StyledBodyCell>
                                                );
                                            }
                                            if (columnIndex === 4) {
                                                return (
                                                    <StyledBodyCell key={`${id}-${value}-${rowIndex}-${columnIndex}`}>
                                                        {(() => {
                                                            if (value === 'Active') {
                                                                return <SuccessChip label={value} />;
                                                            }
                                                            if (value === 'Suspended') {
                                                                return <SuspendedUserChip label={value} />;
                                                            }
                                                            return <HoldUserChip label={value} />;
                                                        })()}
                                                    </StyledBodyCell>
                                                );
                                            }
                                            return (
                                                <StyledBodyCell key={`${id}-${value}-${rowIndex}-${columnIndex}-return`} />);
                                        })}
                                    </StyledRow>
                                    {selectedUserRow && selectedUserRow.userStatus && (
                                        <Menu
                                            sx={{ mt: '4px' }}
                                            id="menu-appbar"
                                            anchorEl={menuIsOpen}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(menuIsOpen)}
                                            onClose={closeUserMenu}
                                        >
                                            <MenuItem key="manage-credits" onClick={openManageCredits}>
                                                Manage Credits
                                            </MenuItem>
                                            {selectedUserRow.userStatus === 'Suspended' ? (
                                                <MenuItem key="revoke-suspension" onClick={openRevokeSuspensionModal}>
                                                    Revoke Suspension
                                                </MenuItem>
                                            ) : (
                                                <MenuItem key="suspend-profile" onClick={openSuspendUserModal}>
                                                    Suspend Profile
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    )}

                                </>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableAdminView.propTypes = {
    headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
    filteredUsers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableAdminView;
