import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useForgottenPasswordMutation } from '../../api/LabtraceApi';

import GradientLogoImage from '../../assets/images/gradientLogo.svg';
import ResetPasswordImage from '../../assets/images/resetPassword.svg';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginBottom: '3rem'
}));

const ResetPasswordWrapper = styled(Box)(() => ({
    position: 'absolute',
    left: '5.5rem',
    top: '9.5rem',
    width: '20.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    right: '2rem',
    bottom: '3rem',
    '& > img': {
        width: '85%'
    },
    [theme.breakpoints.up('lg')]: {
        right: '4.5rem',
        bottom: '6.5rem',
        '& > img': {
            width: '100%'
        }
    }
}));

const ResetPassword = () => {
    const [email, setEmail] = useState({
        value: '',
        error: '',
        pattern: Joi.string().email({ tlds: { allow: false } }).required().messages({
            'string.empty': 'Email address is required.',
            'string.email': 'Email address is not valid.'
        })
    });
    const history = useHistory();
    const [forgottenPassword, { isLoading }] = useForgottenPasswordMutation();

    const handleChange = (event) => {
        const error = email.pattern ? email.pattern.validate(event.target.value).error : '';

        setEmail({
            ...email,
            value: event.target.value,
            error
        });
    };

    const handleForgottenPassword = async () => {
        if (email.value && !email.error) {
            try {
                await forgottenPassword(email.value).unwrap();
                history.push('/reset-password-almost-done');
            } catch (e) {
                setEmail({
                    ...email,
                    error: e.data.error.responseMessage
                });
            }
        }
    };

    return (
        <Paper elevation={0} sx={{ height: '100vh', position: 'relative' }}>
            <Box sx={{ position: 'absolute', left: '2rem', top: '1.5rem' }}>
                <img src={GradientLogoImage} alt="LabTrace" />
            </Box>
            <ResetPasswordWrapper
                component="form"
                noValidate
                autoComplete="off"
            >
                <StyledTitle variant="h5">Reset password</StyledTitle>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="outlined-email">
                        <Typography variant="subtitle1">Email</Typography>
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-email"
                        type="text"
                        value={email.value}
                        onChange={handleChange}
                        label="Email"
                    />
                </FormControl>
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disableElevation
                    required
                    disabled={!email.value || !!email.error || isLoading}
                    sx={{ mt: '3rem' }}
                    onClick={handleForgottenPassword}
                >
                    <Typography variant="button">Continue</Typography>
                </Button>
            </ResetPasswordWrapper>
            <ImageWrapper>
                <img src={ResetPasswordImage} alt="Almost done" />
            </ImageWrapper>
        </Paper>
    );
};

export default ResetPassword;
