import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useContext, useEffect, useState } from 'react';
import { transformUsersResponse } from '../../adapters/UsersAdapter';
import { useGetUsersQuery } from '../../api/LabtraceApi';
import DashboardAdminFilters from '../../components/DashboardAdmin/DashboardAdminFilters';
import TableAdminView from '../../components/TableAdminView/TableAdminView';
import { FiltersAdminContext } from '../../hooks/FilterAdminContext';

const UsersList = () => {
    const headCells = [
        { id: 'fullName', label: 'User' },
        { id: 'userOrganization', label: 'Organization' },
        { id: 'createdOn', label: 'Member since' },
        { id: 'creditBalance', label: 'Credits balance' },
        { id: 'status' }
    ];
    const [filteredUsers, setFilteredUsers] = useState({});
    const { view, activeFilter, customFilters, editableFilter, setActiveFilter } = useContext(FiltersAdminContext);
    const { data, isLoading } = useGetUsersQuery();

    const activeUsersFilter = () => {
        const users = data.records.filter((item) => {
            if (item.userStatus === 'Active') {
                return true;
            } return false;
        });
        return users;
    };

    const onHoldUsersFilter = () => {
        const users = data.records.filter((item) => item.userStatus === 'On-hold');
        return users;
    };

    const suspendedUsersFilter = () => {
        const users = data.records.filter((item) => item.userStatus === 'Suspended');
        return users;
    };

    const customUsersFilter = () => {
        if (customFilters[activeFilter - 1]) {
            const searchFullName = customFilters[activeFilter - 1].fullName;
            const users = data.records.filter((item) => (`${item.name} ${item.lastName}`).includes(searchFullName));
            return users;
        }
        return data.records;
    };

    const filterProjects = () => {
        let users = [];
        if (activeFilter === 'active') {
            users = activeUsersFilter();
        } else if (activeFilter === 'hold') {
            users = onHoldUsersFilter();
        } else if (activeFilter === 'suspended') {
            users = suspendedUsersFilter();
        } else if (activeFilter === 'all') {
            users = data.records;
        } else {
            users = customUsersFilter();
        }

        users = transformUsersResponse(users);
        setFilteredUsers(users);
    };

    useEffect(() => {
        if (data && !editableFilter) {
            filterProjects();
        }
    }, [data, activeFilter, editableFilter]);

    const getTableItems = () => {
        let tableItems = [];
        if (filteredUsers.tableData) {
            tableItems = filteredUsers.tableData.map((item) => ({
                ...item,
            }));
        }

        return tableItems;
    };

    return (
        <>
            <DashboardAdminFilters numberOfRecords={data ? data.records.length : 0} />
            {
                isLoading
                    ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                            <CircularProgress />
                        </Box>
                    )
                    : <TableAdminView filteredUsers={getTableItems()} headCells={headCells} />
            }
        </>
    );
};

export default UsersList;
