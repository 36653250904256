import moment from 'moment';
import { isLeaderVisible, isOrganizationVisible } from '../services/Utils/Utils';

export const transformProjectsResponse = (records, userId) => {
    const gridData = [];
    const tableData = [];

    records.forEach((record) => {
        const {
            id, name, organisation, leaderId, leaderFirstName, leaderLastName,
            members, maxNumberOfMembers, startDate, endDate
        } = record;
        const gridEndDateFormated = (endDate && endDate.date) ? moment(endDate.date).format('DD/MM/YYYY') : 'unlimited';
        const item = {
            id,
            leaderId,
            href: `/dashboard/projects/${id}`,
            title: name,
            subtitle: `End Date: ${gridEndDateFormated}`,
            user: {
                avatar: '',
                name: isLeaderVisible(userId, record) ? `${leaderFirstName} ${leaderLastName}` : 'Not available',
                organization: isOrganizationVisible(userId, record) ? organisation : 'Not available'
            },
            membersArr: members
        };

        gridData.push(item);

        const tableStartDateFormated = moment(startDate.date).format('DD/MM/YYYY');
        const tableEndDateFormated = (endDate && endDate.date) ? moment(endDate.date).format('DD/MM/YYYY') : 'unlimited';
        const numberOfProjectDays = (endDate && endDate.date) ? moment(endDate.date).diff(moment(startDate.date), 'days') : null;
        const row = {
            id,
            name,
            href: `/dashboard/projects/${id}`,
            leader: {
                id: leaderId,
                value: isLeaderVisible(userId, record) ? `${leaderFirstName} ${leaderLastName}` : 'Not available'
            },
            organization: isOrganizationVisible(userId, record) ? organisation : 'Not available',
            members: `${members.records.filter((member) => member.status === 'Active').length}/${maxNumberOfMembers}`,
            startDate: tableStartDateFormated,
            endDate: tableEndDateFormated,
            days: numberOfProjectDays ? `${numberOfProjectDays} days` : 'unlimited',
            membersArr: members
        };
        tableData.push(row);
    });

    return {
        gridData,
        tableData
    };
};
