import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { transformProjectsResponse } from '../../adapters/ProjectsAdapter';
import { useGetProjectsQuery } from '../../api/LabtraceApi';
import { SuccessChip } from '../../components/Chip/Chip';
import DashboardFilters from '../../components/DashboardFilters/DashboardFilters';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import ProjectsGrid from '../../components/GridView/GridView';
import ProjectsTable from '../../components/TableView/TableView';
import { FiltersContext } from '../../hooks/FiltersContext';

const Projects = () => {
    const headCells = [
        { id: 'name', label: 'Project name' },
        { id: 'leader', label: 'Project leader' },
        { id: 'organization', label: 'Organization' },
        { id: 'members', label: 'Members' },
        { id: 'startDate', label: 'Start date' },
        { id: 'endDate', label: 'End date' },
        { id: 'days' }
    ];
    const [filteredProjects, setFilteredProjects] = useState({});
    const { view, activeFilter, customFilters, editableFilter, setActiveFilter } = useContext(FiltersContext);
    const { data, isLoading } = useGetProjectsQuery();
    const user = useSelector((state) => state.user);

    const myProjectsFilter = (project) => {
        const isLeader = project.leaderId === user.id;
        const isMember = project.members.records.some((record) => record.id === user.id);

        return isLeader || isMember;
    };

    const customProjectsFilter = (project) => {
        const filter = customFilters.filter((f) => f.id === activeFilter).shift();
        let includeProject = true;

        if (
            (filter.tag !== '' && !project.tags.includes(filter.tag))
            || (filter.organization && filter.organization.label !== project.organisation)
            || (filter.projectLeader && !new RegExp(filter.projectLeader, 'i').test(`${project.leaderFirstName} ${project.leaderLastName}`))
            || (filter.projectMember && !project.members.records.some((record) => new RegExp(filter.projectMember, 'i').test(`${record.name} ${record.lastName}`)))
            || (filter.location && filter.location.label !== project.location)
            || (filter.projectName && filter.projectName.label !== project.name)
            || (
                (
                    filter.projectDuration[0]
                    && filter.projectDuration[1]
                    && project.startDate
                    && project.endDate
                    && moment(filter.projectDuration[0]).isAfter(project.startDate.date)
                    && moment(filter.projectDuration[1]).isBefore(project.endDate.date)
                )
                || (filter.projectDuration[0] && project.startDate && moment(filter.projectDuration[0]).isAfter(project.startDate.date))
                || (filter.projectDuration[1] && project.endDate && moment(filter.projectDuration[1]).isBefore(project.endDate.date))
            )
        ) {
            includeProject = false;
        }

        return includeProject;
    };

    const filterProjects = () => {
        let projects = [];

        if (activeFilter === 'my') {
            projects = data.records.filter(myProjectsFilter);
        } else if (activeFilter === 'all' || activeFilter === 'add') {
            projects = data.records;
        } else if (activeFilter !== 'add' && customFilters.length > 0) {
            projects = data.records.filter(customProjectsFilter);
        }

        projects = transformProjectsResponse(projects, user.id);

        setFilteredProjects(projects);
    };

    useEffect(() => {
        if (activeFilter === 'primary' || activeFilter === 'secondary') {
            setActiveFilter('all');
        }
    }, []);

    useEffect(() => {
        if (data && !editableFilter) {
            filterProjects();
        }
    }, [data, activeFilter, editableFilter]);

    const getTableItems = () => {
        let tableItems = [];
        if (filteredProjects.tableData) {
            tableItems = filteredProjects.tableData.map((item) => ({
                ...item,
                days: {
                    value: <SuccessChip label={item.days} />
                }
            }));
        }

        return tableItems;
    };

    return (
        <>
            <DashboardHeader breadcrumbs={[{ id: '1', name: 'Projects' }]} />
            <DashboardFilters projects numberOfRecords={data ? data.records.length : 0} />
            {
                isLoading
                    ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                            <CircularProgress />
                        </Box>
                    )
                    : view === 'grid'
                        ? <ProjectsGrid items={filteredProjects.gridData || []} type="folders" />
                        : <ProjectsTable rows={getTableItems()} headCells={headCells} />
            }
        </>
    );
};

export default Projects;
