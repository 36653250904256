import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useGetUserDataMutation } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';
import { download } from '../../services/Utils/Utils';
import DeleteFilesForm from './DeleteFileForm';

const FileOperationsMenu = ({ project, file, openSnackbar, setOpenSnackbar }) => {
    const [menuIsOpen, setOpenMenu] = useState(null);
    const { path } = useRouteMatch();
    const { openDialog } = useContext(DialogContext);
    const isPrivateFolder = path.search(/private-folder/) > 0;
    const [getUser] = useGetUserDataMutation();
    const user = useSelector((state) => state.user);

    const openFileMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const closeFileMenu = () => {
        setOpenMenu(null);
    };

    const openDeleteFileModal = () => {
        closeFileMenu();
        openDialog({
            isOpen: true,
            status: 'error',
            title: 'Warning!',
            content: <DeleteFilesForm projectId={project.id} fileId={file.id} isPrivate={isPrivateFolder} />,
        });
    };

    const checkUserCanDeleteFile = () => {
        if (file.status === 'Deleted') return false;
        if (user.isAdmin) return true;
        if (project.leaderId === user.id) return true;
        if (file.ownerId === user.id) return true;
        return false;
    };

    const getFileNameOnly = (fileNameWithExtension) => {
        const downloadFileNameParts = fileNameWithExtension.split('.');
        return downloadFileNameParts[0];
    };

    return (
        <>
            <IconButton
                onClick={(event) => {
                    openFileMenu(event);
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                sx={{ mt: '4px' }}
                id="menu-appbar"
                anchorEl={menuIsOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(menuIsOpen)}
                onClose={closeFileMenu}
            >
                <MenuItem
                    key="download-certificate"
                    onClick={async () => {
                        closeFileMenu();
                        setOpenSnackbar(true);
                        await download({
                            type: 'certificate',
                            fileId: file.id,
                            fileName: `${getFileNameOnly(file.name ? file.name : file.title)}_certificate.pdf`,
                            projectId: project.id,
                            openDialog,
                            isPrivateFolder
                        });
                        setOpenSnackbar(false);
                    }}
                >
                    download certificate
                </MenuItem>
                {
                    (file.status === 'Deleted' || isPrivateFolder)
                        ? ''
                        : (
                            <MenuItem
                                key="download-file"
                                title="Download file"
                                onClick={async () => {
                                    closeFileMenu();
                                    setOpenSnackbar(true);
                                    await download({
                                        type: 'file',
                                        fileId: file.id,
                                        fileName: file.name,
                                        projectId: project.id,
                                        openDialog,
                                        isPrivateFolder
                                    });
                                    setOpenSnackbar(false);
                                }}
                            >
                                download file
                            </MenuItem>
                        )
                }
                {
                    checkUserCanDeleteFile() ? (
                        <MenuItem onClick={openDeleteFileModal}>
                            delete file
                        </MenuItem>
                    )
                        : ''
                }
            </Menu>
        </>

    );
};

FileOperationsMenu.propTypes = {
    project: PropTypes.any.isRequired,
    file: PropTypes.any.isRequired,
    openSnackbar: PropTypes.bool.isRequired,
    setOpenSnackbar: PropTypes.func.isRequired
};

export default FileOperationsMenu;