import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Layout from '../ThankYou/components/Layout/Layout';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ThankYou = () => {
    const history = useHistory();

    return (
        <Layout>
            <StyledTitle sx={{ paddingBottom: '40px' }} variant="h3">Your token Expired.</StyledTitle>
            <StyledTitle variant="h3">You need to create your </StyledTitle>
            <StyledTitle variant="h3">account again.</StyledTitle>
            <Button
                variant="contained"
                size="large"
                disableElevation
                sx={{ marginTop: '3rem', width: '14.5rem' }}
                onClick={() => { history.push('/sign-up'); }}
            >
                <Typography variant="button">Sign up</Typography>
            </Button>
        </Layout>
    );
};

export default ThankYou;
