import jwtDecode from 'jwt-decode';

export const setToken = (token) => localStorage.setItem('token', token);

export const getToken = () => localStorage.getItem('token');

export const getTokenDecoded = () => {
    if (getToken()) {
        return jwtDecode(getToken());
    }
    return null;
};

export const removeToken = () => localStorage.removeItem('token');
