import UnfoldMore from '@mui/icons-material/UnfoldMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { alpha, lighten, styled } from '@mui/material/styles';
import momentJs from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeletedFileChip } from '../Chip/Chip';

const StyledBodyCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.text.secondary
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    '& > td': {
        whiteSpace: 'noWrap'
    },
    '& > td:last-of-type': {
        minWidth: '50px !important',
        '& button': {
            display: 'none',
            '& > svg': {
                color: alpha(theme.palette.common.black, 0.38)
            }
        }
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& > td:last-of-type': {
            '& button': {
                display: 'inline-flex',
                '& > svg': {
                    color: theme.palette.primary.main
                }
            }
        }
    }
}));

const getOnClickHandler = (row, history) => {
    let handler = null;
    if (!row.isPrivate) {
        if (row.onClick) {
            handler = row.onClick();
        } else if (row.href) {
            handler = history.push(row.href);
        }
    }
    return handler;
};

const TableView = ({ headCells, rows }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(null);
    const history = useHistory();

    const descendingComparator = (a, b, orderByProp) => {
        if (!orderByProp) {
            return -1;
        }
        if (
            (typeof a[orderByProp] === 'object' && typeof b[orderByProp] === 'object' && b[orderByProp].value < a[orderByProp].value)
            || (typeof a[orderByProp] === 'string' && typeof b[orderByProp] === 'string' && b[orderByProp] < a[orderByProp])
        ) {
            return -1;
        }
        if (
            (typeof a[orderByProp] === 'object' && typeof b[orderByProp] === 'object' && b[orderByProp].value > a[orderByProp].value)
            || (typeof a[orderByProp] === 'string' && typeof b[orderByProp] === 'string' && b[orderByProp] > a[orderByProp])
        ) {
            return 1;
        }
        return 0;
    };

    const getComparator = (selectedOrder, orderByProp) => (
        selectedOrder === 'desc'
            ? (a, b) => descendingComparator(a, b, orderByProp)
            : (a, b) => -descendingComparator(a, b, orderByProp)
    );

    const handleSort = (property) => {
        if (property === 'startDate' || property === 'endDate') {
            rows.forEach((row) => {
                row[`${property}Formated`] = momentJs(row[property], 'DD/MM/YYYY').format('YYYY/MM/DD');
            });
            const isAsc = orderBy === `${property}Formated` && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(`${property}Formated`);
        } else {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    };

    const sortData = (array, comparator) => array.sort((a, b) => comparator(a, b));

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            headCells.map((cell) => {
                                if (cell.label) {
                                    return (
                                        <TableCell key={cell.id}>
                                            <TableSortLabel
                                                active
                                                IconComponent={UnfoldMore}
                                                onClick={() => handleSort(cell.id)}
                                            >
                                                {cell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    );
                                }
                                return <TableCell key={cell.id} />;
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sortData(rows, getComparator(order, orderBy)).map((row, rowIndex) => {
                            const { id, href, startDateFormated, ownerId, endDateFormated, onClick, isPrivate, ...loopData } = row;
                            return (
                                <StyledRow
                                    key={id}
                                    sx={{
                                        ...(row.download && { '& > td:last-of-type': { textAlign: 'right', width: '100px' } }),
                                        ...((loopData.status === 'Deleted') && { filter: 'grayscale(100%)', opacity: 0.6 })
                                    }}
                                    onClick={() => getOnClickHandler(row, history)}
                                >
                                    {

                                        Object.values(loopData).map((cellContent, columnIndex) => {
                                            const value = (cellContent && typeof cellContent === 'object')
                                                ? cellContent.value
                                                : cellContent;
                                            if (columnIndex === 5) {
                                                return (
                                                    <StyledBodyCell key={`${id}-${value}-${rowIndex}-${columnIndex}`}>
                                                        {(() => {
                                                            if (value === 'Deleted') {
                                                                return <DeletedFileChip label="Deleted" />;
                                                            }
                                                            return null;
                                                        })()}
                                                    </StyledBodyCell>
                                                );
                                            }
                                            return (
                                                <StyledBodyCell
                                                    key={`${value}-${rowIndex}-${columnIndex}`}
                                                    sx={{
                                                        ...(typeof value === 'object' && { p: 0 })
                                                    }}
                                                >
                                                    {value}
                                                </StyledBodyCell>
                                            );
                                        })
                                    }
                                </StyledRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableView.propTypes = {
    headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableView;
