import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useVerifyFileMutation } from '../../api/LabtraceApi';
import FileImage from '../../assets/images/file.svg';
import { DialogContext } from '../../hooks/DialogContext';

const StyledFileInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3rem 0'
}));

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const VerificationDialog = ({ fileData }) => {
    let isLoading;
    const { closeDialog, openDialog } = useContext(DialogContext);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const mutationResponse = useVerifyFileMutation();
    const verifyFile = mutationResponse[0];
    isLoading = mutationResponse[1].isLoading;

    const handleCloseSnackbar = () => {
        isLoading = false;
        setOpenSnackbar(false);
    };

    const handleFileVerification = async () => {
        try {
            setOpenSnackbar(true);
            const formData = new FormData();
            formData.append('file', fileData);
            const verificationResponse = await verifyFile({ payload: formData }).unwrap();
            console.log(verificationResponse);
            handleCloseSnackbar();
            if (verificationResponse.exists === true) {
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Verification successful',
                    content:
    <Typography variant="body1">
        The file has been verified successfully.
        <br />
        It exists with filename:
        <br />
        <span style={{ fontWeight: 'bold' }}>
            {'    '}
            {' '}
            {verificationResponse.fileName}
        </span>
        <br />
        Inside the project
        <br />
        <span style={{ fontWeight: 'bold' }}>
            {'    '}
            {' '}
            {verificationResponse.project}
        </span>
        .
    </Typography>
                });
            } else {
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Verification successful',
                    content:
    <Typography variant="body1">
        The file has been verified successfully.
        <br />
        It does not exist inside Labtrace.
    </Typography>
                });
            }
        } catch (e) {
            console.log(e);
            handleCloseSnackbar();
        }
    };
    const useStyles = makeStyles(() => ({
        snackbarWidth: {
            width: '85%',
        }
    }));
    const classes = useStyles();

    const action = (
        <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            HIDE
        </Button>);

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <Box sx={{ px: '1rem' }}>
                <StyledFileInfo>
                    <img src={FileImage} alt="file" />
                    <Typography variant="subtitle2" sx={{ textDecoration: 'underline', mt: '1.5rem' }}>
                        {fileData.name}
                    </Typography>
                </StyledFileInfo>
            </Box>
            <StyledActionWrapper>
                <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                    <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                        <Typography variant="button">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={handleFileVerification}
                        disabled={isLoading}
                    >
                        <Typography variant="button">
                            Verify
                        </Typography>
                    </Button>
                </Stack>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    message={
                        <div style={{ width: 500 }}>
                            <Grid container spacing={2} sx={{ maxWidth: 500 }}>
                                <Grid item xs={4}>
                                    Verification in progress
                                </Grid>
                                <Grid item xs={8}>
                                    <Box sx={{ width: '100%' }} mt={1}>
                                        <LinearProgress />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    classes={
                        { root: classes.snackbarWidth }
                    }
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </StyledActionWrapper>
        </Box>

    );
};

VerificationDialog.propTypes = {
    fileData: PropTypes.any.isRequired,
};
export default VerificationDialog;