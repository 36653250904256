export const transformFilesResponse = (response) => {
    const filesGridData = [];
    const filesTableData = [];

    response.records && response.records.forEach((record) => {
        const { id, name, ownerName, ownerLastName, type, fileFormat, uploaded, ownerId, status } = record;
        const isSecondary = type === 'secondary';
        const gridData = {
            id,
            title: name,
            name,
            subtitle: `.${fileFormat.toUpperCase()}`,
            isSecondary,
            isDownloadable: true,
            ownerId,
            status
        };

        filesGridData.push(gridData);

        const row = {
            id,
            name,
            type: isSecondary ? 'Secondary data' : 'Primary data',
            owner: (ownerName && ownerLastName) ? `${ownerName} ${ownerLastName}` : '-',
            uploaded: uploaded || '-',
            download: true,
            isSecondary,
            ownerId,
            status
        };

        filesTableData.push(row);
    });

    return {
        data: response,
        filesGridData,
        filesTableData
    };
};
