import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ErrorIcon from '@mui/icons-material/Error';
import Joi from 'joi';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { styled, lighten, alpha } from '@mui/material/styles';
import { DialogContext } from '../../../../hooks/DialogContext';
import { Tag } from '../../../Tags/Tags';
import {
    useGetUsersQuery,
    useGetProjectQuery,
    useAddProjectMemberMutation,
    useRemoveProjectMemberMutation,
    useGetPendingInvitationsMutation,
    useCanInviteMemberQuery
} from '../../../../api/LabtraceApi';

const StyledAutocomplete = styled(Autocomplete)(() => ({
    width: '100%',
    '& label': {
        lineHeight: 'unset'
    },
    '& .MuiAutocomplete-endAdornment': {
        display: 'none'
    }
}));

const InviteButton = styled(Button)(() => ({
    position: 'absolute',
    right: '.5rem',
    top: '.6rem'
}));

const MembersWrapper = styled(Stack)(({ theme }) => ({
    marginTop: '.5rem',
    maxHeight: '18.5rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: '.25rem'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0.12)
    }
}));

const MemberWrapper = styled(Box)(({ theme }) => ({
    padding: '1rem 2rem 0 1rem',
    margin: '0 -1rem',
    '& button': {
        display: 'none'
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& button': {
            display: 'block'
        }
    }
}));

const Member = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`
}));

const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const ErrorBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    borderLeft: `2px solid ${theme.palette.error.main}`,
    height: '3.5rem',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '& svg': {
        color: theme.palette.error.main,
        marginRight: '.5rem'
    }
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const InviteForm = ({ projectId, userId }) => {
    const { openDialog, closeDialog } = useContext(DialogContext);
    const { data: project } = useGetProjectQuery(projectId);
    const { data: users } = useGetUsersQuery();
    const [values, setValues] = useState({
        email: {
            value: null,
            options: []
        }
    });
    const [members, setMembers] = useState([]);
    const [addMember] = useAddProjectMemberMutation();
    const [removeMember] = useRemoveProjectMemberMutation();
    const [getPendingInvitations] = useGetPendingInvitationsMutation();
    const { error: canNotInviteMember } = useCanInviteMemberQuery(userId, { skip: !userId });
    const [denyUserToBecomeMember, setDenyUserToBecomeMember] = useState(false);

    useEffect(() => {
        (async () => {
            if (users && project) {
                const emailOptions = users.records
                    .filter((record) => record.id !== project.leaderId)
                    .filter((record) => !project.members.records.some((projectMember) => projectMember.id === record.id))
                    .map((record) => ({
                        id: record.id,
                        avatar: record.profileImage || 'A',
                        name: `${record.name} ${record.lastName}`,
                        email: record.email,
                        hasAcceptedInvitation: true
                    }));
                const projectMembersPromisses = project.members.records.map(async (member) => {
                    const { data: { records } } = await getPendingInvitations(member.id);

                    return {
                        id: member.id,
                        avatar: `${member.name.charAt(0)}${member.lastName.charAt(0)}`,
                        name: `${member.name} ${member.lastName}`,
                        email: member.email,
                        hasAcceptedInvitation: !records.some((record) => Number(record.id) === Number(projectId))
                    };
                });
                const projectMembers = await Promise.all(projectMembersPromisses);

                setValues({
                    email: {
                        ...values.email,
                        options: emailOptions
                    }
                });
                setMembers(projectMembers);
            }
        })();
    }, [project, users]);

    const handleAutocompleteChange = (name, value) => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                value
            }
        });
    };

    const inviteMember = async (member) => {
        if (canNotInviteMember) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'Not Allowed',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        {canNotInviteMember.data.error.responseMessage}
                    </Typography>
                )
            });
        } else {
            try {
                if (project.maxNumberOfMembers > project.members.totalRecords) {
                    await addMember({ projectId, userId: member.id }).unwrap();
                } else {
                    openDialog({
                        isOpen: true,
                        status: 'error',
                        title: 'An error occured',
                        content: (
                            <Typography variant="body2" sx={{ mt: '1rem' }}>
                                You have exceeded the maximum number of members
                            </Typography>
                        )
                    });
                }
            } catch (error) {
                openDialog({
                    isOpen: true,
                    status: 'error',
                    title: 'An error occured',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            Failed to invite user to the project, please try again.
                        </Typography>
                    )
                });
            } finally {
                setValues({
                    email: {
                        ...values.email,
                        value: null
                    }
                });
            }
        }
    };

    const deleteMember = async (member) => {
        try {
            await removeMember({ projectId, userId: member.id }).unwrap();
        } catch (error) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'An error occured',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Failed to remove user from the project, please try again.
                    </Typography>
                )
            });
        }
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none',
        }
    }));
    const classes = useStyles();

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: '2rem' }}
        >
            <Box sx={{ position: 'relative' }}>
                <StyledAutocomplete
                    id=""
                    options={values.email.options}
                    value={values.email.value}
                    getOptionLabel={(option) => option.email}
                    onChange={((event, newValue) => handleAutocompleteChange('email', newValue))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Email"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'
                            }}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.email === value.email}
                    noOptionsText="..."
                    filterOptions={(options) => []}
                    classes={
                        { popper: classes.hide }
                    }
                    onInputChange={
                        (event, value) => {
                            const member = values.email.options.find(({ email }) => email === value);
                            setDenyUserToBecomeMember(false);
                            if (member !== undefined) {
                                handleAutocompleteChange('email', member);
                            } else {
                                handleAutocompleteChange('email', null);
                            }

                            const pattern = Joi.string().email({ tlds: { allow: false } });
                            const error = pattern.validate(value);
                            if (error.error === undefined && member === undefined) {
                                setDenyUserToBecomeMember(true);
                            }
                        }
                    }
                />
                {
                    values.email.value
                    && (
                        <InviteButton
                            variant="text"
                            size="large"
                            onClick={() => inviteMember(values.email.value)}
                        >
                            <Typography variant="button">Invite</Typography>
                        </InviteButton>
                    )
                }
                {
                    denyUserToBecomeMember
                    && (
                        <ErrorBox>
                            <ErrorIcon />
                            The user that you are trying to invite is not registered to the platform yet.
                        </ErrorBox>
                    )
                }
            </Box>
            {
                members.length > 0
                && (
                    <Box sx={{ mt: '2rem' }}>
                        <Typography variant="subtitle2">Project members</Typography>
                        <MembersWrapper spacing={0}>
                            {
                                members.map((member) => (
                                    <MemberWrapper key={member.id}>
                                        <Member sx={{ ...(!member.hasAcceptedInvitation && { opacity: 0.5 }) }}>
                                            <Stack spacing={2} direction="row">
                                                <Avatar>{member.avatar}</Avatar>
                                                <Stack spacing={0} sx={{ ml: '1rem' }}>
                                                    <Typography variant="subtitle2">
                                                        {member.name ? member.name : member.email}
                                                    </Typography>
                                                    <Typography variant="caption" color="text.secondary" sx={{ mt: '-5px' }}>
                                                        {member.name ? member.email : 'unknown member'}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            {
                                                member.id === project.leaderId
                                                    ? (
                                                        <Tag label="Project leader" />
                                                    )
                                                    : member.hasAcceptedInvitation
                                                        ? (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => deleteMember(member)}
                                                            >
                                                                <StyledDeleteIcon />
                                                            </IconButton>
                                                        )
                                                        : (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Tag label="on hold" />
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => deleteMember(member)}
                                                                >
                                                                    <StyledDeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        )
                                            }
                                        </Member>
                                    </MemberWrapper>
                                ))
                            }
                        </MembersWrapper>
                    </Box>
                )
            }
            <StyledActionWrapper>
                <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                    <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                        <Typography variant="button">Cancel</Typography>
                    </Button>
                    <Button variant="contained" size="large" fullWidth onClick={closeDialog}>
                        <Typography variant="button">Save</Typography>
                    </Button>
                </Stack>
            </StyledActionWrapper>
        </Box>
    );
};

InviteForm.propTypes = {
    projectId: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired
};

export default InviteForm;
