import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dialog from '../../components/Dialog/Dialog';
import { DialogContext } from '../../hooks/DialogContext';
import { getToken } from '../../services/Auth/Token';
import Header from './components/Header/Header';

const Main = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3.5, 4)
}));

const DefaultLayout = ({ children }) => {
    const { dialog, closeDialog } = useContext(DialogContext);
    const history = useHistory();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!getToken() && !user.id) {
            history.push('/sign-in');
        }
    }, [user]);

    return (
        <Paper elevation={0}>
            <Header />
            <Main elevation={0}>
                {children}
            </Main>
            <Dialog closeDialog={closeDialog} dialog={dialog} />
        </Paper>
    );
};

DefaultLayout.defaultProps = {
    children: []
};

DefaultLayout.propTypes = {
    children: PropTypes.node
};

export default DefaultLayout;
