import Typography from '@mui/material/Typography';
import React from 'react';
import { getToken } from '../Auth/Token';

export const download = async (
    { type, fileId, fileName, projectId, openDialog, userId, from, to, isPrivateFolder }
) => {
    let downloadFileName = fileName;
    try {
        let response;

        if (type === 'file') {
            response = await fetch(`${process.env.REACT_APP_API_INTERNAL_URL}projects/${projectId}/files/${fileId}/download`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            });
        } else if (type === 'certificate') {
            const downloadFileNameParts = fileName.split('.');
            downloadFileNameParts.splice(-1, 1);
            downloadFileName = [...downloadFileNameParts, '.pdf'].join('');

            if (isPrivateFolder) {
                response = await fetch(`${process.env.REACT_APP_API_INTERNAL_URL}private-files/project/${projectId}/files/${fileId}/download/certificate`, {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    }
                });
            } else {
                response = await fetch(`${process.env.REACT_APP_API_INTERNAL_URL}projects/${projectId}/files/${fileId}/download/certificate`, {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    }
                });
            }
        } else if (type === 'creditHistory') {
            downloadFileName = `credit_balance_history_${from}_${to}.xlsx`;
            response = await fetch(`${process.env.REACT_APP_API_INTERNAL_URL}users/${userId}/transactions/${from}/${to}/download`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                }
            });
        }
        if (response.status === 200) {
            if (type === 'file') {
                const { s3Url } = await response.json();
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = s3Url;
                link.download = downloadFileName;
                link.target = '_blank';
                link.setAttribute('Content-Disposition', 'attachment');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            } else {
                const fileBlob = await response.blob();

                const url = window.URL.createObjectURL(new Blob([fileBlob]));
                const anchor = document.createElement('a');

                anchor.href = url;
                anchor.download = downloadFileName;

                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

                window.URL.revokeObjectURL(url);
            }
        } else {
            throw new Error();
        }
    } catch (error) {
        openDialog({
            isOpen: true,
            status: 'error',
            title: 'An error occured',
            content: (
                <Typography variant="body2" sx={{ mt: '1rem' }}>
                    {`Unable to download the ${type === 'file' ? 'file' : 'certificate'}, please try again.`}
                </Typography>
            )
        });
    }
};

export const isLeaderVisible = (userId, project) => {
    let isVisible = false;

    if (
        !project.leaderScope
        || (
            project.leaderScope
            && (
                project.leaderScope === 1
                || (project.leaderScope === 2 && userId === project.leaderId)
                || (project.leaderScope === 2 && project.members.records.some((member) => member.id === userId))
                || (project.leaderScope === 3 && userId === project.leaderId)
            )
        )
    ) {
        isVisible = true;
    }

    return isVisible;
};

export const isOrganizationVisible = (userId, project) => {
    let isVisible = false;

    if (
        !project.organisationScope
        || (
            project.organisationScope
            && (
                project.organisationScope === 1
                || (project.organisationScope === 2 && userId === project.leaderId)
                || (project.organisationScope === 2 && project.members.records.some((member) => member.id === userId))
                || (project.organisationScope === 3 && userId === project.leaderId)
            )
        )
    ) {
        isVisible = true;
    }

    return isVisible;
};
