import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const ImageWrapper = styled(Paper)(() => ({
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
        width: '80%'
    },
    boxShadow: 'none'
}));

export default ImageWrapper;
