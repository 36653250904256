import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useGetIsUserAdminQuery } from '../../api/LabtraceApi';
import DefaultLayout from '../../layout/Default/Default';
import AdminPanel from '../AdminPanel/AdminPanel';
import ProjectFiles from '../ProjectFiles/ProjectFiles';
import ProjectsScene from '../Projects/Projects';
import SingleProject from '../SingleProject/SingleProject';
import VerificationPanel from '../VerificationPanel/VerificationPanel';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector((state) => state.user);
    const { data: isUserAdmin } = useGetIsUserAdminQuery(user.id, { skip: !user.id });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (isUserAdmin !== undefined) {
            setIsLoading(false);
        }
    }, [isUserAdmin]);

    if (isLoading) {
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
            <CircularProgress />
        </Box>;
    }

    return (
        <Route
            {...rest}
            render={(props) => (isUserAdmin && isUserAdmin.isAdmin ? <Component {...props} /> : <Redirect to="/dashboard/projects" />)}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

const Dashboard = () => {
    const { path } = useRouteMatch();

    return (
        <DefaultLayout>
            <Switch>
                <Route exact path={`${path}/verification`} component={VerificationPanel} />
                <Route exact path={`${path}/projects`} component={ProjectsScene} />
                <Route exact path={`${path}/projects/:projectId`} component={SingleProject} />
                <Route exact path={`${path}/projects/:projectId/public-folder`} component={ProjectFiles} />
                <Route exact path={`${path}/projects/:projectId/private-folder`} component={ProjectFiles} />
                <PrivateRoute exact path={`${path}/admin`} component={AdminPanel} />
                <Redirect exact from={path} to={`${path}/projects`} />
            </Switch>
        </DefaultLayout>
    );
};

export default Dashboard;
