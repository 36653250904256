import React, { useEffect } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Layout from './components/Layout/Layout';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ThankYou = () => {
    const history = useHistory();
    const { token } = useParams();

    useEffect(() => {
        (async () => {
            const url = `${process.env.REACT_APP_API_INTERNAL_URL}users/activate/${token}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const responseData = await response.json();
            if (responseData.error) {
                history.push(`/token-expired/${token}`);
            }
        })();
    }, []);
    return (
        <Layout>
            <StyledTitle variant="h3">Thank you for</StyledTitle>
            <StyledTitle variant="h3">joining us!</StyledTitle>
            <Button
                variant="contained"
                size="large"
                disableElevation
                sx={{ marginTop: '3rem', width: '14.5rem' }}
                onClick={() => { history.push('/sign-in'); }}
            >
                <Typography variant="button">Login</Typography>
            </Button>
        </Layout>
    );
};

export default ThankYou;
