import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const AdminMenu = ({ title, href, activeHref }) => {
    const history = useHistory();
    const [isActive, setIsActive] = useState(true);

    const handleMenuClick = () => {
        history.push(`/dashboard/${href}`);
    };

    const menuStyle = {
        fontWeight: 'bold',
        cursor: 'pointer',
        padding: '24px',
        borderRight: '1px solid rgba(255, 255, 255, 0.4)',
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
    };

    if (activeHref !== href) {
        menuStyle.backgroundColor = 'transparent';
    }

    if (title === 'User Management') {
        menuStyle.borderRight = '';
    }
    return (
        <Box
            sx={menuStyle}
            onClick={handleMenuClick}
        >
            { title }
        </Box>
    );
};

AdminMenu.propTypes = {
    title: PropTypes.node.isRequired,
    href: PropTypes.node.isRequired,
    activeHref: PropTypes.node.isRequired,
};

export default AdminMenu;