import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSuspendUserAccountMutation } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const AdminSuspendForm = ({ userToSuspendEmail }) => {
    const { openDialog, closeDialog } = useContext(DialogContext);
    const user = useSelector((state) => state.user);
    const [suspendUser, { isLoading: suspendUserLoading }] = useSuspendUserAccountMutation();
    const [values, setValues] = useState({
        reason: {
            value: null,
            description: '',
            options: ['User invited by mistake', 'Abuse', 'Other reasons']
        }
    });

    const handleAutocompleteChange = (name, value) => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                value,
            }
        });
    };

    const isSuspendButtonDisabled = () => {
        let isDisabled = true;
        if (values.reason.value && values.reason.value !== 'Other reasons') {
            isDisabled = false;
        } else if (values.reason.value === 'Other reasons' && values.reason.description !== '') {
            isDisabled = false;
        } else {
            isDisabled = true;
        }

        return isDisabled;
    };

    const suspendUserAccount = async () => {
        let payloadData;
        if (values.reason.value && values.reason.value !== 'Other reasons') {
            payloadData = values.reason.value;
        }
        if (values.reason.value === 'Other reasons') {
            payloadData = values.reason.description;
        }
        try {
            await suspendUser({ adminId: user.id, userEmail: userToSuspendEmail, reason: payloadData }).unwrap();
            openDialog({
                isOpen: true,
                status: 'success',
                title: 'Done',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        The selected profile has been successfully suspended.
                    </Typography>
                )
            });
        } catch (error) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'Profile not suspended',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Unexpected error. Please try again later.
                    </Typography>
                )
            });
        }
    };

    const handleInputChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                description: event.target.value
            }
        });
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none',
        }
    }));

    return (
        suspendUserLoading
            ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    component="form"
                    sx={{ mt: '2rem' }}
                >
                    <Typography variant="body2">Do you really want to suspend this profle? The user will not be able to access the platform unitl the suspension is revoked and will receive a notification via email. To proceed, select a reason.</Typography>
                    <Grid item sx={{ my: '2rem' }} xs={3}>
                        <Autocomplete
                            id=""
                            sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                            options={values.reason.options}
                            value={values.reason.value}
                            getOptionLabel={(option) => option}
                            onChange={((event, newValue) => handleAutocompleteChange('reason', newValue))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select a reason"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password'
                                    }}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                        />
                    </Grid>
                    {values.reason.value === 'Other reasons' ? (
                        <>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-tag">
                                    <Typography variant="subtitle1">Insert description</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-tag"
                                    type="text"
                                    value={values.reason.description}
                                    onChange={handleInputChange('reason')}
                                    label="Reason"
                                />
                            </FormControl>
                            <Typography sx={{ color: 'grey', fontSize: '0.7rem', ml: '1rem' }}>Max 100 characters</Typography>
                        </>
                    )
                        : ''}

                    <StyledActionWrapper>
                        <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                            <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                                <Typography variant="button">Cancel</Typography>
                            </Button>
                            <Button variant="contained" size="large" fullWidth onClick={suspendUserAccount} disabled={isSuspendButtonDisabled()}>
                                <Typography variant="button">Suspend</Typography>
                            </Button>
                        </Stack>
                    </StyledActionWrapper>
                </Box>
            )

    );
};

AdminSuspendForm.propTypes = {
    userToSuspendEmail: PropTypes.string.isRequired
};

export default AdminSuspendForm;
