import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopy from '@mui/icons-material/ContentCopy';
import EventIcon from '@mui/icons-material/EventOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCreditBalanceQuery, useGetUserPublicKeyQuery } from '../../../../../../../../api/LabtraceApi';
import { DialogContext } from '../../../../../../../../hooks/DialogContext';
import { download } from '../../../../../../../../services/Utils/Utils';

const DrawerContentWrapper = styled(Box)(() => ({
    padding: '1.5rem 1rem',
    height: '100vh',
    position: 'relative'
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    alignItems: 'center'
}));

const ButtonStyle = styled(Button)(() => ({
    width: '127px',
    height: '48px'
}));

const StyledDurationTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root.Mui-focused svg': {
        color: theme.palette.primary.main
    }
}));

const CreditBalanceDrawer = ({ isOpen, anchor, onClose }) => {
    const [values, setValues] = useState({
        startEndDate: {
            value: [null, null]
        }
    });
    const user = useSelector((state) => state.user);
    const { openDialog } = useContext(DialogContext);
    const { data: publicKey } = useGetUserPublicKeyQuery(user.id, { skip: !user.id });
    const { data: creditData } = useGetCreditBalanceQuery(user.id, { skip: !user.id });
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(publicKey.pubKey);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const handleAutocompleteChange = (name, value) => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                value
            }
        });
    };

    const downloadCreditBalanceHistory = async () => {
        await download({
            type: 'creditHistory',
            openDialog,
            userId: user.id,
            from: moment(values.startEndDate.value[0]).format('YYYY-MM-DD'),
            to: moment(values.startEndDate.value[1]).format('YYYY-MM-DD')
        });
    };

    return (
        <MuiDrawer open={isOpen} anchor={anchor} onClose={onClose}>
            <DrawerContentWrapper
                component="form"
                noValidate
                autoComplete="off"
            >
                <StyledButton variant="text" startIcon={<ChevronLeftIcon />} onClick={onClose}>
                    <Typography variant="button">Back</Typography>
                </StyledButton>
                <Grid container spacing={1} sx={{ mt: '2rem', mb: '3rem', width: '480px' }}>
                    <Grid item xs={7}>
                        <Typography variant="subtitle2">Available Credit Balance</Typography>
                        <Typography variant="h6" color="primary.main">
                            { creditData && creditData.creditBalance }
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ mt: '30px' }}>
                        <Typography variant="subtitle2">Public Key</Typography>
                        <Typography variant="h6" color="primary.main" sx={{ display: 'flex' }}>
                            { publicKey && (`${publicKey.pubKey.substring(0, 6)} . . . ${publicKey.pubKey.substring(publicKey.pubKey.length - 4)}`)}
                            <Button
                                variant="outlined"
                                startIcon={<ContentCopy />}
                                sx={{ ml: '1rem' }}
                                onClick={copyToClipboard}
                            >
                                <Typography variant="button">
                                    {isCopied ? 'Copied!' : 'Copy'}
                                </Typography>
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="subtitle2" sx={{ mb: '1rem' }}>History of transactions</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        calendars={1}
                        value={values.startEndDate.value}
                        onChange={(newValue) => handleAutocompleteChange('startEndDate', newValue)}
                        renderInput={(startProps, endProps) => {
                            let value = '';
                            if (startProps.inputProps.value || endProps.inputProps.value) {
                                value = `${startProps.inputProps.value} - ${endProps.inputProps.value}`;
                            }
                            const props = {
                                ...startProps,
                                inputProps: {
                                    ...startProps.inputProps,
                                    value
                                }
                            };

                            return (
                                <StyledDurationTextField
                                    {...props}
                                    label="Selected period"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                                    }}
                                />
                            );
                        }}
                    />
                </LocalizationProvider>
                <StyledButton
                    variant="text"
                    size="large"
                    sx={{ mt: '2.5rem', width: '100%' }}
                    disabled={!values.startEndDate.value[0] || !values.startEndDate.value[1]}
                    onClick={downloadCreditBalanceHistory}
                >
                    <Typography variant="button">Download history</Typography>
                </StyledButton>
            </DrawerContentWrapper>
        </MuiDrawer>
    );
};

CreditBalanceDrawer.defaultProps = {
    anchor: 'right'
};

CreditBalanceDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    anchor: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

export default CreditBalanceDrawer;
