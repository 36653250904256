import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../hooks/DialogContext';
import Dropzone from '../Dropzone/Dropzone';
import VerificationDialog from './VerificationDialog';

const VerificationDropzone = () => {
    const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
    const [file, setFile] = useState(null);
    const { openDialog } = useContext(DialogContext);

    useEffect(() => {
        if (file) {
            openDialog({
                isOpen: true,
                status: null,
                title: 'File Verification Form',
                content: <VerificationDialog fileData={file} />
            });
            setFile(null);
            setIsDropzoneOpen(false);
        }
    }, [file]);

    const uploadClose = (e) => {
        setFile(e.target.files[0]);
        setIsDropzoneOpen(false);
    };
    const closeDropzone = () => setIsDropzoneOpen(!isDropzoneOpen);

    return (
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <Typography variant="h6" sx={{ pt: 10 }}>
                Choose a file to verify:
                <CardContent
                    sx={{
                        border: '2px solid grey',
                        mt: 5,
                        padding: '16px',
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '500px',
                        height: '150px',
                        cursor: 'pointer',
                        transition: 'box-shadow 0.3s ease',
                        '&:hover': {
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Add shadow on hover
                        },
                    }}
                    onClick={() => setIsDropzoneOpen(true)}
                    component="label"
                >
                    <FileUploadIcon
                        sx={{
                            fontSize: 40,
                            color: 'grey',
                        }}
                    />
                    <input
                        type="file"
                        onChange={(e) => uploadClose(e)}
                        hidden
                    />
                </CardContent>
                {isDropzoneOpen && <Dropzone onClose={closeDropzone} isOpen={isDropzoneOpen} />}
            </Typography>
        </Paper>
    );
};

export default VerificationDropzone;
