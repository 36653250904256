import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha, lighten, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    useGetProjectQuery
} from '../../api/LabtraceApi';
import FileImage from '../../assets/images/file.svg';
// import DeletedFileImage from '../../assets/images/deletedFile.svg';
import DeletedFileImage from '../../assets/images/deletedFile.svg';
import FolderImage from '../../assets/images/folder.svg';
import FolderPrivateImage from '../../assets/images/folderPrivate.svg';
import FileOperationsMenu from '../FilesOperations/FilesOperationsMenu';

const StyledCard = styled(Card)(({ theme }) => ({
    border: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    '& button > svg': {
        color: alpha(theme.palette.common.black, 0.38)
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& button > svg': {
            color: theme.palette.primary.main
        }
    }
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3)
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: '.5rem 0',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '.25rem',
        '& .MuiTypography-root': {
            padding: '1rem',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: lighten(theme.palette.primary.main, 0.96)
            }
        }
    }
}));

const TooltipCloseButtonWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative'
}));

const TooltipCloseButton = styled(IconButton)(() => ({
    position: 'absolute',
    right: '-.25rem',
    top: '-.75rem',
    backgroundColor: 'transparent !important'
}));

const StyledTypo = styled(Typography)(() => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    height: '18px',
    display: 'block',
    webkitLineClamp: '1',
    webkitBoxOrient: 'vertical'
}));

const getImage = (type, status, isPrivate = false) => {
    let image = FileImage;
    if (type === 'folders' || type === 'subFolders') {
        image = isPrivate ? FolderPrivateImage : FolderImage;
    } else if (status === 'Deleted') {
        image = DeletedFileImage;
    }
    return image;
};

const getOnClickHandler = (item, history) => {
    let handler = null;
    if (!item.isPrivate) {
        if (item.onClick) {
            handler = item.onClick();
        } else if (item.href) {
            handler = history.push(item.href);
        }
    }
    return handler;
};

const GridView = ({ items, type, isPrivate }) => {
    const [tooltipId, setTooltipId] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const history = useHistory();
    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId, { skip: !projectId });
    const loader = useSelector((state) => state.loader);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loaderCancelled, setLoaderCancelled] = useState(false);

    const handleTooltipClose = () => {
        setTooltipId(null);
    };

    const useStyles = makeStyles(() => ({
        snackbarWidth: {
            width: '85%',
        }
    }));
    const classes = useStyles();

    const handleCloseSnackbar = () => {
        setIsDownloading(false);
    };

    function truncate(str, max) { return str?.length > max ? `${str.substr(0, max - 1)}…` : str; }

    const action = (
        <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            HIDE
        </Button>
    );

    useEffect(() => {
        setLoaderCancelled(loader.cancelled);
    }, [loader]);

    return (
        <Grid container spacing={4}>
            {
                items.map((item) => (
                    <Grid item xs={4} md={3} lg={1.49} key={item.id}>
                        <Typography variant="caption" sx={{ fontSize: '9px' }} />
                        <StyledCard
                            elevation={0}
                            onClick={() => getOnClickHandler(item, history)}
                            sx={{
                                ...((tooltipId && tooltipId === item.id) && {
                                    cursor: 'pointer',
                                    backgroundColor: lighten('#A31988', 0.96),
                                    '& button > svg': {
                                        color: '#A31988'
                                    },
                                    border: '1px solid #A31988'
                                }),
                                ...(item.isPrivate && {
                                    '&:hover': {
                                        cursor: 'default',
                                        backgroundColor: 'transparent'
                                    }
                                }),
                                ...((item.status && (item.status === 'Deleted')) && { filter: 'grayscale(100%)', opacity: 0.6 }),
                            }}
                        >
                            <CardContent sx={{ position: 'relative' }}>
                                <StyledBox>
                                    <img src={getImage(type, item.status, item.isPrivate)} alt="folder" />
                                </StyledBox>
                                <StyledTypo title={item.title} variant="subtitle2">{truncate(item.title, 35)}</StyledTypo>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {
                                            item.subtitle
                                            && (
                                                <Typography variant="caption" color="text.secondary">
                                                    {item.subtitle}
                                                </Typography>
                                            )
                                        }
                                        {
                                            item.isSecondary
                                            && (
                                                <Typography title="Secondary" variant="caption" color="primary.main">
                                                    &nbsp;(S)
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                    {
                                        item.isDownloadable
                                        && (
                                            <HtmlTooltip
                                                open={Boolean(tooltipId) && tooltipId === item.id}
                                                placement="right-start"
                                                title={
                                                    <Stack spacing={0} direction="column">
                                                        <TooltipCloseButtonWrapper>
                                                            <TooltipCloseButton
                                                                size="small"
                                                                onClick={handleTooltipClose}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </TooltipCloseButton>
                                                        </TooltipCloseButtonWrapper>
                                                    </Stack>
                                                }
                                            >
                                                <Box>
                                                    {project && item
                                                        ? <FileOperationsMenu project={project} file={item} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
                                                        : ''}
                                                </Box>
                                            </HtmlTooltip>
                                        )
                                    }

                                </Box>
                                {
                                    item.user && (
                                        <Box sx={{ display: 'flex', marginTop: '1rem' }}>
                                            <Avatar alt="Joe Doe" src={item.user.avatar} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: '.5rem', overflow: 'hidden' }}>
                                                <Tooltip title={truncate(item.user.name, 30)}>
                                                    <StyledTypo noWrap variant="caption">{truncate(item.user.name, 30)}</StyledTypo>
                                                </Tooltip>
                                                <Tooltip title={item.user.organization}>
                                                    <StyledTypo noWrap variant="caption" color="text.secondary">
                                                        {item.user.organization}
                                                    </StyledTypo>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    )
                                }
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))
            }
            <Snackbar
                open={isDownloading}
                autoHideDuration={6000}
                message={
                    <div style={{ width: 500 }}>
                        <Grid container spacing={2} sx={{ maxWidth: 500 }}>
                            <Grid item xs={4}>
                                Download in progress
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ width: '100%' }} mt={1}>
                                    <LinearProgress />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                }
                classes={
                    { root: classes.snackbarWidth }
                }
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                message={
                    <div style={{ width: 500 }}>
                        <Grid container spacing={2} sx={{ maxWidth: 500 }}>
                            <Grid item xs={4}>
                                Download in progress
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ width: '100%' }} mt={1}>
                                    <LinearProgress />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                }
                classes={
                    { root: classes.snackbarWidth }
                }
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </Grid>
    );
};

GridView.defaultProps = {
    isPrivate: false,
};

GridView.propTypes = {
    type: PropTypes.oneOf(['folders', 'subFolders', 'files']).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    isPrivate: PropTypes.bool,
};

export default GridView;
