import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, lighten, styled } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useGetFilesQuery,
    useGetPrivateFilesQuery,
    useUploadFileMutation,
    useUploadPrivateFileMutation
} from '../../../api/LabtraceApi';
import { DialogContext } from '../../../hooks/DialogContext';
import { Tag, TagsWrapper } from '../../Tags/Tags';

import FileImage from '../../../assets/images/file.svg';

const filter = createFilterOptions();

const ScrollableArea = styled(Box)(({ theme }) => ({
    maxHeight: '31.5rem',
    margin: '0 -1rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: '.25rem'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0.12)
    }
}));

const StyledFileInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3rem 0'
}));

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
    width: '100%',
    '& label': {
        lineHeight: 'unset'
    },
    '& .MuiAutocomplete-endAdornment': {
        display: 'none'
    }
}));

const File = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',
    padding: '0 1rem',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    cursor: 'pointer',
    '& > button': {
        display: 'none',
        '& > svg': {
            color: theme.palette.primary.main
        }
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& > button': {
            display: 'block'
        }
    }
}));

const RestOfFiles = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '.5rem',
    color: theme.palette.primary.main
}));

const UploadForm = ({ fileData, projectId, isPrivateFolder, progressCallback }) => {
    const [values, setValues] = useState({
        type: {
            value: ''
        },
        label: {
            value: null,
            options: []
        },
        primaryData: {
            value: null,
            options: []
        },
        links: {
            value: null,
            options: []
        },
        description: {
            value: ''
        }
    });
    const [primaryDataFiles, setPrimaryDataFiles] = useState([]);
    const user = useSelector((state) => state.user);
    const { closeDialog, openDialog } = useContext(DialogContext);

    let files;
    let filesQueryResponse;
    let uploadFile;
    let isLoading;
    let refetchFiles;
    if (isPrivateFolder) {
        const mutationResponse = useUploadPrivateFileMutation();
        const queryResponse = useGetPrivateFilesQuery(projectId, { refetchOnMount: true });

        files = queryResponse.data;
        refetchFiles = queryResponse.refetch;
        filesQueryResponse = queryResponse;
        uploadFile = mutationResponse[0];
        isLoading = mutationResponse[1].isLoading;
    } else {
        const mutationResponse = useUploadFileMutation();
        const queryResponse = useGetFilesQuery(projectId, { refetchOnMount: true });

        files = queryResponse.data;
        refetchFiles = queryResponse.refetch;
        filesQueryResponse = queryResponse;
        uploadFile = mutationResponse[0];
        isLoading = mutationResponse[1].isLoading;
    }

    useEffect(() => {
        if (files && files.data && files.data.records) {
            console.log('useEffect', files.data.records.length);
            const primaryData = files.data.records
                .filter((record) => (record.type === 'primary' && record.status !== 'Deleted'))
                .map((record) => ({
                    id: record.id,
                    name: record.name,
                    description: '',
                    tags: record.label
                }));
            const links = files.data.records.filter((record) => record.status !== 'Deleted')
                .map((record) => ({
                    id: record.id,
                    name: record.name
                }));

            setValues({
                ...values,
                primaryData: {
                    ...values.primaryData,
                    options: primaryData
                },
                links: {
                    ...values.links,
                    options: links
                }
            });
        }
    }, [files]);

    const handleInputChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value
            },
            ...(prop === 'description' && event.target.value && { links: { ...values.links, value: null } })
        });
    };

    const handleAutocompleteChange = (name, value) => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                value
            },
            ...(name === 'links' && value && { description: { ...values.description, value: '' } })
        });
    };

    const addNewTag = (label) => {
        const existingLabel = [...values.label.options].filter((option) => option.label === label).shift();

        if (!existingLabel) {
            const labels = [...values.label.options];
            labels.push({
                id: values.label.options.length + 1,
                label
            });

            setValues({
                ...values,
                label: {
                    ...values.label,
                    options: labels
                }
            });
        }
    };

    const removeTag = (id) => {
        const labels = [...values.label.options].filter((label) => label.id !== id);

        setValues({
            ...values,
            label: {
                ...values.label,
                options: labels
            }
        });
    };

    const removeFile = (file) => {
        const newFiles = [...primaryDataFiles].filter((singleFile) => singleFile.id !== file.id);

        setPrimaryDataFiles(newFiles);
        const newPrimaryData = values.primaryData.options;
        newPrimaryData.push(file);
        setValues({
            ...values,
            primaryData: {
                ...values.primaryData,
                options: newPrimaryData
            }
        });
    };

    const addFile = (file) => {
        setPrimaryDataFiles([file, ...primaryDataFiles]);
        const newPrimaryData = values.primaryData.options.filter((option) => file.id !== option.id);
        setValues({
            ...values,
            primaryData: {
                ...values.primaryData,
                options: newPrimaryData
            }
        });
    };

    const wait = async (seconds) => new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });

    const handleFileUpload = async () => {
        try {
            progressCallback.setIsUploading(true);

            const formData = new FormData();
            formData.append('file', fileData);
            formData.append('label', values.label.options.map((option) => option.label));
            formData.append('type', values.type.value);

            if (!isPrivateFolder) {
                formData.append('uploaded', moment().format('YYYY-MM-DD'));
            }

            if (isPrivateFolder) {
                formData.append('ownerName', user.name);
                formData.append('ownerLastName', user.lastName);
            }

            if (values.type.value === 'secondary') {
                formData.append('linkToProcedure', [values.links.value ? values.links.value.id : '']);
                formData.append('procedureDescription', values.description.value);
                formData.append('primaryData', primaryDataFiles.map((record) => record.id));
            }

            const isFileAlreadyPresent = files.data.records.some((file) => file.name === fileData.name.replace(/_/g, '-') && file.status === 'Available');

            if (isFileAlreadyPresent) {
                openDialog({
                    isOpen: true,
                    status: 'error',
                    title: 'An error occured',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            File with the same name already exist in the platform.
                        </Typography>
                    )
                });
            } else {
                await uploadFile({
                    projectId,
                    payload: formData
                }).unwrap();

                await wait(40);
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Congratulations!',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            You have uploaded a new file.
                        </Typography>
                    )
                });
            }

            progressCallback.setIsUploading(false);
        } catch (error) {
            await wait(40);
            refetchFiles();
            // 'FETCH_ERROR' or 503 error status is due to a backend timeout, but the file still loads correctly in the background
            if (error.status === 'FETCH_ERROR') {
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Congratulations!',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            You have uploaded a new file.
                        </Typography>
                    )
                });

                progressCallback.setIsUploading(false);
            } else if (error.status === 409 || error.status === 403) {
                refetchFiles();
                await wait(20);
                refetchFiles();
                const isFileCorrectlyUploaded = files.data.records.some((file) => file.name === fileData.name.replace(/_/g, '-') && file.status === 'Available');

                if (isFileCorrectlyUploaded) {
                    openDialog({
                        isOpen: true,
                        status: 'success',
                        title: 'Congratulations!',
                        content: (
                            <Typography variant="body2" sx={{ mt: '1rem' }}>
                                You have uploaded a new file.
                            </Typography>
                        )
                    });
                } else {
                    openDialog({
                        isOpen: true,
                        status: 'error',
                        title: 'An error occured',
                        content: (
                            <Typography variant="body2" sx={{ mt: '1rem' }}>
                                {
                                    (error && error.data)
                                        ? error.data.error.responseMessage
                                        : 'Could not upload a file. Please try again.'
                                }
                            </Typography>
                        )
                    });
                }
                progressCallback.setIsUploading(false);
            } else {
                openDialog({
                    isOpen: true,
                    status: 'error',
                    title: 'An error occured',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            {
                                (error && error.data)
                                    ? error.data.error.responseMessage
                                    : 'Could not upload a file. Please try again.'
                            }
                        </Typography>
                    )
                });
                progressCallback.setIsUploading(false);
            }
        }
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <ScrollableArea>
                <Box sx={{ px: '1rem' }}>
                    <StyledFileInfo>
                        <img src={FileImage} alt="file" />
                        <Typography variant="subtitle2" sx={{ textDecoration: 'underline', mt: '1.5rem' }}>
                            {fileData.name}
                        </Typography>
                    </StyledFileInfo>
                    <Stack spacing={3}>
                        <FormControl fullWidth>
                            <InputLabel id="file-type-label">File type</InputLabel>
                            <Select
                                labelId="file-type-label"
                                id="file-type-select"
                                value={values.type.value}
                                label="File type"
                                onChange={handleInputChange('type')}
                            >
                                <MenuItem value="primary">Primary</MenuItem>
                                <MenuItem value="secondary">Secondary</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            values.type.value
                            && (
                                <>
                                    <Autocomplete
                                        id="label"
                                        freeSolo
                                        selectOnFocus
                                        clearOnBlur
                                        sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                                        options={values.label.options}
                                        value={values.label.value}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string') {
                                                return '';
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }

                                            return option.label;
                                        }}
                                        onChange={((event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                addNewTag(newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                handleAutocompleteChange('tags', newValue.inputValue);
                                            } else {
                                                handleAutocompleteChange('tags', newValue);
                                            }
                                        })}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option.label);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.unshift({
                                                    inputValue,
                                                    label: 'Press Enter to create a new label',
                                                });
                                            }

                                            return filtered;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Label"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password'
                                                }}
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                    />
                                    {
                                        values.label.options.length > 0
                                        && (
                                            <TagsWrapper direction="row" spacing={1}>
                                                {
                                                    values.label.options.map((option) => (
                                                        <Tag
                                                            key={option.id}
                                                            label={option.label}
                                                            onDelete={() => removeTag(option.id)}
                                                        />
                                                    ))
                                                }
                                            </TagsWrapper>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            values.type.value === 'secondary'
                            && (
                                <>
                                    <Box>
                                        <StyledAutocomplete
                                            id=""
                                            selectOnFocus
                                            blurOnSelect
                                            clearOnBlur
                                            options={values.primaryData.options}
                                            value={values.primaryData.value}
                                            getOptionLabel={(option) => option.name}
                                            onChange={((event, newValue) => addFile(newValue))}
                                            filterOptions={(options, params) => {
                                                let filtered = options;
                                                if (params.inputValue) {
                                                    const value = params.inputValue.toLowerCase();
                                                    filtered = options.filter((option) => {
                                                        const descMatch = option.name.toLowerCase().search(value) >= 0;
                                                        const tagMatch = option.tags.some(
                                                            (tag) => tag.toLowerCase().search(value) >= 0
                                                        );

                                                        return descMatch || tagMatch;
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Stack spacing={0}>
                                                        <Typography variant="body2">{option.name}</Typography>
                                                        <Typography variant="caption">{option.tags.join('; ')}</Typography>
                                                    </Stack>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Primary data"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                        />
                                        {
                                            primaryDataFiles.length > 0
                                            && (
                                                primaryDataFiles.map((file, index) => (
                                                    index < 2
                                                        ? (
                                                            <File key={file.id}>
                                                                {file.name}
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => removeFile(file)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </File>
                                                        )
                                                        : null
                                                ))
                                            )
                                        }
                                        {
                                            primaryDataFiles.length > 2
                                            && (
                                                <RestOfFiles>
                                                    +
                                                    {primaryDataFiles.length - 2}
                                                    {primaryDataFiles.length > 3 ? ' files' : ' file'}
                                                </RestOfFiles>
                                            )
                                        }
                                    </Box>
                                    <Box>
                                        <StyledAutocomplete
                                            id=""
                                            selectOnFocus
                                            blurOnSelect
                                            options={values.links.options}
                                            value={null}
                                            disabled={values.description.value !== ''}
                                            getOptionLabel={(option) => option.name}
                                            onChange={((event, newValue) => handleAutocompleteChange('links', newValue))}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Typography variant="body2">{option.name}</Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Link to procedure"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                        />
                                        {
                                            values.links.value && (
                                                <File>
                                                    {values.links.value.name}
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setValues({
                                                                ...values,
                                                                links: {
                                                                    ...values.links,
                                                                    value: null
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </File>
                                            )
                                        }
                                    </Box>
                                    <Typography variant="body2" align="center" color="text.secondary">OR</Typography>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        disabled={values.links.value !== null}
                                    >
                                        <InputLabel htmlFor="outlined-description">
                                            <Typography variant="subtitle1">Procedure description</Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-description"
                                            type="text"
                                            value={values.description.value}
                                            onChange={handleInputChange('description')}
                                            label="Procedure description"
                                        />
                                    </FormControl>
                                </>
                            )
                        }
                    </Stack>
                </Box>
            </ScrollableArea>
            <StyledActionWrapper>
                <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                    <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                        <Typography variant="button">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={handleFileUpload}
                        disabled={isLoading}
                    >
                        <Typography variant="button">
                            Upload
                        </Typography>
                    </Button>
                </Stack>
            </StyledActionWrapper>
        </Box>
    );
};

UploadForm.propTypes = {
    fileData: PropTypes.any.isRequired,
    projectId: PropTypes.string.isRequired,
    isPrivateFolder: PropTypes.bool.isRequired,
    progressCallback: PropTypes.object.isRequired,
};

export default UploadForm;
