import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { transformFilesResponse } from '../adapters/FilesAdapter';
import { getToken, removeToken } from '../services/Auth/Token';
import { resetUser } from '../slices/User';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_INTERNAL_URL,
    prepareHeaders: (headers) => {
        if (getToken()) {
            headers.set('Authorization', `Bearer ${getToken()}`);
        }
        return headers;
    }
});
const baseQueryWithInterceptor = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        removeToken();
        api.dispatch(resetUser());
    }
    return result;
};

export const LabtraceApi = createApi({
    reducerPath: 'labtraceApi',
    baseQuery: baseQueryWithInterceptor,
    tagTypes: [
        'Users', 'User', 'Admin', 'Projects', 'Project', 'Files', 'OnHoldProjects', 'Tags', 'CreditBalance', 'Avatar',
        'PrivateConfig', 'PrivateFiles'
    ],
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (payload) => ({
                url: 'users',
                method: 'POST',
                body: payload
            })
        }),
        login: builder.mutation({
            query: (payload) => ({
                url: 'login',
                method: 'POST',
                body: payload
            })
        }),
        getUsers: builder.query({
            query: () => 'users',
            providesTags: ['Users']
        }),
        getUser: builder.query({
            query: (userId) => `users/${userId}`,
            providesTags: ['User']
        }),
        updateUser: builder.mutation({
            query: ({ userId, ...payload }) => ({
                url: `users/${userId}`,
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ['Users', 'User']
        }),
        getIsUserAdmin: builder.query({
            query: (userId) => `users/${userId}/admin`,
            providesTags: ['User', 'Admin']
        }),
        getProjects: builder.query({
            query: () => 'projects',
            providesTags: ['Projects']
        }),
        createProject: builder.mutation({
            query: (payload) => ({
                url: 'projects',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['Projects', 'CreditBalance']
        }),
        updateProject: builder.mutation({
            query: ({ projectId, ...payload }) => ({
                url: `projects/${projectId}`,
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ['Projects', 'Project']
        }),
        getProject: builder.query({
            query: (projectId) => `projects/${projectId}`,
            providesTags: ['Project']
        }),
        addProjectMember: builder.mutation({
            query: ({ projectId, userId }) => ({
                url: `projects/${projectId}/members/${userId}`,
                method: 'POST'
            }),
            invalidatesTags: ['Projects', 'Project', 'CreditBalance']
        }),
        removeProjectMember: builder.mutation({
            query: ({ projectId, userId }) => ({
                url: `projects/${projectId}/members/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Projects', 'Project']
        }),
        getFiles: builder.query({
            query: (projectId) => `projects/${projectId}/files`,
            transformResponse: (response) => transformFilesResponse(response),
            providesTags: ['Files']
        }),
        getPrivateFiles: builder.query({
            query: (projectId) => `/private-files/project/${projectId}`,
            transformResponse: (response) => transformFilesResponse(response),
            providesTags: ['PrivateFiles']
        }),
        uploadFile: builder.mutation({
            query: ({ projectId, payload }) => ({
                url: `projects/${projectId}/files`,
                method: 'POST',
                body: payload,
                prepareHeaders: (headers) => {
                    headers.set('Content-Type', 'multipart/form-data');
                    return headers;
                }
            }),
            invalidatesTags: ['Files', 'CreditBalance']
        }),
        uploadPrivateFile: builder.mutation({
            query: ({ projectId, payload }) => ({
                url: `/private-files/project/${projectId}`,
                method: 'POST',
                body: payload,
                prepareHeaders: (headers) => {
                    headers.set('Content-Type', 'multipart/form-data');
                    return headers;
                }
            }),
            invalidatesTags: ['PrivateFiles', 'CreditBalance']
        }),
        verifyFile: builder.mutation({
            query: ({ payload }) => ({
                url: '/projects/files/verify',
                method: 'POST',
                body: payload,
                prepareHeaders: (headers) => {
                    headers.set('Content-Type', 'multipart/form-data');
                    return headers;
                }
            }),
            invalidatesTags: []
        }),
        getOnHoldProjects: builder.query({
            query: (userId) => `projects/${userId}/on-hold`,
            providesTags: ['OnHoldProjects']
        }),
        acceptInvitation: builder.mutation({
            query: ({ projectId, userId }) => ({
                url: `/projects/${projectId}/members/${userId}/activate`,
                method: 'POST'
            }),
            invalidatesTags: ['Projects', 'Project', 'OnHoldProjects']
        }),
        getPendingInvitations: builder.mutation({
            query: (userId) => `projects/${userId}/on-hold`
        }),
        forgottenPassword: builder.mutation({
            query: (email) => ({
                url: `users/forgotten-password/${email}`,
                method: 'GET'
            })
        }),
        resetPassword: builder.mutation({
            query: ({ token, ...payload }) => ({
                url: `users/reset-password/${token}`,
                method: 'POST',
                body: payload
            })
        }),
        getTags: builder.query({
            query: () => 'tags',
            providesTags: ['Tags']
        }),
        saveTag: builder.mutation({
            query: (tag) => ({
                url: 'tags',
                method: 'POST',
                body: {
                    name: tag
                }
            }),
            invalidatesTags: ['Tags']
        }),
        getUserPublicKey: builder.query({
            query: (userId) => `/users/${userId}/pubkey`
        }),
        getCreditBalance: builder.query({
            query: (userId) => `/users/${userId}/credit-balance`,
            providesTags: ['CreditBalance']
        }),
        getAvatar: builder.query({
            query: (userId) => `/users/${userId}/avatar`,
            providesTags: ['Avatar']
        }),
        addAvatar: builder.mutation({
            query: ({ userId, payload }) => ({
                url: `/users/${userId}/avatar`,
                method: 'POST',
                body: payload,
                prepareHeaders: (headers) => {
                    headers.set('Content-Type', 'multipart/form-data');
                    return headers;
                }
            }),
            invalidatesTags: ['Avatar']
        }),
        getPrivateFolderConfig: builder.query({
            query: (projectId) => `/projects/${projectId}/private-folder`,
            providesTags: ['PrivateConfig']
        }),
        addPrivateFolderConfig: builder.mutation({
            query: ({ projectId, payload }) => ({
                url: `/projects/${projectId}/private-folder`,
                method: 'PATCH',
                body: payload
            }),
            invalidatesTags: ['PrivateConfig']
        }),
        canCreateProject: builder.query({
            query: (userId) => `/users/${userId}/can-create-project`
        }),
        canInviteMember: builder.query({
            query: (userId) => `/users/${userId}/can-invite-member`
        }),
        getUserData: builder.mutation({
            query: (userId) => `users/${userId}`,
            providesTags: ['User']
        }),
        suspendUserAccount: builder.mutation({
            query: ({ adminId, userEmail, ...payload }) => ({
                url: `/users/admin/${adminId}/suspend/${userEmail}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['User', 'Users', 'Admin']
        }),
        revokeSuspensionUserAccount: builder.mutation({
            query: ({ adminId, userEmail }) => ({
                url: `/users/admin/${adminId}/revoke-suspension/${userEmail}`,
                method: 'PATCH',
            }),
            invalidatesTags: ['User', 'Users', 'Admin']
        }),
        updateUserCreditsByAdmin: builder.mutation({
            query: ({ adminId, userId, ...payload }) => ({
                url: `/users/admin/${adminId}/update_user_credits/${userId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['User', 'Users', 'Admin', 'CreditBalance']
        }),
        deletePublicFile: builder.mutation({
            query: ({ projectId, fileId, ...payload }) => ({
                url: `/projects/${projectId}/file/${fileId}/delete`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['Projects', 'Project', 'Tags', 'Files']
        }),
        deletePrivateFile: builder.mutation({
            query: ({ projectId, fileId, ...payload }) => ({
                url: `/private-files/project/${projectId}/file/${fileId}/delete`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['PrivateFiles']
        })
    })
});

export const {
    useGetProjectsQuery,
    useCreateProjectMutation,
    useLoginMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useGetIsUserAdminQuery,
    useGetProjectQuery,
    useGetFilesQuery,
    useGetPrivateFilesQuery,
    useLazyGetPrivateFilesQuery,
    useGetUsersQuery,
    useUpdateProjectMutation,
    useAddProjectMemberMutation,
    useRemoveProjectMemberMutation,
    useUploadFileMutation,
    useUploadPrivateFileMutation,
    useVerifyFileMutation,
    useGetOnHoldProjectsQuery,
    useAcceptInvitationMutation,
    useGetPendingInvitationsMutation,
    useRegisterMutation,
    useForgottenPasswordMutation,
    useResetPasswordMutation,
    useGetTagsQuery,
    useSaveTagMutation,
    useGetUserPublicKeyQuery,
    useGetCreditBalanceQuery,
    useGetAvatarQuery,
    useAddAvatarMutation,
    useGetPrivateFolderConfigQuery,
    useAddPrivateFolderConfigMutation,
    useCanCreateProjectQuery,
    useCanInviteMemberQuery,
    useGetUserDataMutation,
    useSuspendUserAccountMutation,
    useRevokeSuspensionUserAccountMutation,
    useUpdateUserCreditsByAdminMutation,
    useDeletePublicFileMutation,
    useDeletePrivateFileMutation
} = LabtraceApi;
