import { Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Joi from 'joi';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserCreditsByAdminMutation } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const StyledAvatar = styled(Avatar)(() => ({
    width: '2.75rem',
    height: '2.75rem',
    marginLeft: '-1rem !important'
}));

const StyledValue = styled('span')({
    fontWeight: 'bold',
    height: '1.5rem',
    color: 'black',
});

const AdminCreditsManagementForm = ({ selectedUser }) => {
    const { openDialog, closeDialog } = useContext(DialogContext);
    const user = useSelector((state) => state.user);
    const [updateUserCredits, { isLoading: updateUserCreditsLoading }] = useUpdateUserCreditsByAdminMutation();
    const [values, setValues] = useState ({
        operationType: {
            value: null,
            error: '',
            pattern: {
                name: 'required',
                message: 'Operation type is required.'
            }
        },
        creditAmount: {
            value: '',
            error: '',
            pattern: Joi.number().required().messages({
                'number.base': 'Credit amount is required.'
            })
        }
    });

    const areAllRequiredFieldsPopulated = () => {
        let allFieldsPopulated = true;
        const newValues = { ...values };

        Object.entries(values).forEach(([field, fieldValue]) => {
            let error = '';
            if (fieldValue.pattern && !fieldValue.pattern.name) {
                const patternError = fieldValue.pattern.validate(fieldValue.value).error;

                if (patternError) {
                    allFieldsPopulated = false;
                    error = patternError.details[0].message;
                }
            } else if (fieldValue.pattern && fieldValue.pattern.name) {
                if (((fieldValue.pattern.name === 'required') && !fieldValue.value)
                ) {
                    error = fieldValue.pattern.message;
                    allFieldsPopulated = false;
                }
            }
            newValues[field] = {
                ...values[field],
                error
            };
        });
        setValues(newValues);

        return allFieldsPopulated;
    };

    const handleCheckboxChange = (operation) => {
        let error = '';
        if (!operation) {
            error = values.operationType.pattern.message;
        }
        setValues({
            ...values,
            operationType: {
                ...values.operationType,
                value: operation,
                error: error ? error.details[0].message : ''
            }
        });
    };

    const handleInputChange = (prop) => (event) => {
        const error = values.creditAmount.pattern ? values.creditAmount.pattern.validate(event.target.value).error : null;
        setValues({
            ...values,
            [prop]: {
                ...values.creditAmount,
                value: event.target.value,
                error: error ? error.details[0].message : ''
            },
        });
    };

    const manageUserCredits = async () => {
        if (!areAllRequiredFieldsPopulated()) return;
        try {
            await updateUserCredits({ adminId: user.id, userId: selectedUser.id, amount: Number(values.creditAmount.value), operationType: values.operationType.value }).unwrap();
            openDialog({
                isOpen: true,
                status: 'success',
                title: 'Done',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        The user&apos;s credit balance has been successfully updated.
                        <br />
                        The user will receive a notification via email.
                    </Typography>
                )
            });
        } catch (error) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'Balance not updated',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Unexpected error. Please try again later.
                    </Typography>
                )
            });
        }
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none',
        }
    }));
    const classes = useStyles();

    return (
        updateUserCreditsLoading
            ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    component="form"
                    sx={{ mt: '2rem' }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    {selectedUser.avatar
                                        ? <Avatar src={selectedUser.avatar} />
                                        : (
                                            <AvatarGroup max={4} sx={{ ml: '1rem !important' }}>
                                                <StyledAvatar key="stiled_avatar_credit_management_form">
                                                    {selectedUser.name.charAt(0)}
                                                    {selectedUser.lastName.charAt(0)}
                                                </StyledAvatar>
                                            </AvatarGroup>
                                        )}
                                </Grid>
                                <Grid item>
                                    <Stack>
                                        <StyledValue>{selectedUser.fullName}</StyledValue>
                                        <Typography variant="caption" sx={{ color: '#9e9e9e' }}>{selectedUser.email}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginLeft: '1.5rem' }}>
                                        {selectedUser.creditBalance}
                                        {' '}
                                        Credits
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Box sx={{ mt: '0.5rem' }}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label-1"
                                            name="row-radio-buttons-group-1"
                                            defaultValue="1"
                                        >
                                            <FormControlLabel
                                                sx={{ ml: '-.5rem' }}
                                                control={
                                                    <Radio checked={Boolean(values.operationType.value && values.operationType.value === 'refill')} />
                                                }
                                                onChange={() => handleCheckboxChange('refill')}
                                                label={
                                                    <Typography variant="body2">Refill</Typography>
                                                }
                                            />
                                            <FormControlLabel
                                                sx={{}}
                                                control={
                                                    <Radio checked={Boolean(values.operationType.value && values.operationType.value === 'remove')} />
                                                }
                                                onChange={() => handleCheckboxChange('remove')}
                                                label={
                                                    <Typography variant="body2">Remove</Typography>
                                                }
                                            />
                                        </RadioGroup>
                                        {
                                            values.operationType.error
                          && (
                              <FormHelperText id="outlined-email-helper-text" sx={{ ml: '14px !important' }}>
                                  <StyledErrorText variant="caption">{values.operationType.error}</StyledErrorText>
                              </FormHelperText>
                          )
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-credit-amount">
                                            <Typography variant="subtitle1">Define amount of credits</Typography>
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-credit-amount"
                                            type="number"
                                            value={values.creditAmount.value}
                                            onChange={handleInputChange('creditAmount')}
                                            label="Credit Amount"
                                        />
                                        {
                                            values.creditAmount.error
                        && (
                            <FormHelperText id="outlined-email-helper-text">
                                <StyledErrorText variant="caption">{values.creditAmount.error}</StyledErrorText>
                            </FormHelperText>
                        )
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <StyledActionWrapper>
                        <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                            <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                                <Typography variant="button">Cancel</Typography>
                            </Button>
                            <Button variant="contained" size="large" fullWidth onClick={manageUserCredits}>
                                <Typography variant="button">Save</Typography>
                            </Button>
                        </Stack>
                    </StyledActionWrapper>
                </Box>
            )
    );
};

AdminCreditsManagementForm.propTypes = {
    selectedUser: PropTypes.any.isRequired
};

export default AdminCreditsManagementForm;
