import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FiltersAdminContext } from '../../hooks/FilterAdminContext';
import AddAdminFilterForm from '../DashboardFilters/components/AddFilterForm/AddAdminFilterForm';

const FilterButton = ({ startIcon, endIcon, activeFilter, filterId, action, name, doubleClickAction }) => (
    <Button
        variant="outlined"
        size="large"
        disableElevation
        startIcon={startIcon && startIcon}
        endIcon={endIcon && endIcon}
        sx={{
            maxWidth: '18rem',
            ...(activeFilter === filterId && { backgroundColor: 'rgba(163, 25, 136, 0.12)' })
        }}
        onClick={action}
        onDoubleClick={doubleClickAction}
    >
        <Tooltip title={name}>
            <Typography variant="button" noWrap>{name}</Typography>
        </Tooltip>
    </Button>
);

FilterButton.defaultProps = {
    startIcon: null,
    endIcon: null,
    doubleClickAction: null
};

FilterButton.propTypes = {
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    activeFilter: PropTypes.string.isRequired,
    filterId: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    doubleClickAction: PropTypes.func,
    name: PropTypes.string.isRequired
};

const getFilterName = (filter) => {
    const filterCopy = { ...filter };

    delete filterCopy.id;

    return Object.values(filterCopy)
        .map((value) => {
            let propertyValue = null;
            if (value) {
                propertyValue = (typeof value === 'object') ? value.label : value;
            }

            return propertyValue;
        })
        .filter((value) => value)
        .join('; ');
};

const DashboardAdminFilters = ({ numberOfRecords }) => {
    const {
        activeFilter, setActiveFilter, editableFilter, setEditableFilter, customFilters
    } = useContext(FiltersAdminContext);
    return (
        <Grid container sx={{ py: '2rem' }}>
            <Grid item xs={10}>
                <Stack spacing={2} direction="row">
                    <FilterButton
                        filterId="all"
                        activeFilter={activeFilter}
                        action={() => setActiveFilter('all')}
                        name={`All users (${numberOfRecords})`}
                    />
                    <FilterButton
                        filterId="active"
                        activeFilter={activeFilter}
                        action={() => setActiveFilter('active')}
                        name="Only active"
                    />
                    <FilterButton
                        filterId="hold"
                        activeFilter={activeFilter}
                        action={() => setActiveFilter('hold')}
                        name="Only on hold"
                    />
                    <FilterButton
                        filterId="suspended"
                        activeFilter={activeFilter}
                        action={() => setActiveFilter('suspended')}
                        name="Only suspended"
                    />
                    {
                        customFilters.map((filter) => (
                            <FilterButton
                                key={filter.id}
                                filterId={filter.id}
                                activeFilter={activeFilter}
                                action={() => setActiveFilter(filter.id)}
                                doubleClickAction={() => setEditableFilter(filter)}
                                endIcon={(editableFilter && filter.id === editableFilter.id) && <EditIcon />}
                                name={getFilterName(filter)}
                            />
                        ))

                    }
                    <Box>
                        <FilterButton
                            filterId="add"
                            activeFilter={activeFilter}
                            action={() => setActiveFilter('add')}
                            startIcon={<AddIcon />}
                            name="Add filter"
                        />
                    </Box>
                </Stack>
            </Grid>
            {
                (activeFilter === 'add' || editableFilter)
            && (
                <Grid item xs={12}>
                    <AddAdminFilterForm />
                </Grid>
            )
            }
        </Grid>
    );
};

DashboardAdminFilters.defaultProps = {
    numberOfRecords: 0
};

DashboardAdminFilters.propTypes = {
    numberOfRecords: PropTypes.number
};

export default DashboardAdminFilters;