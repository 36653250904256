import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    useGetCreditBalanceQuery,
    useGetIsUserAdminQuery,
    useGetOnHoldProjectsQuery
} from '../../../../api/LabtraceApi';
import LogoImage from '../../../../assets/images/logo.svg';
import Divider from '../../../../components/Divider/Divider';
import { setUser } from '../../../../slices/User';
import AdminMenu from './components/AdminMenu/AdminMenu';
import Notifications from './components/Notifications/Notifications';
import UserProfile from './components/UserProfile/UserProfile';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
    height: '5rem',
    display: 'flex',
    justifyContent: 'center'
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: 'transparent',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(11.5),
    width: '100%'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.common.white,
    border: `1px solid ${alpha(theme.palette.common.white, 0.5)}`,
    borderRadius: '3.5px',
    '& .MuiInputBase-input': {
        padding: theme.spacing(2, 1, 2, 2),
        width: '100%'
    },
    width: '100%'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .MuiSvgIcon-root': {
        color: alpha(theme.palette.common.white, 0.75)
    }
}));

const CreditBalance = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.common.white, 0.6)
}));

const CreditBalanceWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(4)
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 0)
}));

const Header = () => {
    const user = useSelector((state) => state.user);
    const { data: onHoldProjects } = useGetOnHoldProjectsQuery(user.id, { skip: !user.id });
    const { data: creditData } = useGetCreditBalanceQuery(user.id, { skip: !user.id });
    const [notifications, setNotifications] = useState([]);
    const { location: { pathname } } = useHistory();
    const totalOnHoldProjects = onHoldProjects ? onHoldProjects.totalRecords : 0;
    const { data: isUserAdmin } = useGetIsUserAdminQuery(user.id, { skip: !user.id });
    const [activeHref, setActiveHref] = useState('projects');
    const dispatch = useDispatch();

    useEffect(() => {
        if (isUserAdmin && isUserAdmin.isAdmin) {
            dispatch(setUser({
                isAdmin: isUserAdmin.isAdmin
            }));
        }
    }, []);

    useEffect(() => {
        if (totalOnHoldProjects > 0) {
            const onHoldNotifications = onHoldProjects.records.map((project) => ({
                id: project.id,
                avatar: 'NN',
                title: `${project.name}: You have been invited as a member`,
                timePassed: 'Unknown time',
                isInvitation: true
            }));

            setNotifications(onHoldNotifications);
        } else if (totalOnHoldProjects === 0 && notifications.length > 0) {
            setNotifications([]);
        }
    }, [totalOnHoldProjects]);

    useEffect(() => {
        if (pathname.includes('projects')) { setActiveHref('projects'); }
        if (pathname.includes('verification')) { setActiveHref('verification'); }
        if (pathname.includes('admin')) { setActiveHref('admin'); }
    }, [pathname]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="static" elevation={0}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={8} lg={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mr: 2 }}>
                                <Link to="/dashboard">
                                    <img src={LogoImage} alt="LabTrace" />
                                </Link>
                            </Box>
                            <AdminMenu title="Projects" href="projects" activeHref={activeHref} />
                            <AdminMenu title="Verify" href="verification" activeHref={activeHref} />
                            { isUserAdmin && isUserAdmin.isAdmin ? (
                                <AdminMenu
                                    title="User Management"
                                    href="admin"
                                    activeHref={activeHref}
                                />)
                                : null}
                            <Search sx={{ display: 'none' }}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>
                        </Grid>
                        <StyledGrid item xs={4} lg={6}>
                            <Notifications notifications={notifications} />
                            <Divider orientation="vertical" />
                            <CreditBalanceWrapper>
                                <CreditBalance variant="caption">
                                    Credit Balance
                                </CreditBalance>
                                <Typography variant="h6" sx={{ lineHeight: '1' }}>
                                    { creditData ? creditData.creditBalance : 0 }
                                </Typography>
                            </CreditBalanceWrapper>
                            <UserProfile />
                        </StyledGrid>
                    </Grid>
                </Toolbar>
            </StyledAppBar>
        </Box>
    );
};

export default Header;
