import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EventIcon from '@mui/icons-material/EventOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useGetProjectsQuery } from '../../../../api/LabtraceApi';
import { FiltersContext } from '../../../../hooks/FiltersContext';

const AddFilterFormWrapper = styled(Box)(({ theme }) => ({
    padding: '2rem 0',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`
}));

const StyledDurationTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root.Mui-focused svg': {
        color: theme.palette.primary.main
    }
}));

const AddFilterForm = () => {
    const [values, setValues] = useState({
        tag: {
            value: ''
        },
        projectName: {
            value: null,
            options: []
        },
        projectDuration: {
            value: [null, null]
        },
        projectMember: {
            value: ''
        },
        projectLeader: {
            value: ''
        },
        organization: {
            value: null,
            options: []
        },
        location: {
            value: null,
            options: []
        }
    });
    const {
        customFilters, editableFilter, addFilter, setActiveFilter, updateFilter, removeFilter
    } = useContext(FiltersContext);
    const { data } = useGetProjectsQuery();

    const isApplyButtonDisabled = () => {
        let isDisabled = true;
        if (
            Object.values(values).some((v) => (Array.isArray(v.value) ? Boolean(v.value[0]) : Boolean(v.value)))
        ) {
            isDisabled = false;
        }

        return isDisabled;
    };

    const handleResetAll = () => {
        setValues({
            tag: {
                value: ''
            },
            projectName: {
                ...values.projectName,
                value: null,
            },
            projectDuration: {
                value: [null, null]
            },
            projectMember: {
                value: ''
            },
            projectLeader: {
                value: ''
            },
            organization: {
                ...values.organization,
                value: null
            },
            location: {
                ...values.location,
                value: null
            }
        });
    };

    useEffect(() => {
        const projectNames = [];
        const organizations = [];
        const locations = [];

        if (data) {
            data.records.forEach((record) => {
                if (projectNames.filter((project) => project.label === record.name).length === 0) {
                    projectNames.push({
                        id: projectNames.length + 1,
                        label: record.name
                    });
                }
                if (organizations.filter((org) => org.label === record.organisation).length === 0) {
                    organizations.push({
                        id: organizations.length + 1,
                        label: record.organisation
                    });
                }
                if (locations.filter((loc) => loc.label === record.location).length === 0) {
                    locations.push({
                        id: locations.length + 1,
                        label: record.location
                    });
                }
            });

            setValues({
                tag: {
                    value: editableFilter ? editableFilter.tag : ''
                },
                projectName: {
                    value: editableFilter ? editableFilter.projectName : null,
                    options: projectNames
                },
                projectDuration: {
                    value: editableFilter ? editableFilter.projectDuration : [null, null]
                },
                projectMember: {
                    value: editableFilter ? editableFilter.projectMember : ''
                },
                projectLeader: {
                    value: editableFilter ? editableFilter.projectLeader : ''
                },
                organization: {
                    value: editableFilter ? editableFilter.organization : null,
                    options: organizations
                },
                location: {
                    value: editableFilter ? editableFilter.location : null,
                    options: locations
                }
            });
        }
    }, [data, editableFilter]);

    const handleInputChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value
            }
        });
    };

    const handleAutocompleteChange = (name, value) => {
        setValues({
            ...values,
            [name]: {
                ...values[name],
                value
            }
        });
    };

    const handleUpsert = (type) => {
        const filter = {
            tag: values.tag.value,
            projectName: values.projectName.value,
            projectDuration: values.projectDuration.value,
            projectMember: values.projectMember.value,
            projectLeader: values.projectLeader.value,
            organization: values.organization.value,
            location: values.location.value
        };

        if (type === 'add') {
            const id = String(customFilters.length + 1);
            filter.id = id;
            addFilter(filter);
            setActiveFilter(id);
        } else if (type === 'update') {
            filter.id = editableFilter.id;
            updateFilter(filter);
        }

        handleResetAll();
    };

    return (
        <AddFilterFormWrapper>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-tag">
                            <Typography variant="subtitle1">Tag</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-tag"
                            type="text"
                            value={values.tag.value}
                            onChange={handleInputChange('tag')}
                            label="Tag"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id=""
                        sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                        options={values.projectName.options}
                        value={values.projectName.value}
                        getOptionLabel={(option) => option.label}
                        onChange={((event, newValue) => handleAutocompleteChange('projectName', newValue))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Project name"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'
                                }}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            calendars={1}
                            value={values.projectDuration.value}
                            onChange={(newValue) => handleAutocompleteChange('projectDuration', newValue)}
                            renderInput={(startProps, endProps) => {
                                let value = '';
                                if (startProps.inputProps.value || endProps.inputProps.value) {
                                    value = `${startProps.inputProps.value} - ${endProps.inputProps.value}`;
                                }
                                const props = {
                                    ...startProps,
                                    inputProps: {
                                        ...startProps.inputProps,
                                        value
                                    }
                                };

                                return (
                                    <StyledDurationTextField
                                        {...props}
                                        label="Project duration"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                                        }}
                                    />
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-projectMember">
                            <Typography variant="subtitle1">Project member</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-projectMember"
                            type="text"
                            value={values.projectMember.value}
                            onChange={handleInputChange('projectMember')}
                            label="Project member"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-projectLeader">
                            <Typography variant="subtitle1">Project leader</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-projectLeader"
                            type="text"
                            value={values.projectLeader.value}
                            onChange={handleInputChange('projectLeader')}
                            label="Project leader"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id=""
                        sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                        options={values.organization.options}
                        value={values.organization.value}
                        getOptionLabel={(option) => option.label}
                        onChange={((event, newValue) => handleAutocompleteChange('organization', newValue))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Organization"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'
                                }}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id=""
                        sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                        options={values.location.options}
                        value={values.location.value}
                        getOptionLabel={(option) => option.label}
                        onChange={((event, newValue) => handleAutocompleteChange('location', newValue))}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Location"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'
                                }}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            sx={{ mr: '1rem' }}
                            onClick={handleResetAll}
                        >
                            <Typography variant="button">Reset all</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            disableElevation
                            disabled={isApplyButtonDisabled()}
                            onClick={() => handleUpsert(editableFilter ? 'update' : 'add')}
                        >
                            <Typography variant="button">
                                { editableFilter ? 'Update' : 'Apply' }
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                {
                    editableFilter
                    && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="text"
                                size="large"
                                startIcon={<DeleteIcon />}
                                onClick={() => removeFilter(editableFilter.id)}
                            >
                                <Typography variant="button">Delete this filter</Typography>
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
        </AddFilterFormWrapper>
    );
};

export default AddFilterForm;
