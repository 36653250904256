import Container from '@mui/material/Container';
import React, { StrictMode } from 'react';
import { useLocation } from 'react-router-dom';
import { DialogProvider } from './hooks/DialogContext';
import { FiltersAdminProvider } from './hooks/FilterAdminContext';
import { FiltersProvider } from './hooks/FiltersContext';
import Routes from './routes/Routes';

const LabTrace = () => {
    const location = useLocation();
    const isDashboardAdmin = location.pathname === '/dashboard/admin';
    return (
        <StrictMode>
            <Container maxWidth={false} sx={{ px: '0px !important' }}>
                <DialogProvider>
                    {isDashboardAdmin ? (
                        <FiltersAdminProvider>
                            <Routes />
                        </FiltersAdminProvider>
                    ) : (
                        <FiltersProvider>
                            <Routes />
                        </FiltersProvider>
                    )}
                </DialogProvider>
            </Container>
        </StrictMode>
    );
};

export default LabTrace;
