import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Layout from './components/Layout/Layout';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const Congratulations = () => {
    const history = useHistory();

    return (
        <Layout align="start">
            <StyledTitle variant="h3">Congratulations!</StyledTitle>
            <Typography variant="h5" sx={{ mt: '1.5rem' }}>Your password has been successfully changed.</Typography>
            <Button
                variant="contained"
                size="large"
                disableElevation
                sx={{ marginTop: '3rem' }}
                onClick={() => history.push('/sign-in')}
            >
                <Typography variant="button">Login</Typography>
            </Button>
        </Layout>
    );
};

export default Congratulations;
