import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

const FiltersAdminContext = createContext({});

const FiltersAdminProvider = ({ children }) => {
    const [view, setView] = useState('grid');
    const [activeFilter, setActive] = useState('all');
    const [customFilters, setCustomFilters] = useState([]);
    const [editableFilter, setEditableFilter] = useState(null);

    const addFilter = (newFilter) => {
        const newCustomFilters = [...customFilters, newFilter];
        setCustomFilters(newCustomFilters);
    };

    const removeFilter = (filterId) => {
        const newCustomFilters = [...customFilters].filter((customFilter) => customFilter.id !== filterId);
        setCustomFilters(newCustomFilters);
        setEditableFilter(null);
        setActive('all');
    };

    const updateFilter = (newFilter) => {
        customFilters.splice(customFilters.indexOf(editableFilter), 1, newFilter);
        setEditableFilter(null);
        setActive(newFilter.id);
    };

    const editFilter = (filterId) => {
        const filter = customFilters.filter((customFilter) => customFilter.id === filterId);
        setEditableFilter(filter);
    };

    const setActiveFilter = (filterId) => {
        setActive(filterId);
        if (editableFilter && editableFilter.id !== filterId) {
            setEditableFilter(null);
        }
    };

    const value = useMemo(() => (
        {
            view,
            setView,
            activeFilter,
            setActiveFilter,
            customFilters,
            editableFilter,
            setEditableFilter,
            addFilter,
            removeFilter,
            updateFilter,
            editFilter
        }
    ), [view, activeFilter, customFilters, editableFilter]);

    return (
        <FiltersAdminContext.Provider value={value}>
            {children}
        </FiltersAdminContext.Provider>
    );
};

FiltersAdminProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export { FiltersAdminContext, FiltersAdminProvider };
