import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { LabtraceApi } from '../api/LabtraceApi';
import { loaderSlice } from '../slices/Loader';
import { userSlice } from '../slices/User';

export const Store = configureStore({
    reducer: {
        [LabtraceApi.reducerPath]: LabtraceApi.reducer,
        [userSlice.name]: userSlice.reducer,
        [loaderSlice.name]: loaderSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(LabtraceApi.middleware)
});

setupListeners(Store.dispatch);
