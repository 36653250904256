import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    useGetFilesQuery,
    useGetPrivateFilesQuery,
    useGetProjectQuery
} from '../../api/LabtraceApi';
import DashboardFilters from '../../components/DashboardFilters/DashboardFilters';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import ProjectsGrid from '../../components/GridView/GridView';
import ProjectsTable from '../../components/TableView/TableView';
import { FiltersContext } from '../../hooks/FiltersContext';

const SingleProject = () => {
    const { view } = useContext(FiltersContext);
    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);
    const { data: files } = useGetFilesQuery(projectId);
    const [folders, setFolders] = useState([]);
    const [rows, setRows] = useState([]);
    const user = useSelector((state) => state.user);
    const isProjectMember = project && project.members.records.some((member) => member.id === user.id);
    const isProjectLeader = project && project.leaderId === user.id;
    const { data: privateFiles, status: statusPrivateQuery } = useGetPrivateFilesQuery(projectId);

    const headCells = [
        { id: 'type', label: 'Type of folder' },
        { id: 'files', label: 'Number of files' },
        { id: 'update', label: 'Last update' }
    ];

    const timer = null;
    let responseLoaded = false;

    useEffect(() => () => { clearTimeout(timer); responseLoaded = true; }, []);

    useEffect(() => {
        if (project && files) {
            let projectFolders = [
                {
                    id: '1',
                    title: 'Public folder',
                    subtitle: `${files.filesGridData.length} files`,
                    href: `/dashboard/projects/${projectId}/public-folder`
                },
                {
                    id: '2',
                    title: 'Private folder',
                    subtitle: '--',
                    isPrivate: true,
                    href: `/dashboard/projects/${projectId}/private-folder`
                }
            ];
            let projectRows = [
                {
                    id: '1',
                    type: 'Public',
                    files: files.filesGridData.length,
                    update: files.filesTableData.length > 0 ? files.filesTableData[0].uploaded : '-',
                    href: `/dashboard/projects/${projectId}/public-folder`
                },
                {
                    id: '2',
                    type: 'Private',
                    files: '-',
                    update: '-',
                    isPrivate: true,
                    href: `/dashboard/projects/${projectId}/private-folder`
                }
            ];
            if (
                !isProjectMember
            ) {
                projectFolders = [projectFolders[0]];
                projectRows = [projectRows[0]];
            }
            if (isProjectMember && privateFiles) {
                projectFolders = [
                    projectFolders[0],
                    {
                        id: '2',
                        title: 'Private folder',
                        subtitle: `${privateFiles ? privateFiles.filesGridData.length : '0'} files`,
                        isPrivate: false,
                        href: `/dashboard/projects/${projectId}/private-folder`
                    }
                ];
                projectRows = [
                    projectRows[0],
                    {
                        id: '2',
                        type: 'Private',
                        files: privateFiles ? privateFiles.filesGridData.length : 0,
                        update: privateFiles.filesTableData.length > 0 ? privateFiles.filesTableData[0].uploaded : '-',
                        isPrivate: false,
                        href: `/dashboard/projects/${projectId}/private-folder`
                    }
                ];
            }

            setFolders(projectFolders);
            setRows(projectRows);
        }
    }, [project, files, privateFiles]);

    return (
        <>
            <DashboardHeader
                breadcrumbs={
                    [
                        { id: '1', name: 'Projects', href: '/dashboard/projects' },
                        { id: '2', name: project ? project.name : '' }
                    ]
                }
                singleProject
                subFolders
            />
            <DashboardFilters singleProject />
            {
                view === 'grid'
                    ? <ProjectsGrid items={folders} type="subFolders" />
                    : <ProjectsTable rows={rows} headCells={headCells} />
            }
        </>
    );
};

export default SingleProject;
