import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const DialogContext = createContext({});

const DialogProvider = ({ children }) => {
    const [dialog, openDialog] = useState({
        isOpen: false,
        status: '',
        title: '',
        content: '',
        type: ''
    });

    const closeDialog = () => {
        openDialog({
            isOpen: false,
            status: '',
            title: '',
            content: '',
            type: ''
        });
    };

    const value = useMemo(() => (
        { dialog, openDialog, closeDialog }
    ), [dialog]);

    return (
        <DialogContext.Provider value={value}>
            {children}
        </DialogContext.Provider>
    );
};

DialogProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export {
    DialogContext,
    DialogProvider
};
