import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { styled, alpha } from '@mui/material/styles';

const TagsWrapper = styled(Stack)(({ theme }) => ({
    maxWidth: '100%',
    overflowX: 'auto',
    paddingBottom: '.25rem',
    '&::-webkit-scrollbar': {
        height: '.25rem'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0.12)
    }
}));

const Tag = styled(Chip)(() => ({
    '& span': {
    }
}));

export {
    TagsWrapper,
    Tag
};
