import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { useResetPasswordMutation } from '../../api/LabtraceApi';

import GradientLogoImage from '../../assets/images/gradientLogo.svg';
import ResetPasswordImage from '../../assets/images/resetPassword.svg';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginBottom: '3rem'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const ResetPasswordWrapper = styled(Box)(() => ({
    position: 'absolute',
    left: '5.5rem',
    top: '9.5rem',
    width: '20.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    right: '2rem',
    bottom: '3rem',
    '& > img': {
        width: '85%'
    },
    [theme.breakpoints.up('lg')]: {
        right: '4.5rem',
        bottom: '6.5rem',
        '& > img': {
            width: '100%'
        }
    }
}));

const StyledVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const NewPassword = () => {
    const [values, setValues] = useState({
        password: {
            value: '',
            error: ''
        },
        passwordConfirm: {
            value: '',
            error: ''
        },
        showPassword: false
    });
    const history = useHistory();
    const { token } = useParams();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();

    const handleChange = (prop) => (event) => {
        const isPassField = prop === 'password';
        const isPassConfirmField = prop === 'passwordConfirm';
        const passValue = isPassField ? event.target.value : values.password.value;
        const passConfirmValue = isPassConfirmField ? event.target.value : values.passwordConfirm.value;
        const isConfirmPassValid = passValue === passConfirmValue;
        const confirmPasswordError = ((isPassField || isPassConfirmField) && !isConfirmPassValid) ? 'Passwords do not match.' : '';

        if (isPassField) {
            setValues({
                ...values,
                password: {
                    ...values.password,
                    value: event.target.value
                },
                passwordConfirm: {
                    ...values.passwordConfirm,
                    error: confirmPasswordError
                }
            });
        } else if (isPassConfirmField) {
            setValues({
                ...values,
                passwordConfirm: {
                    value: event.target.value,
                    error: confirmPasswordError
                }
            });
        }
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleResetPassword = async () => {
        if (!values.passwordConfirm.error) {
            try {
                await resetPassword({
                    token,
                    password: values.password.value
                }).unwrap();
                history.push('/congratulations');
            } catch (e) {
                alert('Could not reset password, please try again.');
            }
        }
    };

    return (
        <Paper elevation={0} sx={{ height: '100vh', position: 'relative' }}>
            <Box sx={{ position: 'absolute', left: '2rem', top: '1.5rem' }}>
                <img src={GradientLogoImage} alt="LabTrace" />
            </Box>
            <ResetPasswordWrapper
                component="form"
                noValidate
                autoComplete="off"
            >
                <StyledTitle variant="h5">Create a new password</StyledTitle>
                <Stack spacing={3} sx={{ width: '100%' }}>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel htmlFor="outlined-adornment-password">
                            <Typography variant="subtitle1">Password</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password.value}
                            onChange={handleChange('password')}
                            label="Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {
                                            values.password.value
                                            && (values.showPassword
                                                ? <StyledVisibilityOffIcon />
                                                : <StyledVisibilityIcon />)
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel htmlFor="outlined-adornment-passwordConfirm">
                            <Typography variant="subtitle1">Confirm password</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-passwordConfirm"
                            type="password"
                            value={values.passwordConfirm.value}
                            onChange={handleChange('passwordConfirm')}
                            label="Confirm password"
                        />
                        {
                            values.passwordConfirm.error
                            && (
                                <FormHelperText id="outlined-email-helper-text">
                                    <StyledTypography variant="caption">{values.passwordConfirm.error}</StyledTypography>
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                </Stack>
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disableElevation
                    disabled={
                        !values.password.value
                        || !!values.passwordConfirm.error
                        || isLoading
                    }
                    sx={{ mt: '3rem' }}
                    onClick={handleResetPassword}
                >
                    <Typography variant="button">Continue</Typography>
                </Button>
            </ResetPasswordWrapper>
            <ImageWrapper>
                <img src={ResetPasswordImage} alt="Almost done" />
            </ImageWrapper>
        </Paper>
    );
};

export default NewPassword;
