import { Modal } from '@mui/base/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Joi from 'joi';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRegisterMutation } from '../../api/LabtraceApi';
import ImageWrapper from '../../components/ImageWrapper/ImageWrapper';

import GradientLogoImage from '../../assets/images/gradientLogo.svg';
import LogoImage from '../../assets/images/logo.svg';
import RegisterImage from '../../assets/images/register.svg';

const SignUpWrapper = styled(Box)(({ theme }) => ({
    height: '100vh',
    padding: '1rem',
    [theme.breakpoints.up('lg')]: {
        padding: '1.5rem 2rem'
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const StyledTitlePrimary = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const StyledPasswordStrengthWrapper = styled(Paper)(({ theme }) => ({
    height: '.25rem',
    marginTop: '1rem',
    display: 'flex',
    background: theme.palette.primary.light
}));

const StyledPasswordBar = styled(Paper)(() => ({
    height: '.25rem',
    width: 'calc(100%/3)',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const StyledPasswordWeakBar = styled(StyledPasswordBar)(({ theme }) => ({
    background: theme.palette.error.main
}));

const StyledPasswordMediumBar = styled(StyledPasswordBar)(({ theme }) => ({
    background: theme.palette.warning.main
}));

const StyledPasswordStrongBar = styled(StyledPasswordBar)(({ theme }) => ({
    background: theme.palette.success.main
}));

const StyledVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const StyledSpan = styled(Typography)(() => ({
    color: '#A31988',
    textDecoration: 'underline',
    cursor: 'pointer'
}));

const StyledModalBox = styled(Box)(() => ({
    margin: '20px 70px',
}));

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    position: 'relative',
    width: 1200,
    height: '90vh',
    bgcolor: 'background.paper',
    p: 2,
    px: 4,
    pb: 3,
    overflowY: 'scroll',
    outline: 'none'
};

const SignUp = () => {
    const [values, setValues] = useState({
        name: {
            value: '',
            error: '',
            pattern: Joi.string().required().messages({
                'string.empty': 'Name is required.'
            })
        },
        surname: {
            value: '',
            error: '',
            pattern: Joi.string().required().messages({
                'string.empty': 'Surname is required.'
            })
        },
        email: {
            value: '',
            error: '',
            pattern: Joi.string().email({ tlds: { allow: false } }).required().messages({
                'string.empty': 'Email address is required.',
                'string.email': 'Email address is not valid.'
            })
        },
        organization: {
            value: '',
            error: ''
        },
        location: {
            value: '',
            error: ''
        },
        password: {
            value: '',
            error: '',
            pattern: Joi.string().min(8).required().messages({
                'string.empty': 'Password is required.',
                'string.min': 'The password should be at least 8 characters.'
            })
        },
        passwordConfirm: {
            value: '',
            error: ''
        },
        terms: {
            value: false
        },
        showPassword: false,
        passwordStrength: 0
    });
    const history = useHistory();
    const [registerUser, { isLoading }] = useRegisterMutation();

    const handleChange = (prop) => (event) => {
        const propError = values[prop].pattern ? values[prop].pattern.validate(event.target.value).error : null;
        const isPassField = prop === 'password';
        const isPassConfirmField = prop === 'passwordConfirm';
        const passValue = isPassField ? event.target.value : values.password.value;
        const passConfirmValue = isPassConfirmField ? event.target.value : values.passwordConfirm.value;
        const isConfirmPassValid = passValue === passConfirmValue;
        const confirmPasswordError = ((isPassField || isPassConfirmField) && !isConfirmPassValid) ? 'Passwords do not match.' : '';

        if (isPassField) {
            setValues({
                ...values,
                password: {
                    ...values.password,
                    value: event.target.value,
                    error: propError ? propError.details[0].message : ''
                },
                passwordConfirm: {
                    ...values.passwordConfirm,
                    error: confirmPasswordError
                }
            });
        } else if (isPassConfirmField) {
            setValues({
                ...values,
                passwordConfirm: {
                    value: event.target.value,
                    error: confirmPasswordError
                }
            });
        } else {
            setValues({
                ...values,
                [prop]: {
                    ...values[prop],
                    value: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
                    ...(event.target.type !== 'checkbox' && { error: propError ? propError.details[0].message : '' })
                }
            });
        }
    };

    const [showModal, setShowModal] = useState(false);
    const [handleCheckbox, setHandleCheckbox] = useState(true);
    const handleOpen = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setHandleCheckbox(false);
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkPasswordStrength = (event) => {
        const password = event.target.value;
        const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/g;
        const mediumRegex = /^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$/g;

        let passwordStrength = 1;

        if (mediumRegex.test(password)) {
            passwordStrength = 2;
        }
        if (strongRegex.test(password)) {
            passwordStrength = 3;
        }

        setValues({
            ...values,
            passwordStrength
        });
    };

    const areAllRequiredFieldsPopulated = () => {
        let allFieldsPopulated = true;
        const newValues = { ...values };

        Object.entries(values).forEach(([field, fieldValue]) => {
            let error = '';
            if (fieldValue.pattern && !fieldValue.pattern.name) {
                const patternError = fieldValue.pattern.validate(fieldValue.value).error;

                if (patternError) {
                    allFieldsPopulated = false;
                    error = patternError.details[0].message;
                }
            } else if (fieldValue.pattern && fieldValue.pattern.name) {
                if (
                    ((fieldValue.pattern.name === 'dateRange') && !fieldValue.value[fieldValue.pattern.index])
                    || ((fieldValue.pattern.name === 'atLeast') && fieldValue.options.length < fieldValue.pattern.quantity)
                    || ((fieldValue.pattern.name === 'required') && !fieldValue.value)
                    || ((fieldValue.pattern.name === 'depending') && values[fieldValue.pattern.field].value && !fieldValue.value)
                ) {
                    error = fieldValue.pattern.message;
                    allFieldsPopulated = false;
                }
            }
            newValues[field] = {
                ...values[field],
                error
            };
        });
        setValues(newValues);

        return allFieldsPopulated;
    };

    const register = async () => {
        if (!areAllRequiredFieldsPopulated()) return;
        const { name, surname, email, location, organization, terms, password, passwordConfirm } = values;

        if (terms.value && email.value && name.value && surname.value && password.value && !email.error && !passwordConfirm.error && !password.error && !name.error && !surname.error) {
            try {
                await registerUser({
                    name: name.value,
                    lastName: surname.value,
                    email: email.value,
                    location: location.value,
                    organisation: organization.value,
                    password: password.value
                }).unwrap();
                history.push('/sign-up-almost-done');
            } catch (e) {
                setValues({
                    ...values,
                    email: {
                        ...values.email,
                        error: e.data.error.responseMessage
                    }
                });
            }
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid item xs={6}>
                <SignUpWrapper
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <Box>
                        <img src={GradientLogoImage} alt="LabTrace" />
                    </Box>
                    <Paper elevation={0} sx={{ py: '4rem' }}>
                        <StyledTitlePrimary variant="h5" sx={{ mb: '3rem' }}>
                            Create an account
                        </StyledTitlePrimary>
                        <Box sx={{ marginBottom: '1.5rem' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-email">
                                    {
                                        values.email.error
                                            ? <StyledErrorText variant="subtitle1">Email</StyledErrorText>
                                            : <Typography variant="subtitle1">Email</Typography>
                                    }
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-email"
                                    type="email"
                                    value={values.email.value}
                                    onChange={handleChange('email')}
                                    label="Email"
                                    required
                                    error={Boolean(values.email.error)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                disabled
                                            >
                                                {values.email.error && <StyledErrorIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {
                                    values.email.error
                                    && (
                                        <FormHelperText id="outlined-email-helper-text">
                                            <StyledErrorText variant="caption">{values.email.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-name">
                                        <Typography variant="subtitle1">Name</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-name"
                                        type="text"
                                        value={values.name.value}
                                        required
                                        onChange={handleChange('name')}
                                        label="Name"
                                        error={Boolean(values.name.error)}
                                    />
                                    {
                                        values.name.error
                                        && (
                                            <FormHelperText id="outlined-email-helper-text">
                                                <StyledErrorText variant="caption">{values.name.error}</StyledErrorText>
                                            </FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-surname">
                                        <Typography variant="subtitle1">Surname</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-surname"
                                        type="text"
                                        value={values.surname.value}
                                        required
                                        onChange={handleChange('surname')}
                                        label="Surname"
                                        error={Boolean(values.surname.error)}
                                    />
                                    {
                                        values.surname.error
                                        && (
                                            <FormHelperText id="outlined-email-helper-text">
                                                <StyledErrorText variant="caption">{values.surname.error}</StyledErrorText>
                                            </FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-organization">
                                        <Typography variant="subtitle1">Organization</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-organization"
                                        type="text"
                                        value={values.organization.value}
                                        onChange={handleChange('organization')}
                                        label="Organization"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-location">
                                        <Typography variant="subtitle1">Location</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-location"
                                        type="text"
                                        value={values.location.value}
                                        onChange={handleChange('location')}
                                        label="Location"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        <Typography variant="subtitle1">Password</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password.value}
                                        onChange={handleChange('password')}
                                        onKeyUp={checkPasswordStrength}
                                        label="Password"
                                        error={Boolean(values.password.error)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {
                                                        values.password.value
                                                        && (values.showPassword
                                                            ? <StyledVisibilityOffIcon />
                                                            : <StyledVisibilityIcon />)
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {
                                        values.password.error
                                        && (
                                            <FormHelperText id="outlined-email-helper-text">
                                                <StyledTypography variant="caption">{values.password.error}</StyledTypography>
                                            </FormHelperText>
                                        )
                                    }
                                    {
                                        (values.password.value && !values.passwordConfirm.value)
                                        && (
                                            <StyledPasswordStrengthWrapper elevation={0}>
                                                {
                                                    values.passwordStrength > 0
                                                    && (
                                                        <StyledPasswordWeakBar elevation={0}>
                                                            {
                                                                values.passwordStrength === 1
                                                                && <Typography variant="caption" sx={{ mt: '.5rem' }}>Weak</Typography>
                                                            }
                                                        </StyledPasswordWeakBar>
                                                    )
                                                }
                                                {
                                                    values.passwordStrength > 1
                                                    && (
                                                        <StyledPasswordMediumBar elevation={0}>
                                                            {
                                                                values.passwordStrength === 2
                                                                && <Typography variant="caption" sx={{ mt: '.5rem' }}>Medium</Typography>
                                                            }
                                                        </StyledPasswordMediumBar>
                                                    )
                                                }
                                                {
                                                    values.passwordStrength > 2
                                                    && (
                                                        <StyledPasswordStrongBar elevation={0}>
                                                            {
                                                                values.passwordStrength === 3
                                                                && <Typography variant="caption" sx={{ mt: '.5rem' }}>Strong</Typography>
                                                            }
                                                        </StyledPasswordStrongBar>
                                                    )
                                                }
                                            </StyledPasswordStrengthWrapper>
                                        )
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-passwordConfirm">
                                        <Typography variant="subtitle1">Confirm password</Typography>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-passwordConfirm"
                                        type="password"
                                        value={values.passwordConfirm.value}
                                        onChange={handleChange('passwordConfirm')}
                                        label="Confirm password"
                                    />
                                    {
                                        values.passwordConfirm.error
                                        && (
                                            <FormHelperText id="outlined-email-helper-text">
                                                <StyledTypography variant="caption">{values.passwordConfirm.error}</StyledTypography>
                                            </FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox disabled={handleCheckbox} checked={values.terms.value} />}
                                    onChange={handleChange('terms')}
                                    label={
                                        <Typography variant="body2">
                                            I have read and agree to
                                            <StyledSpan variant="span" onClick={handleOpen}> the Terms and Conditions</StyledSpan>
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <StyledModal
                                aria-labelledby="unstyled-modal-title"
                                aria-describedby="unstyled-modal-description"
                                open={showModal}
                                onClose={handleClose}
                                BackdropComponent={Backdrop}
                            >
                                <Box sx={style}>
                                    <Button style={{ position: 'absolute', right: '20px', top: '20px' }} onClick={handleClose}><CloseIcon /></Button>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '50px 70px 20px 70px' }}>
                                        <Typography>Last updated: April, 2022</Typography>
                                        <img src={LogoImage} style={{ filter: 'invert(100%) sepia(10%) saturate(100%) hue-rotate(6deg) brightness(1%) contrast(500%)' }} alt="LabTrace" />
                                    </Box>
                                    <Box>
                                        <Typography align="center" variant="h4">Terms and Conditions</Typography>
                                    </Box>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Welcome to LabTrace</Typography>
                                        <Typography>LabTrace provides services that enable people to upload and notarize scientific research records securely and in accordance with GDPR regulations. These Terms and Conditions govern your use of our service. As used in these Terms and Conditions, &quot;LabTrace&quot;, &quot;We&quot;, &quot;our service&quot; or &quot;the service&quot;, &quot;us&quot;, &quot;system&quot; refers to Lab Trace Ltd or the service provided by Lab Trace Ltd. Please read these terms and conditions carefully before using our service.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox style={{ marginBottom: '30px' }}>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Privacy</Typography>
                                        <Typography> Please review our Privacy Policy, which also governs your visit to the LabTrace Site, to understand our practices. This policy explains how we collect and use your personal data to provide the service. If these Terms and Conditions are not accepted in full, you do not have permission to access the contents of the Site and therefore should cease using the Site and services immediately.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Your Responsibility</Typography>
                                        <Typography variant="h6" style={{ fontWeight: '600' }}>Password and Account Access:</Typography>
                                        <ul>
                                            <li>To  use  the  LabTrace  service,  you  need  to  register  with  the  system.  You  must  keep  this registration information private and don’t share it with others. </li>
                                            <li>To  maintain  control  over  the  account  and  to  prevent  anyone  from  accessing  the  account you should keep your account credentials safe and not reveal the details to anyone. </li>
                                        </ul>

                                        <Typography variant="h6" style={{ fontWeight: '600' }}>Credits refill:</Typography>
                                        <Typography>Once registered, users will be able to refill their credit account in order to carry out operations in LabTrace. The user will pay for the requested credits through a bank transfer.</Typography>

                                        <Typography variant="h6" style={{ fontWeight: '600' }}>Data to be Uploaded:</Typography>
                                        <ul>
                                            <li>Using LabTrace you can upload primary or secondary research records. These may include any digital material that is used in a scientific project such as project plans, research notes, radiology  images,  laboratory  and  test  results  but  also  software,  software  scripts  or  any digital material used as primary data or that was necessary to generate secondary data.</li>
                                            <li>
                                                <Typography style={{ fontWeight: '600' }}>LabTrace does allow the storage of personal data and the necessary controls and data privacy.</Typography>
                                                Storage of personal data is allowed only via Private folders and access should be limited only to authorised users. However, if you share any content with personal details in LabTrace,  you  will  be  the  sole  responsible  for  it.  We  will  not  take  the  accountability  of uploaded data with personally identifiable information (PII).
                                            </li>
                                            <li>Please do not copy, upload, download or share content unless you have the right to do so.</li>
                                            <li>Please remember that you cannot use this system to violate the law in any way, including storing, publishing or sharing material that’s illegal, fraudulent, defamatory, or misleading. We reserve the right to block the account in case any activity violates these provisions.</li>
                                            <li>
                                                You  are  also  not  allowed  to  upload  material  that  violates  authorship  and  intellectual property rights of others or upload data that have been sourced from participants without their  informed  consent  or,  more  generally,   in  violation  of  the  UK  Code  of  Practice  for Research (
                                                <Link style={{ color: 'black', textDecoration: 'none', fontWeight: '600', fontSize: '1rem' }} variant="body2" target="_blank" href="https://ukrio.org/publications/code-of-practice-for-research/">https://ukrio.org/publications/code-of-practice-for-research/</Link>
                                                ).
                                            </li>
                                        </ul>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Electronic Communications</Typography>
                                        <Typography>We will send you information relating to your account (e.g. confirmation messages, changes in password, transferred files, notices) in electronic form only, for example via emails to your email address provided during registration. You consent to receive communications from us electronically. Lab Trace does not ask for your personal data via email or via social networks or links.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Copyright</Typography>
                                        <Typography>All content included on the LabTrace Site, such as text, graphics, logos, button icons, images, page layout and underlying code are the property of Lab Trace Ltd. By continuing to use the site you acknowledge that such contents are protected by copyright, trademarks, database rights and other intellectual property rights. You can only use our copyrights or any of the content from the website with our prior written permission.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Copyright</Typography>
                                        <Typography> All content included on the LabTrace Site, such as text, graphics, logos, button icons, images, page layout and underlying code are the property of Lab Trace Ltd. By continuing to use the site you acknowledge that such contents are protected by copyright, trademarks, database rights and other intellectual property rights. You can only use our copyrights or any of the content from the website with our prior written permission. </Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Disclaimer of Warranties and Limitation of Liability</Typography>
                                        <Typography style={{ marginBottom: '15px' }}> The service is provided to you &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, LabTrace and its affiliates, distributors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, LabTrace provides no warranty or undertaking, and makes no representation of any kind that it will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</Typography>
                                        <Typography>LabTrace does not warrant that the site, information, contents, services, its servers or email sent from LabTrace are free of viruses or other harmful components. LabTrace will not be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the service, third-party software and/or third-party hardware used with the service, or otherwise in connection with any provision of this Terms), even if the service or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Governing Law</Typography>
                                        <Typography> These Terms of Use shall be governed by and construed in accordance with the laws of the United Kingdom. Your use of the service may also be subject to other local, state, national, or international laws.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Changes to Terms and Conditions</Typography>
                                        <Typography> LabTrace may, from time to time, change these Terms and Conditions. We will notify you at least 30 days before such changes apply to you.</Typography>
                                    </StyledModalBox>
                                    <StyledModalBox>
                                        <Typography variant="h6" style={{ marginBottom: '15px' }}>Contact Us</Typography>
                                        <Typography style={{ marginBottom: '15px' }}>Lab Trace Ltd is a private limited company registered in England and Wales (Company number 13361831).</Typography>
                                        <Typography style={{ marginBottom: '200px' }}>
                                            If you have any questions about these Terms and Conditions, you can contact us by email:
                                            <Link style={{ color: 'black', textDecoration: 'none', fontWeight: '600', fontSize: '1rem' }} variant="body2" href="mailto:info@labtrace.io "> info@labtrace.io</Link>
                                        </Typography>
                                    </StyledModalBox>
                                </Box>

                            </StyledModal>
                            <Grid item xs={3}>
                                <Button
                                    variant="text"
                                    size="large"
                                    fullWidth
                                    sx={{ mb: '1rem' }}
                                    onClick={() => history.push('/sign-in')}
                                >
                                    <Typography variant="button">Cancel</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    disableElevation
                                    disabled={
                                        !values.terms.value
                                        || !values.email.value
                                        || !!values.email.error
                                        || !!values.passwordConfirm.error
                                        || isLoading
                                    }
                                    onClick={register}
                                >
                                    <Typography variant="button">Create</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </SignUpWrapper>
            </Grid>
            <Grid item xs={6}>
                <ImageWrapper sx={{ '& > img': { width: '90%' } }}>
                    <img src={RegisterImage} alt="Register illustration" />
                </ImageWrapper>
            </Grid>
        </Grid>
    );
};

export default SignUp;
