import moment from 'moment';

export const transformUsersResponse = (records) => {
    const tableData = [];
    records.forEach((record) => {
        const {
            createdOn, email, id, isActive, lastName, location, name, organisation, userStatus, creditBalance, avatar
        } = record;
        const row = {
            avatar,
            name,
            lastName,
            fullName: `${name} ${lastName}`,
            userOrganization: organisation,
            location,
            createdOn: moment(createdOn.date).format('DD/MM/YYYY'),
            creditBalance,
            userStatus,
            email,
            id,
            isActive
        };
        tableData.push(row);
    });

    return {
        tableData
    };
};
