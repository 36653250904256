import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useGetProjectsQuery } from '../../../../api/LabtraceApi';
import { FiltersAdminContext } from '../../../../hooks/FilterAdminContext';

const AddFilterFormWrapper = styled(Box)(({ theme }) => ({
    padding: '2rem 0',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`
}));

const AddAdminFilterForm = () => {
    const [values, setValues] = useState({
        fullName: {
            value: ''
        }
    });
    const {
        customFilters, editableFilter, addFilter, setActiveFilter, updateFilter, removeFilter
    } = useContext(FiltersAdminContext);
    const { data } = useGetProjectsQuery();

    const isApplyButtonDisabled = () => {
        let isDisabled = true;
        if (
            Object.values(values).some((v) => (Array.isArray(v.value) ? Boolean(v.value[0]) : Boolean(v.value)))
        ) {
            isDisabled = false;
        }

        return isDisabled;
    };

    const handleResetAll = () => {
        setValues({
            fullName: {
                value: ''
            }
        });
    };

    useEffect(() => {
        if (data) {
            setValues({
                fullName: {
                    value: editableFilter ? editableFilter.fullName : ''
                },

            });
        }
    }, [data, editableFilter]);

    const handleInputChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value
            }
        });
    };

    const handleUpsert = (type) => {
        const filter = {
            fullName: values.fullName.value,
        };
        if (type === 'add') {
            const id = String(customFilters.length + 1);
            filter.id = id;
            addFilter(filter);
            setActiveFilter(id);
        } else if (type === 'update') {
            filter.id = editableFilter.id;
            updateFilter(filter);
        }

        handleResetAll();
    };

    return (
        <AddFilterFormWrapper>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-fullName">
                            <Typography variant="subtitle1">Full Name</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-fullName"
                            type="text"
                            value={values.fullName.value}
                            onChange={handleInputChange('fullName')}
                            label="FullName"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Button
                            variant="text"
                            size="large"
                            fullWidth
                            sx={{ mr: '1rem' }}
                            onClick={handleResetAll}
                        >
                            <Typography variant="button">Reset all</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            disableElevation
                            disabled={isApplyButtonDisabled()}
                            onClick={() => handleUpsert(editableFilter ? 'update' : 'add')}
                        >
                            <Typography variant="button">
                                { editableFilter ? 'Update' : 'Apply' }
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                {
                    editableFilter
                    && (
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="text"
                                size="large"
                                startIcon={<DeleteIcon />}
                                onClick={() => removeFilter(editableFilter.id)}
                            >
                                <Typography variant="button">Delete this filter</Typography>
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
        </AddFilterFormWrapper>
    );
};

export default AddAdminFilterForm;
