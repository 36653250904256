import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LabTrace from './LabTrace';
import reportWebVitals from './reportWebVitals';
import { Store } from './store/Store';
import theme from './theme/theme';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={Store}>
                <LabTrace />
            </Provider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

reportWebVitals();
