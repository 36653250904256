import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRevokeSuspensionUserAccountMutation } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';

const StyledActionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '3rem'
}));

const AdminRevokeSuspensionForm = ({ userEmail }) => {
    const { openDialog, closeDialog } = useContext(DialogContext);
    const user = useSelector((state) => state.user);
    const [revokeSuspensionUser, { isLoading: revokeSuspensioUserLoading }] = useRevokeSuspensionUserAccountMutation();

    const revokeSuspendedAccount = async () => {
        try {
            await revokeSuspensionUser({ adminId: user.id, userEmail, }).unwrap();
            openDialog({
                isOpen: true,
                status: 'success',
                title: 'Done',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        The suspension has been successfully revoked.
                    </Typography>
                )
            });
        } catch (error) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'Profile not suspended',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Unexpected error. Please try again later.
                    </Typography>
                )
            });
        }
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none',
        }
    }));
    const classes = useStyles();

    return (
        revokeSuspensioUserLoading
            ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    component="form"
                    sx={{ mt: '2rem' }}
                >
                    <Typography variant="body2">Do you really want to revoke the suspension for this profile? The user will be able to access the platform again and will receive a notification via email.</Typography>

                    <StyledActionWrapper>
                        <Stack spacing={2} direction="row" sx={{ width: '50%' }}>
                            <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                                <Typography variant="button">Cancel</Typography>
                            </Button>
                            <Button variant="contained" size="large" fullWidth onClick={revokeSuspendedAccount}>
                                <Typography variant="button">Revoke</Typography>
                            </Button>
                        </Stack>
                    </StyledActionWrapper>
                </Box>
            )

    );
};

AdminRevokeSuspensionForm.propTypes = {
    userEmail: PropTypes.string.isRequired
};

export default AdminRevokeSuspensionForm;
