import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
    useGetFilesQuery,
    useGetPrivateFilesQuery,
    useGetProjectQuery
} from '../../api/LabtraceApi';
import DashboardFilters from '../../components/DashboardFilters/DashboardFilters';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import FileOperationsMenu from '../../components/FilesOperations/FilesOperationsMenu';
import FilesGrid from '../../components/GridView/GridView';
import FilesTable from '../../components/TableView/TableView';
import { FiltersContext } from '../../hooks/FiltersContext';

export const FileUploadProgressContext = createContext(null);
export const FileVerificationProgressContext = createContext(null);

const ProjectFiles = () => {
    const headCells = [
        { id: 'name', label: 'File name' },
        { id: 'type', label: 'File type' },
        { id: 'owner', label: 'Owner' },
        { id: 'uploaded', label: 'Uploaded' },
        { id: 'status' },
        { id: 'options' }
    ];
    const [gridItems, setGridItems] = useState([]);
    const [tableItems, setTableItems] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const { view, activeFilter, setActiveFilter } = useContext(FiltersContext);
    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);
    const { path } = useRouteMatch();
    const isPrivateFolder = path.search(/private-folder/) > 0;
    const [isUploading, setIsUploading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    let files;
    let isLoading;
    let refetchFiles;
    if (isPrivateFolder) {
        const queryResponse = useGetPrivateFilesQuery(projectId);
        files = queryResponse.data;
        refetchFiles = queryResponse.refetch;
        isLoading = queryResponse.isLoading;
    } else {
        const queryResponse = useGetFilesQuery(projectId);
        files = queryResponse.data;
        refetchFiles = queryResponse.refetch;
        isLoading = queryResponse.isLoading;
    }

    const handleCloseSnackbar = () => {
        setIsDownloading(false);
    };

    const action = (
        <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            HIDE
        </Button>
    );

    useEffect(() => {
        if (files) {
            if (activeFilter === 'primary') {
                const primaryGridFiles = files.filesGridData.filter((file) => !file.isSecondary);
                const primaryTableFiles = files.filesTableData.filter((file) => !file.isSecondary);

                setGridItems(primaryGridFiles);
                setTableItems(primaryTableFiles);
            } else if (activeFilter === 'secondary') {
                const primaryGridFiles = files.filesGridData.filter((file) => file.isSecondary);
                const primaryTableFiles = files.filesTableData.filter((file) => file.isSecondary);

                setGridItems(primaryGridFiles);
                setTableItems(primaryTableFiles);
            } else if (activeFilter === 'deleted') {
                const primaryGridFiles = files.filesGridData.filter((file) => file.status === 'Deleted');
                const primaryTableFiles = files.filesTableData.filter((file) => file.status === 'Deleted');

                setGridItems(primaryGridFiles);
                setTableItems(primaryTableFiles);
            } else if (activeFilter === 'all') {
                setGridItems(files.filesGridData);
                setTableItems(files.filesTableData);
            }
        }
    }, [files, activeFilter]);

    useEffect(() => {
        try {
            if (files && files.data) {
                refetchFiles();
            }
        } catch (e) {
            refetchFiles();
        }
    }, [files, isUploading]);

    const getTableItems = () => tableItems.map(({ isSecondary, ...item }) => ({
        ...item,
        options: {
            value: (
                project && item
                    ? <FileOperationsMenu project={project} file={item} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
                    : ''
            )
        }
    }));

    useEffect(() => {
        setActiveFilter('all');
    }, []);

    const useStyles = makeStyles(() => ({
        snackbarWidth: {
            width: '85%',
        }
    }));
    const classes = useStyles();

    const handleCloseUploadSnackbar = () => {
        setIsUploading(false);
    };

    const actionUpload = (
        <Button color="secondary" size="small" onClick={handleCloseUploadSnackbar}>
            HIDE
        </Button>
    );

    return (
        <FileUploadProgressContext.Provider value={{ setIsUploading }}>
            <DashboardHeader
                breadcrumbs={
                    [
                        { id: '1', name: 'Projects', href: '/dashboard/projects' },
                        { id: '2', name: project ? project.name : '', href: `/dashboard/projects/${projectId}` },
                        { id: '2', name: `${isPrivateFolder ? 'Private' : 'Public'} folder` }
                    ]
                }
                singleProject
            />
            {/* <Button onClick={()=>refetchFiles()}>refetch</Button> */}
            <DashboardFilters files numberOfRecords={files ? files.filesGridData.length : 0} />
            {
                isLoading
                    ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 350px)' }}>
                            <CircularProgress />
                        </Box>
                    )
                    : view === 'grid'
                        ? <FilesGrid items={gridItems} type="files" isPrivate={isPrivateFolder} />
                        : (
                            <>
                                <FilesTable rows={getTableItems()} headCells={headCells} />
                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={6000}
                                    message={
                                        <div style={{ width: 500 }}>
                                            <Grid container spacing={2} sx={{ maxWidth: 500 }}>
                                                <Grid item xs={4}>
                                                    Download in progress
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Box sx={{ width: '100%' }} mt={1}>
                                                        <LinearProgress />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    classes={
                                        { root: classes.snackbarWidth }
                                    }
                                    action={action}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                />
                            </>
                        )
            }
            <Snackbar
                open={isUploading}
                autoHideDuration={6000}
                message={
                    <div style={{ width: 500 }}>
                        <Grid container spacing={2} sx={{ maxWidth: 500 }}>
                            <Grid item xs={4}>
                                Upload in progress
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ width: '100%' }} mt={1}>
                                    <LinearProgress />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                }
                action={actionUpload}
                classes={
                    { root: classes.snackbarWidth }
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </FileUploadProgressContext.Provider>
    );
};

export default ProjectFiles;
