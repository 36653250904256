import AddIcon from '@mui/icons-material/Add';
import AppsIcon from '@mui/icons-material/Apps';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import momentJs from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useGetProjectQuery } from '../../api/LabtraceApi';
import { DialogContext } from '../../hooks/DialogContext';
import { FiltersContext } from '../../hooks/FiltersContext';
import { FileUploadProgressContext } from '../../scenes/ProjectFiles/ProjectFiles';
import Dropzone from '../Dropzone/Dropzone';
import UploadForm from '../Dropzone/UploadForm/UploadForm';
import { Tag, TagsWrapper } from '../Tags/Tags';
import AddFilterForm from './components/AddFilterForm/AddFilterForm';

const FilterButton = ({ startIcon, endIcon, activeFilter, filterId, action, name, doubleClickAction }) => (
    <Button
        variant="outlined"
        size="large"
        disableElevation
        startIcon={startIcon && startIcon}
        endIcon={endIcon && endIcon}
        sx={{
            maxWidth: '18rem',
            ...(activeFilter === filterId && { backgroundColor: 'rgba(163, 25, 136, 0.12)' })
        }}
        onClick={action}
        onDoubleClick={doubleClickAction}
    >
        <Tooltip title={name}>
            <Typography variant="button" noWrap>{name}</Typography>
        </Tooltip>
    </Button>
);

FilterButton.defaultProps = {
    startIcon: null,
    endIcon: null,
    doubleClickAction: null
};

FilterButton.propTypes = {
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    activeFilter: PropTypes.string.isRequired,
    filterId: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    doubleClickAction: PropTypes.func,
    name: PropTypes.string.isRequired
};

const getFilterName = (filter) => {
    const filterCopy = { ...filter };

    if (filterCopy.projectDuration[0] || filterCopy.projectDuration[1]) {
        const durationFrom = filterCopy.projectDuration[0]
            ? momentJs(filterCopy.projectDuration[0]).format('DD.MM.YYYY')
            : null;
        const durationTo = filterCopy.projectDuration[1]
            ? momentJs(filterCopy.projectDuration[1]).format('DD.MM.YYYY')
            : null;

        filterCopy.projectDuration = `${durationFrom || ''} - ${durationTo || ''}`;
    }
    delete filterCopy.id;

    return Object.values(filterCopy)
        .map((value) => {
            let propertyValue = null;
            if (value) {
                propertyValue = (typeof value === 'object') ? value.label : value;
            }

            return propertyValue;
        })
        .filter((value) => value)
        .join('; ');
};

const DashboardFilters = ({ projects, singleProject, files, numberOfRecords }) => {
    const {
        view, setView, activeFilter, setActiveFilter, editableFilter, setEditableFilter, customFilters
    } = useContext(FiltersContext);
    const { openDialog } = useContext(DialogContext);
    const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
    const { projectId } = useParams();
    const { data: projectData } = useGetProjectQuery(projectId, { skip: !projectId });
    const user = useSelector((state) => state.user);
    const [file, setFile] = useState(null);
    const { path } = useRouteMatch();
    const isPrivateFolder = path.search(/private-folder/) > 0;

    const uploadClose = (e) => {
        setFile(e.target.files[0]);
        setIsDropzoneOpen(false);
    };

    const progressCallback = useContext(FileUploadProgressContext);

    useEffect(() => {
        if (file) {
            openDialog({
                isOpen: true,
                status: null,
                title: 'File uploading form',
                content: <UploadForm fileData={file} projectId={projectId} isPrivateFolder={isPrivateFolder} progressCallback={progressCallback} />
            });
            setFile(null);
        }
    }, [file]);
    const closeDropzone = () => setIsDropzoneOpen(!isDropzoneOpen);

    return (
        <Grid container sx={{ py: '2rem' }}>
            <Grid item xs={10}>
                {
                    projects
                    && (
                        <Stack spacing={2} direction="row">
                            <FilterButton
                                filterId="all"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('all')}
                                name={`All projects (${numberOfRecords})`}
                            />
                            <FilterButton
                                filterId="my"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('my')}
                                name="Only my projects"
                            />
                            {
                                customFilters.map((filter) => (
                                    <FilterButton
                                        key={filter.id}
                                        filterId={filter.id}
                                        activeFilter={activeFilter}
                                        action={() => setActiveFilter(filter.id)}
                                        doubleClickAction={() => setEditableFilter(filter)}
                                        endIcon={(editableFilter && filter.id === editableFilter.id) && <EditIcon />}
                                        name={getFilterName(filter)}
                                    />
                                ))
                            }
                            <Box>
                                <FilterButton
                                    filterId="add"
                                    activeFilter={activeFilter}
                                    action={() => setActiveFilter('add')}
                                    startIcon={<AddIcon />}
                                    name="Add filter"
                                />
                            </Box>
                        </Stack>
                    )
                }
                {
                    singleProject && projectData
                    && (
                        <TagsWrapper direction="row" spacing={1}>
                            {
                                projectData.tags.map((tag, index) => <Tag key={`${tag}-${index}`} label={tag} />)
                            }
                        </TagsWrapper>
                    )
                }
                {
                    files
                    && (
                        <Stack spacing={2} direction="row">
                            <FilterButton
                                filterId="all"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('all')}
                                name={`All files (${numberOfRecords})`}
                            />
                            <FilterButton
                                filterId="primary"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('primary')}
                                name="Primary data"
                            />
                            <FilterButton
                                filterId="secondary"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('secondary')}
                                name="Secondary data"
                            />
                            <FilterButton
                                filterId="deleted"
                                activeFilter={activeFilter}
                                action={() => setActiveFilter('deleted')}
                                name="Deleted data"
                            />
                        </Stack>
                    )
                }
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Stack spacing={2} direction="row">
                    {
                        files
                        && (
                            <IconButton
                                sx={{ color: 'rgba(0, 0, 0, 0.38)', ...(isDropzoneOpen && { color: 'common.black' }) }}
                                onClick={() => setIsDropzoneOpen(true)}
                                disabled={projectData
                                    && user.id !== projectData.leaderId
                                    && !projectData.members.records.some((record) => record.id === user.id)}
                                component="label"
                            >
                                <FileUploadIcon />
                                <input type="file" value="" onChange={(e) => uploadClose(e)} hidden />
                            </IconButton>
                        )
                    }
                    <IconButton
                        onClick={() => setView('grid')}
                        sx={{ color: 'rgba(0, 0, 0, 0.38)', ...(view === 'grid' && { color: 'common.black' }) }}
                    >
                        <AppsIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => setView('table')}
                        sx={{ color: 'rgba(0, 0, 0, 0.38)', ...(view === 'table' && { color: 'common.black' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Stack>
            </Grid>
            {
                (activeFilter === 'add' || editableFilter)
                && (
                    <Grid item xs={12}>
                        <AddFilterForm />
                    </Grid>
                )
            }
            {
                isDropzoneOpen && <Dropzone onClose={closeDropzone} isOpen={isDropzoneOpen} />
            }
        </Grid>
    );
};

DashboardFilters.defaultProps = {
    projects: false,
    singleProject: false,
    files: false,
    numberOfRecords: 0
};

DashboardFilters.propTypes = {
    projects: PropTypes.bool,
    singleProject: PropTypes.bool,
    files: PropTypes.bool,
    numberOfRecords: PropTypes.number
};

export default DashboardFilters;
