import React from 'react';
import Typography from '@mui/material/Typography';
import Layout from './components/Layout/Layout';

const AlmostDoneResetPassword = () => (
    <Layout
        subtitle={(
            <>
                <Typography variant="h5">Please check your email to reset</Typography>
                <Typography variant="h5"> your password.</Typography>
            </>
        )}
    />
);

export default AlmostDoneResetPassword;
