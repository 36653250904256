import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joi from 'joi';
import PropTypes from 'prop-types';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, alpha } from '@mui/material/styles';
import { DialogContext } from '../../../../../../../../hooks/DialogContext';
import {
    useUpdateUserMutation,
    useGetAvatarQuery,
    useAddAvatarMutation
} from '../../../../../../../../api/LabtraceApi';
import { setUser } from '../../../../../../../../slices/User';

const DrawerContentWrapper = styled(Box)(() => ({
    padding: '1.5rem 1rem',
    height: '100vh',
    position: 'relative'
}));

const ProfileImageWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 0',
    width: '320px'
}));

const FormWrapper = styled(Stack)(({ theme }) => ({
    maxHeight: 'calc(100vh - 8.5rem - 14.5rem)',
    overflowY: 'auto',
    padding: '1rem',
    margin: '0 -1rem',
    '&::-webkit-scrollbar': {
        width: '.25rem'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0.12)
    }
}));

const ButtonsWrapper = styled(Stack)(() => ({
    position: 'absolute',
    bottom: '1.5rem',
    left: '1rem',
    right: '1rem',
    marginTop: '1.5rem'
}));

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    alignItems: 'center',
    position: 'relative',
    '& > input[type="file"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0
    }
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const UserProfileDrawer = ({ isOpen, anchor, onClose }) => {
    const [values, setValues] = useState({
        name: {
            value: '',
            error: '',
            pattern: Joi.string().required().messages({
                'string.empty': 'Name is required.'
            })
        },
        surname: {
            value: '',
            error: '',
            pattern: Joi.string().required().messages({
                'string.empty': 'Surname is required.'
            })
        },
        email: {
            value: ''
        },
        password: {
            value: 'Password',
            savedValue: 'Password'
        },
        passwordConfirm: {
            value: ''
        },
        organization: {
            value: 'Organization'
        },
        location: {
            value: 'Location'
        },
        showPassword: false
    });
    const [avatarError, setAvatarError] = useState(null);
    const { openDialog } = useContext(DialogContext);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const { data: avatarData } = useGetAvatarQuery(user.id, { skip: !user.id });
    const [addAvatar, { isLoading: addAvatarLoading }] = useAddAvatarMutation();

    useEffect(() => {
        setValues({
            ...values,
            name: { ...values.name, value: user.name },
            surname: { ...values.surname, value: user.lastName },
            email: { value: user.email },
            organization: { value: user.organization || '' },
            location: { value: user.location || '' }
        });
    }, [user]);

    useEffect(() => {
        setValues({
            ...values,
            name: { ...values.name, value: user.name, error: '' },
            surname: { ...values.surname, value: user.lastName, error: '' },
            email: { value: user.email },
            organization: { value: user.organization || '' },
            location: { value: user.location || '' }
        });
    }, [onClose]);

    const handleChange = (prop) => (event) => {
        const propError = values[prop].pattern ? values[prop].pattern.validate(event.target.value).error : null;
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value,
                error: propError ? propError.details[0].message : ''
            }
        });
    };

    const areAllRequiredFieldsPopulated = () => {
        let allFieldsPopulated = true;
        const newValues = { ...values };

        Object.entries(values).forEach(([field, fieldValue]) => {
            let error = '';
            if (fieldValue.pattern && !fieldValue.pattern.name) {
                const patternError = fieldValue.pattern.validate(fieldValue.value).error;

                if (patternError) {
                    allFieldsPopulated = false;
                    error = patternError.details[0].message;
                }
            } else if (fieldValue.pattern && fieldValue.pattern.name) {
                if (
                    ((fieldValue.pattern.name === 'dateRange') && !fieldValue.value[fieldValue.pattern.index])
                    || ((fieldValue.pattern.name === 'atLeast') && fieldValue.options.length < fieldValue.pattern.quantity)
                    || ((fieldValue.pattern.name === 'required') && !fieldValue.value)
                    || ((fieldValue.pattern.name === 'depending') && values[fieldValue.pattern.field].value && !fieldValue.value)
                ) {
                    error = fieldValue.pattern.message;
                    allFieldsPopulated = false;
                }
            }
            newValues[field] = {
                ...values[field],
                error
            };
        });
        setValues(newValues);

        return allFieldsPopulated;
    };

    const handleUserUpdate = async () => {
        if (!areAllRequiredFieldsPopulated()) return;
        try {
            await updateUser({
                userId: user.id,
                name: values.name.value,
                lastName: values.surname.value,
                organisation: values.organization.value,
                location: values.location.value
            }).unwrap();

            dispatch(setUser({
                name: values.name.value,
                lastName: values.surname.value,
                organization: values.organization.value,
                location: values.location.value
            }));
            onClose();
            openDialog({
                isOpen: true,
                status: 'success',
                title: 'Done',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Your account changes have been successfully saved.
                    </Typography>
                )
            });
        } catch (error) {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'An error occured',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        Could not update the user data, please try again.
                    </Typography>
                )
            });
        }
    };

    const handleAvatarUpdate = async (event) => {
        const allowedTypes = ['image/jpeg', 'image/png'];
        const maxImageSize = 1000000;
        const file = event.currentTarget.files[0];

        if (!allowedTypes.includes(file.type)) {
            setAvatarError('File type is not allowed.');
        } else if (maxImageSize < file.size) {
            setAvatarError('Max allowed image size is 1MB.');
        } else {
            const formData = new FormData();

            setAvatarError(null);
            formData.append('avatar', file);

            try {
                await addAvatar({ userId: user.id, payload: formData }).unwrap();
            } catch (error) {
                openDialog({
                    isOpen: true,
                    status: 'error',
                    title: 'An error occured',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            Could not upload avatar, please try again.
                        </Typography>
                    )
                });
            }
        }
    };

    return (
        <MuiDrawer open={isOpen} anchor={anchor} onClose={onClose}>
            <DrawerContentWrapper
                component="form"
                noValidate
                autoComplete="off"
            >
                <StyledButton variant="text" startIcon={<ChevronLeftIcon />} onClick={onClose}>
                    <Typography variant="button">Back</Typography>
                </StyledButton>
                <ProfileImageWrapper>
                    <Avatar
                        sx={{ width: '5rem', height: '5rem' }}
                        alt={`${user.name} ${user.lastName}`}
                        src={avatarData ? avatarData.avatar : ''}
                    />
                    <StyledButton variant="text" disabled={addAvatarLoading}>
                        <Typography variant="button">Edit</Typography>
                        <input type="file" value="" onChange={handleAvatarUpdate} />
                    </StyledButton>
                    {
                        avatarError && (
                            <Box sx={{ mt: '.5rem' }}>
                                <StyledErrorText variant="caption" align="center">{avatarError}</StyledErrorText>
                            </Box>
                        )
                    }
                </ProfileImageWrapper>
                <FormWrapper spacing={3} id="drawer-form-wrapper">
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-name">
                            <Typography variant="subtitle1">Name</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-name"
                            type="text"
                            value={values.name.value}
                            required
                            onChange={handleChange('name')}
                            label="Name"
                            error={Boolean(values.name.error)}
                        />
                        {
                            values.name.error
                            && (
                                <FormHelperText id="outlined-email-helper-text">
                                    <StyledErrorText variant="caption">{values.name.error}</StyledErrorText>
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-surname">
                            <Typography variant="subtitle1">Surname</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-surname"
                            type="text"
                            value={values.surname.value}
                            required
                            onChange={handleChange('surname')}
                            label="Surname"
                            error={Boolean(values.surname.error)}
                        />
                        {
                            values.surname.error
                            && (
                                <FormHelperText id="outlined-email-helper-text">
                                    <StyledErrorText variant="caption">{values.surname.error}</StyledErrorText>
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-email">
                            <Typography variant="subtitle1">Email</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-email"
                            type="text"
                            defaultValue={values.email.value}
                            label="Email"
                            readOnly
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-organization">
                            <Typography variant="subtitle1">Organization</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-organization"
                            type="text"
                            value={values.organization.value}
                            onChange={handleChange('organization')}
                            label="Organization"
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-location">
                            <Typography variant="subtitle1">Location</Typography>
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-location"
                            type="text"
                            value={values.location.value}
                            onChange={handleChange('location')}
                            label="Location"
                        />
                    </FormControl>
                </FormWrapper>
                <ButtonsWrapper spacing={2}>
                    <Button variant="text" size="large" fullWidth onClick={onClose}>
                        <Typography variant="button">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        disableElevation
                        fullWidth
                        disabled={isLoading}
                        onClick={handleUserUpdate}
                    >
                        <Typography variant="button">Save</Typography>
                    </Button>
                </ButtonsWrapper>
            </DrawerContentWrapper>
        </MuiDrawer>
    );
};

UserProfileDrawer.defaultProps = {
    anchor: 'right'
};

UserProfileDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    anchor: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

export default UserProfileDrawer;
