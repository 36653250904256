import React, { useState, useEffect, useContext } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import { styled } from '@mui/material/styles';
import UploadForm from './UploadForm/UploadForm';
import { DialogContext } from '../../hooks/DialogContext';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.black,
    zIndex: theme.zIndex.drawer
}));

const Dropzone = ({ isOpen, onClose }) => {
    const [isProhibitedType, setIsProhibitedType] = useState(false);
    const [file, setFile] = useState(null);
    const { openDialog } = useContext(DialogContext);
    const { projectId } = useParams();
    const { path } = useRouteMatch();
    const isPrivateFolder = path.search(/private-folder/) > 0;

    const allowedTypes = [
        'application/pdf',
        'image/png',
        'image/svg+xml',
        'image/jpeg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    useEffect(() => {
        if (file) {
            openDialog({
                isOpen: true,
                status: null,
                title: 'File uploading form',
                content: <UploadForm fileData={file} projectId={projectId} isPrivateFolder={isPrivateFolder} />
            });
            setFile(null);
        }
    }, [file]);

    const onDrop = (e) => {
        e.preventDefault();

        if (!allowedTypes.includes(e.currentTarget.files[0].type)) {
            setIsProhibitedType(true);
        } else {
            setFile(e.currentTarget.files[0]);
        }
    };

    const onDragOver = (e) => {
        e.preventDefault();

        if (isProhibitedType) {
            setIsProhibitedType(false);
        }
    };

    const onClick = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <>
            <StyledBackdrop open={isOpen}>
                {
                    !isProhibitedType
                    && (
                        <>
                            <ArrowCircleDownIcon sx={{ width: '80px', height: '80px', color: 'common.white' }} />
                            <Typography color="common.white" align="center" sx={{ mt: '1rem' }}>Drop here</Typography>
                        </>
                    )
                }
                {
                    isProhibitedType
                        && (
                            <>
                                <CancelIcon sx={{ width: '80px', height: '80px', color: 'common.white' }} />
                                <Typography color="common.white" align="center" sx={{ mt: '1rem' }}>File type is not allowed</Typography>
                            </>
                        )
                }
            </StyledBackdrop>
            <input
                type="file"
                value=""
                onChange={onDrop}
                onDragOver={onDragOver}
                onClick={onClick}
                style={{ position: 'absolute', top: 0, left: 0, opacity: 0, zIndex: '1200', width: '100vw', height: '100vh' }}
            />
        </>
    );
};

Dropzone.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Dropzone;
