import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import EventIcon from '@mui/icons-material/EventOutlined';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { alpha, lighten, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Joi from 'joi';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useCreateProjectMutation,
    useGetTagsQuery,
    useGetUsersQuery,
    useSaveTagMutation,
    useUpdateProjectMutation
} from '../../../../api/LabtraceApi';
import { DialogContext } from '../../../../hooks/DialogContext';
import { Tag, TagsWrapper } from '../../../Tags/Tags';

const filter = createFilterOptions();

const StyledAutocomplete = styled(Autocomplete)(() => ({
    width: '100%',
    '& label': {
        lineHeight: 'unset'
    },
    '& .MuiAutocomplete-endAdornment': {
        display: 'none'
    }
}));

const InviteButton = styled(Button)(() => ({
    position: 'absolute',
    right: '.5rem',
    top: '.6rem'
}));

const StyledFormWrapper = styled(Box)(({ theme }) => ({
    maxHeight: '31.5rem',
    overflowY: 'auto',
    margin: '1.5rem -1rem 0',
    '&::-webkit-scrollbar': {
        width: '.25rem'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.black, 0.12)
    }
}));

const StyledActionWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '5.5rem',
    margin: '0 -1rem',
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.12)}`
}));

const Circle = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: '.5rem'
}));

const StyledDateTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root.Mui-focused svg': {
        color: theme.palette.primary.main
    }
}));

const Member = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3rem',
    padding: '0 1rem',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    cursor: 'pointer',
    '& > button': {
        display: 'none',
        '& > svg': {
            color: theme.palette.primary.main
        }
    },
    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        '& > button': {
            display: 'block'
        }
    }
}));

const RestOfMembers = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '.5rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
}));

const ErrorBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    borderLeft: `2px solid ${theme.palette.error.main}`,
    height: '3.5rem',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '& svg': {
        color: theme.palette.error.main,
        marginRight: '.5rem'
    }
}));

const StyledErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

const CustomBadge = ({ content }) => (
    <Circle component="span">{content}</Circle>
);

CustomBadge.propTypes = {
    content: PropTypes.string.isRequired
};

const CreateNewProjectForm = ({ project }) => {
    const { openDialog, closeDialog } = useContext(DialogContext);
    const [values, setValues] = useState({
        projectName: {
            value: '',
            error: '',
            pattern: Joi.string().trim().required().messages({
                'string.empty': 'Project name is required.'
            })
        },
        startEndDate: {
            value: [null, null],
            error: '',
            pattern: {
                name: 'dateRange',
                index: 0,
                message: 'Start date is required.'
            }
        },
        area: {
            value: '',
            error: '',
            pattern: Joi.string().trim().required().messages({
                'string.empty': 'Area is required.'
            })
        },
        organization: {
            value: ''
        },
        viewOrg: {
            value: null,
            error: '',
            pattern: {
                name: 'depending',
                field: 'organization',
                message: 'Organization data privacy is required.'
            }
        },
        location: {
            value: '',
            error: '',
            pattern: Joi.string().trim().pattern(/[a-zA-Z]/).required()
                .messages({
                    'string.empty': 'Location is required.'
                })
        },
        tags: {
            value: null,
            options: [],
            error: '',
            pattern: {
                name: 'atLeast',
                quantity: 1,
                message: 'At least 1 tag is required.'
            }
        },
        allTags: {
            value: null,
            options: []
        },
        maxNumberOfMembers: {
            value: '',
            error: '',
            pattern: Joi.number().required().messages({
                'number.base': 'Max number of members must be a number.'
            })
        },
        membersEmails: {
            value: null,
            options: [],
            error: ''
        },
        invitedMembers: {
            ids: [],
            members: []
        },
        viewLeader: {
            value: null,
            error: '',
            pattern: {
                name: 'required',
                message: 'Leader data privacy is required.'
            }
        }
    });
    const [showAllMembers, setShowAllMembers] = useState(false);
    const [createProject, { isLoading: isLoadingCreate }] = useCreateProjectMutation();
    const [updateProject, { isLoading: isLoadingUpdate }] = useUpdateProjectMutation();
    const user = useSelector((state) => state.user);
    const { data: users } = useGetUsersQuery();
    const { data: allTags } = useGetTagsQuery();
    const [saveTag] = useSaveTagMutation();
    const [denyUserToBecomeMember, setDenyUserToBecomeMember] = useState(false);

    useEffect(() => {
        if (users && allTags) {
            const membersEmails = users.records
                .filter((member) => member.id !== user.id)
                .map((member) => ({ id: member.id, email: member.email }));
            const awailableTags = allTags.records;

            setValues({
                ...values,
                membersEmails: {
                    ...values.membersEmails,
                    options: membersEmails
                },
                allTags: {
                    ...values.allTags,
                    options: awailableTags
                }
            });
        }
    }, [users, allTags]);

    useEffect(() => {
        if (project) {
            setValues({
                ...values,
                projectName: {
                    ...values.projectName,
                    value: project.name
                },
                startEndDate: {
                    ...values.startEndDate,
                    value: [
                        moment(project.startDate.date).format('MM/DD/YYYY'),
                        (project.endDate && project.endDate.date) ? moment(project.endDate.date).format('MM/DD/YYYY') : null
                    ]
                },
                area: {
                    ...values.area,
                    value: project.area
                },
                organization: {
                    ...values.organization,
                    value: project.organisation
                },
                location: {
                    ...values.organization,
                    value: project.location
                },
                tags: {
                    ...values.tags,
                    options: project.tags.map((tag, index) => ({ id: index, label: tag }))
                },
                maxNumberOfMembers: {
                    ...values.maxNumberOfMembers,
                    value: project.maxNumberOfMembers
                },
                invitedMembers: {
                    ids: project.members.records.map((record) => record.id),
                    members: project.members.records.map((record) => ({
                        id: record.id,
                        email: record.email
                    }))
                },
                viewOrg: {
                    ...values.viewOrg,
                    value: project.organisationScope
                },
                viewLeader: {
                    ...values.viewLeader,
                    value: project.leaderScope
                }
            });
        }
    }, [project]);

    const handleInputChange = (prop) => (event) => {
        const error = values[prop].pattern ? values[prop].pattern.validate(event.target.value).error : null;
        setValues({
            ...values,
            [prop]: {
                ...values[prop],
                value: event.target.value,
                error: error ? error.details[0].message : ''
            },
            ...(prop === 'organization' && !event.target.value && {
                viewOrg: {
                    ...values.viewOrg,
                    value: null
                }
            })
        });
        if (prop === 'maxNumberOfMembers' && project) {
            if (event.target.value < project.members.records.length) {
                setValues({
                    ...values,
                    [prop]: {
                        ...values[prop],
                        value: event.target.value,
                        error: 'Project has more members already.'
                    }
                });
            }
        }
    };

    const handleCheckboxChange = (group, selectedField) => {
        let error = '';
        if (!selectedField) {
            error = values[group].pattern.message;
        }
        setValues({
            ...values,
            [group]: {
                ...values[group],
                value: selectedField,
                error
            }
        });
    };

    const handleAutocompleteChange = (name, value) => {
        let error = null;
        if (name === 'startEndDate' && !value[0]) {
            error = 'Start date is required.';
        }

        setValues({
            ...values,
            [name]: {
                ...values[name],
                value,
                ...(error && { error }),
                ...(!error && name === 'startEndDate' && { error: '' })
            }
        });
    };

    const addNewTag = (label) => {
        const existingTag = [...values.tags.options].filter((tag) => tag.label === label).shift();

        if (!existingTag) {
            const tags = [...values.tags.options];
            tags.push({
                id: values.tags.options.length + 1,
                label
            });

            setValues({
                ...values,
                tags: {
                    ...values.tags,
                    options: tags,
                    error: ''
                }
            });
        }
    };

    const removeTag = (id) => {
        const tags = [...values.tags.options].filter((tag) => tag.id !== id);

        setValues({
            ...values,
            tags: {
                ...values.tags,
                options: tags,
                ...(tags.length === 0 && { error: values.tags.pattern.message })
            }
        });
    };

    const saveNewTag = async (tag) => {
        const checkValues = [];
        values.allTags.options.forEach((item) => {
            checkValues.push(item.name);
        });
        if (checkValues.includes(tag)) return;
        await saveTag(tag).unwrap();
    };

    const inviteMember = (member) => {
        const { id: memberId, email: memberEmail } = member;
        const existingMember = [...values.invitedMembers.ids].filter((id) => id === memberId).shift();
        const totalMembers = values.invitedMembers.ids.length;

        if (!existingMember) {
            if ((totalMembers < values.maxNumberOfMembers.value) || !values.maxNumberOfMembers.value) {
                const ids = [...values.invitedMembers.ids, memberId];
                const members = [...values.invitedMembers.members, { id: memberId, email: memberEmail }];
                const membersEmails = users.records
                    .filter((m) => (m.id !== user.id && !ids.includes(m.id)))
                    .map((m) => ({ id: m.id, email: m.email }));

                setValues({
                    ...values,
                    membersEmails: {
                        ...values.membersEmails,
                        options: membersEmails,
                        value: null
                    },
                    invitedMembers: {
                        ids,
                        members
                    }
                });
            } else {
                setValues({
                    ...values,
                    membersEmails: {
                        ...values.membersEmails,
                        value: null,
                        error: 'You have exceeded the maximum number of members'
                    }
                });
            }
        } else {
            setValues({
                ...values,
                membersEmails: {
                    ...values.membersEmails,
                    value: null
                }
            });
        }
    };

    const removeMember = (member) => {
        const { id: memberId } = member;
        const ids = [...values.invitedMembers.ids].filter((id) => id !== memberId);
        const members = [...values.invitedMembers.members].filter((m) => m.id !== memberId);
        const membersEmails = users.records
            .filter((m) => (m.id !== user.id && !ids.includes(m.id)))
            .map((m) => ({ id: m.id, email: m.email }));

        setValues({
            ...values,
            membersEmails: {
                ...values.membersEmails,
                options: membersEmails
            },
            maxNumberOfMembers: {
                ...values.maxNumberOfMembers,
                error: ''
            },
            invitedMembers: {
                ids,
                members
            }
        });
    };

    const areAllRequiredFieldsPopulated = () => {
        let allFieldsPopulated = true;
        const newValues = { ...values };

        Object.entries(values).forEach(([field, fieldValue]) => {
            let error = '';
            if (fieldValue.pattern && !fieldValue.pattern.name) {
                const patternError = fieldValue.pattern.validate(fieldValue.value).error;

                if (patternError) {
                    allFieldsPopulated = false;
                    error = patternError.details[0].message;
                }
            } else if (fieldValue.pattern && fieldValue.pattern.name) {
                if (
                    ((fieldValue.pattern.name === 'dateRange') && !fieldValue.value[fieldValue.pattern.index])
                    || ((fieldValue.pattern.name === 'atLeast') && fieldValue.options.length < fieldValue.pattern.quantity)
                    || ((fieldValue.pattern.name === 'required') && !fieldValue.value)
                    || ((fieldValue.pattern.name === 'depending') && values[fieldValue.pattern.field].value && !fieldValue.value)
                ) {
                    error = fieldValue.pattern.message;
                    allFieldsPopulated = false;
                }
            }
            newValues[field] = {
                ...values[field],
                error
            };
        });
        if (values.maxNumberOfMembers.error) {
            allFieldsPopulated = false;
            newValues.maxNumberOfMembers.error = values.maxNumberOfMembers.error;
        }
        setValues(newValues);

        return allFieldsPopulated;
    };

    const createNewProject = async () => {
        if (!areAllRequiredFieldsPopulated()) return;

        try {
            const payload = {
                name: values.projectName.value,
                area: values.area.value,
                organisation: values.organization.value,
                location: values.location.value,
                tags: values.tags.options.map((tag) => tag.label),
                leader: user.id,
                maxNumberOfMembers: values.maxNumberOfMembers.value,
                members: values.invitedMembers.ids,
                startDate: moment(values.startEndDate.value[0]).format('YYYY-MM-DD'),
                endDate: values.startEndDate.value[1] ? moment(values.startEndDate.value[1]).format('YYYY-MM-DD') : null,
                leaderScope: values.viewLeader.value,
                organisationScope: values.viewOrg.value
            };

            if (project) {
                const { leader, members, ...rest } = payload;
                const updatePayload = { ...rest, projectId: project.id };

                await updateProject(updatePayload).unwrap();
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Congratulations!',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            The project has been successfully updated.
                        </Typography>
                    )
                });
            } else {
                await createProject(payload).unwrap();
                openDialog({
                    isOpen: true,
                    status: 'success',
                    title: 'Congratulations!',
                    content: (
                        <Typography variant="body2" sx={{ mt: '1rem' }}>
                            The project has been successfully created.
                        </Typography>
                    )
                });
            }
        } catch {
            openDialog({
                isOpen: true,
                status: 'error',
                title: 'An error occured',
                content: (
                    <Typography variant="body2" sx={{ mt: '1rem' }}>
                        {`Could not ${project ? 'update the' : 'create a new'} project, please try again.`}
                    </Typography>
                )
            });
        }
    };

    const useStyles = makeStyles(() => ({
        hide: {
            display: 'none',
        }
    }));
    const classes = useStyles();

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
        >
            <StyledFormWrapper>
                <Stack spacing={4} sx={{ px: '1rem', pb: '1rem' }}>
                    <Box>
                        <Typography variant="subtitle2">
                            <CustomBadge content="1" />
                            Establish project
                        </Typography>
                        <Stack spacing={3} sx={{ mt: '2rem' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-projectName">
                                    <Typography variant="subtitle1">Project name</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-projectName"
                                    type="text"
                                    value={values.projectName.value}
                                    onChange={handleInputChange('projectName')}
                                    label="Project name"
                                />
                                {
                                    values.projectName.error
                                    && (
                                        <FormHelperText id="outlined-project-name-helper-text">
                                            <StyledErrorText variant="caption">{values.projectName.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateRangePicker
                                    startText="Start date"
                                    endText="End date"
                                    inputFormat="dd/MM/yyyy"
                                    calendars={1}
                                    onChange={(newValue) => handleAutocompleteChange('startEndDate', newValue)}
                                    renderInput={(startProps, endProps) => (
                                        <Stack spacing={0} sx={{ width: '100%' }}>
                                            <StyledDateTextField
                                                {...startProps}
                                                label="Start date"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EventIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {
                                                values.startEndDate.error
                                                && (
                                                    <FormHelperText id="outlined-start-date-helper-text" sx={{ mx: '14px' }}>
                                                        <StyledErrorText variant="caption">{values.startEndDate.error}</StyledErrorText>
                                                    </FormHelperText>
                                                )
                                            }
                                            <StyledDateTextField
                                                {...endProps}
                                                sx={{ mt: '1.5rem' }}
                                                label="End date"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EventIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Stack>
                                    )}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            <CustomBadge content="2" />
                            Specify details
                        </Typography>
                        <Stack spacing={3} sx={{ mt: '2rem' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-area">
                                    <Typography variant="subtitle1">Area</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-area"
                                    type="text"
                                    value={values.area.value}
                                    onChange={handleInputChange('area')}
                                    label="Area"
                                />
                                {
                                    values.area.error
                                    && (
                                        <FormHelperText id="outlined-email-helper-text">
                                            <StyledErrorText variant="caption">{values.area.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-organization">
                                    <Typography variant="subtitle1">Organization</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-organization"
                                    type="text"
                                    value={values.organization.value}
                                    onChange={handleInputChange('organization')}
                                    label="Organization"
                                />
                            </FormControl>
                            <Box>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue="1"
                                >
                                    <FormControlLabel
                                        sx={{ ml: '-.5rem' }}
                                        control={
                                            <Radio
                                                checked={Boolean(values.viewOrg.value && values.viewOrg.value === 1)}
                                            />
                                        }
                                        onChange={() => handleCheckboxChange('viewOrg', 1)}
                                        disabled={!values.organization.value}
                                        label={
                                            <Typography variant="body2">Anyone can view the Organization</Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        sx={{ ml: '-.5rem' }}
                                        control={
                                            <Radio
                                                checked={Boolean(values.viewOrg.value && values.viewOrg.value === 2)}
                                            />
                                        }
                                        onChange={() => handleCheckboxChange('viewOrg', 2)}
                                        disabled={!values.organization.value}
                                        label={
                                            <Typography variant="body2">Only members can view the Organization</Typography>
                                        }
                                    />
                                </RadioGroup>
                                {
                                    values.viewOrg.error
                                    && (
                                        <FormHelperText id="outlined-email-helper-text" sx={{ ml: '14px !important' }}>
                                            <StyledErrorText variant="caption">{values.viewOrg.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </Box>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-location">
                                    <Typography variant="subtitle1">Location</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-location"
                                    type="text"
                                    value={values.location.value}
                                    onChange={handleInputChange('location')}
                                    label="Location"
                                />
                                {
                                    values.location.error
                                    && (
                                        <FormHelperText id="outlined-email-helper-text">
                                            <StyledErrorText variant="caption">{values.location.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                            <StyledAutocomplete
                                id="tags"
                                freeSolo
                                selectOnFocus
                                clearOnBlur
                                sx={{ width: '100%', '& label': { lineHeight: 'unset' } }}
                                options={values.allTags.options}
                                value={values.allTags.value}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return '';
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }

                                    return option.name;
                                }}
                                onChange={((event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        addNewTag(newValue);
                                        saveNewTag(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        handleAutocompleteChange('tags', newValue.inputValue);
                                    } else {
                                        handleAutocompleteChange('tags', newValue);
                                    }
                                })}
                                filterOptions={(options, params) => {
                                    if (params.inputValue !== '') {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.unshift({
                                                inputValue,
                                                name: 'Press Enter to create a new tag',
                                            });
                                        }

                                        return filtered;
                                    }
                                    return [];
                                }}
                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tag"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password'
                                        }}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                            />
                            {
                                values.tags.options.length > 0
                                && (
                                    <TagsWrapper direction="row" spacing={1}>
                                        {
                                            values.tags.options.map((option) => (
                                                <Tag
                                                    key={option.id}
                                                    label={option.label}
                                                    onDelete={() => removeTag(option.id)}
                                                />
                                            ))
                                        }
                                    </TagsWrapper>
                                )
                            }
                            {
                                values.tags.error
                                && (
                                    <FormHelperText
                                        id="outlined-email-helper-text"
                                        sx={{ mt: '5px !important', ml: '14px !important' }}
                                    >
                                        <StyledErrorText variant="caption">{values.tags.error}</StyledErrorText>
                                    </FormHelperText>
                                )
                            }
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            <CustomBadge content="3" />
                            Invite members
                        </Typography>
                        <Stack spacing={3} sx={{ mt: '2rem' }}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-maxNumberOfMembers">
                                    <Typography variant="subtitle1">Max number of members</Typography>
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-maxNumberOfMembers"
                                    type="text"
                                    value={values.maxNumberOfMembers.value}
                                    onChange={handleInputChange('maxNumberOfMembers')}
                                    label="Max number of members"
                                />
                                {
                                    values.maxNumberOfMembers.error
                                    && (
                                        <FormHelperText id="outlined-email-helper-text">
                                            <StyledErrorText variant="caption">{values.maxNumberOfMembers.error}</StyledErrorText>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                            {
                                !project && (
                                    <Box sx={{ position: 'relative' }}>
                                        <StyledAutocomplete
                                            id=""
                                            options={values.membersEmails.options}
                                            value={values.membersEmails.value}
                                            getOptionLabel={(option) => option.email}
                                            onChange={((event, newValue) => handleAutocompleteChange('membersEmails', newValue))}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Email"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password'
                                                    }}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.email === value.email}
                                            noOptionsText="..."
                                            filterOptions={(options) => []}
                                            classes={
                                                { popper: classes.hide }
                                            }
                                            onInputChange={
                                                (event, value) => {
                                                    const member = values.membersEmails.options.find(({ email }) => email === value);
                                                    setDenyUserToBecomeMember(false);
                                                    if (member !== undefined) {
                                                        handleAutocompleteChange('membersEmails', member);
                                                    } else {
                                                        handleAutocompleteChange('membersEmails', null);
                                                    }

                                                    const pattern = Joi.string().email({ tlds: { allow: false } });
                                                    const error = pattern.validate(value);
                                                    if (error.error === undefined && member === undefined) {
                                                        setDenyUserToBecomeMember(true);
                                                    }
                                                }
                                            }
                                        />
                                        {
                                            values.membersEmails.value
                                            && (
                                                <InviteButton
                                                    variant="text"
                                                    size="large"
                                                    onClick={() => inviteMember(values.membersEmails.value)}
                                                >
                                                    <Typography variant="button">Invite</Typography>
                                                </InviteButton>
                                            )
                                        }
                                        {
                                            values.invitedMembers.members.length > 0
                                            && (
                                                values.invitedMembers.members.map((member, index) => (
                                                    ((index < 2 && !showAllMembers) || showAllMembers)
                                                        ? (
                                                            <Member key={member.id}>
                                                                {member.email}
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => removeMember(member)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Member>
                                                        )
                                                        : null
                                                ))
                                            )
                                        }
                                        {
                                            (values.invitedMembers.ids.length > 2 && !showAllMembers)
                                            && (
                                                <RestOfMembers onClick={() => setShowAllMembers(true)}>
                                                    +
                                                    {values.invitedMembers.ids.length - 2}
                                                    {values.invitedMembers.ids.length > 3 ? ' members' : ' member'}
                                                </RestOfMembers>
                                            )
                                        }
                                        {
                                            (values.invitedMembers.ids.length > 2 && showAllMembers)
                                            && (
                                                <RestOfMembers onClick={() => setShowAllMembers(false)}>
                                                    Show less
                                                </RestOfMembers>
                                            )
                                        }
                                        {
                                            values.membersEmails.error
                                            && (
                                                <ErrorBox>
                                                    <ErrorIcon />
                                                    {values.membersEmails.error}
                                                </ErrorBox>
                                            )
                                        }
                                        {
                                            denyUserToBecomeMember
                                            && (
                                                <ErrorBox>
                                                    <ErrorIcon />
                                                    The user that you are trying to invite is not registered to the platform yet.
                                                </ErrorBox>
                                            )
                                        }
                                    </Box>
                                )
                            }
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            <CustomBadge content="4" />
                            Project leader data privacy
                        </Typography>
                        <Box sx={{ mt: '1.5rem' }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label-1"
                                name="row-radio-buttons-group-1"
                                defaultValue="1"
                            >
                                <FormControlLabel
                                    sx={{ ml: '-.5rem' }}
                                    control={
                                        <Radio
                                            checked={Boolean(values.viewLeader.value && values.viewLeader.value === 1)}
                                        />
                                    }
                                    onChange={() => handleCheckboxChange('viewLeader', 1)}
                                    label={
                                        <Typography variant="body2">Anyone can view the Project Leader Name</Typography>
                                    }
                                />
                                <FormControlLabel
                                    sx={{ ml: '-.5rem' }}
                                    control={
                                        <Radio
                                            checked={Boolean(values.viewLeader.value && values.viewLeader.value === 2)}
                                        />
                                    }
                                    onChange={() => handleCheckboxChange('viewLeader', 2)}
                                    label={
                                        <Typography variant="body2">Only members can view the Project Leader Name</Typography>
                                    }
                                />
                            </RadioGroup>
                        </Box>
                        {
                            values.viewLeader.error
                            && (
                                <FormHelperText id="outlined-email-helper-text" sx={{ ml: '14px !important' }}>
                                    <StyledErrorText variant="caption">{values.viewLeader.error}</StyledErrorText>
                                </FormHelperText>
                            )
                        }
                    </Box>
                </Stack>
            </StyledFormWrapper>
            <StyledActionWrapper>
                <Stack spacing={2} direction="row" sx={{ width: '50%', pr: '1rem' }}>
                    <Button variant="text" size="large" fullWidth onClick={closeDialog}>
                        <Typography variant="button">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={Boolean(isLoadingCreate || isLoadingUpdate)}
                        onClick={createNewProject}
                    >
                        <Typography variant="button">{project ? 'Update' : 'Create'}</Typography>
                    </Button>
                </Stack>
            </StyledActionWrapper>
        </Box>
    );
};

CreateNewProjectForm.defaultProps = {
    project: null
};

CreateNewProjectForm.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string,
        area: PropTypes.string,
        name: PropTypes.string,
        organisation: PropTypes.string,
        location: PropTypes.string,
        maxNumberOfMembers: PropTypes.number,
        tags: PropTypes.arrayOf(PropTypes.string),
        leaderId: PropTypes.string,
        leaderFirstName: PropTypes.string,
        leaderLastName: PropTypes.string,
        startDate: PropTypes.any,
        endDate: PropTypes.any,
        members: PropTypes.shape({
            records: PropTypes.arrayOf(PropTypes.any)
        }),
        leaderScope: PropTypes.number,
        organisationScope: PropTypes.number
    })
};

export default CreateNewProjectForm;
